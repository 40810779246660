import axios from "axios";
export default axios.create({
    // '### release - change URL
    //baseURL: "https://localhost:44313/",
    //baseURL: "https://eyethrive.swipsystems.com/",
    baseURL: "https://portal.eyethrive.org/",
    //baseURL: "https://mvc.eyethrive.org/",
    //baseURL: "https://testportal.eyethrive.org/",
    headers: {
        "Content-type": "application/json"
    }
});