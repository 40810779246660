import http from "../http-common";
import authService from '../components/api-authorization/AuthorizeService';

class UploadFilesService {
    async upload(file, siteId, userId, onUploadProgress) {
        const token = await authService.getAccessToken();
        let formData = new FormData();
        formData.append("file", file);
        formData.append("siteId", siteId);
        formData.append("userId", userId);
        return http.post("api/upload/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress,
        });
    }
    async getFiles(siteId) {
        const token = await authService.getAccessToken();
        return http.get("api/upload/files?siteId=" + siteId, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }
}
export default new UploadFilesService();