import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import userFunctions from '../UserFunctions';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            formNoMatchCount: 0
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();

        this.getFormsNoMatchCount();

        setInterval(async () => {
            await this.getFormsNoMatchCount();
        }, 6000);
    }

    async getFormsNoMatchCount() {
        var count = await userFunctions.FormsNoMatchCount();

        this.setState({ formNoMatchCount: count ?? 0 });
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        if (user) {
            sessionStorage.setItem("UserId", user.sub);
            sessionStorage.setItem("Username", user.name);
        }

        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <div className="menu-items" style={{ width: 'fit-content' }}>
                <UncontrolledButtonDropdown className="et-menu">
                    <DropdownToggle caret size="md" className="menuDropdown">
                        Search
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag={Link} to='/DistrictSearch'>District</DropdownItem>
                        <DropdownItem tag={Link} to='/ReplacementSearch'>Replacement</DropdownItem>
                        <DropdownItem tag={Link} to='/SiteSearch'>Site</DropdownItem>
                        <DropdownItem tag={Link} to='/StudentSearch'>Student</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                <UncontrolledButtonDropdown className="et-menu">
                    <DropdownToggle caret size="md" className="menuDropdown">
                        Inventory
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag={Link} to='/FrameInventory'>Frame</DropdownItem>
                        <DropdownItem tag={Link} to='/LensInventory'>Lens</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                <NavItem className="et-menu-item">
                    <NavLink style={{ whiteSpace: 'nowrap' }} tag={Link} className="text-dark" to='/DoctorExamSelection'>Doctor Exam / Lab Selection</NavLink>
                </NavItem>
                <NavItem className="et-menu-item">
                    <NavLink style={{ whiteSpace: 'nowrap' }} tag={Link} className="text-dark" to='/Reporting'>Reporting</NavLink>
                </NavItem>
                <NavItem className="et-menu-item">
                    <NavLink style={{ whiteSpace: 'nowrap' }} tag={Link} className="text-dark" to='/RosterImport'>Roster Import</NavLink>
                </NavItem>
                {this.state.userName === 'billgeorge@eyethrive.org' || this.state.userName === 'katemckearn@eyethrive.org' || this.state.userName === 'brian@swipsystems.com' || this.state.userName === 'ashleywildermuth@eyethrive.org' || this.state.userName === 'DebeauxBowman@eyethrive.org' ?
                    <NavItem className="et-menu-item" style={{ position: 'relative', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <NavLink style={{ whiteSpace: 'nowrap', paddingRight: '.20rem' }} tag={Link} className="text-dark" to='/StudentForms'>Forms</NavLink>
                        {this.state.formNoMatchCount && this.state.formNoMatchCount > 0 ?
                            <div style={{ width: 'fit-content', height: '18px', maxHeight: '18px', marginRight: '.40rem', padding: '1px', background: '#dd0000', color: '#fff', borderRadius: '60%', minWidth: '18px', fontSize: '11px', textAlign: 'center' }}>{this.state.formNoMatchCount}</div>
                            : null}
                    </NavItem>
                    : null}
                <NavItem className="et-menu-item">
                    <NavLink style={{ whiteSpace: 'nowrap' }} tag={Link} className="text-dark" to='/DataSync'>Data Sync</NavLink>
                </NavItem>
                {/*<NavItem className="et-menu-item">*/}
                {/*    <NavLink tag={Link} className="text-dark" to='/Screening'>Screening</NavLink>*/}
                {/*</NavItem>*/}
            </div>
            <div className="profile-logout">
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                </NavItem>
            </div>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
