import React, { Component, Fragment } from "react";
import './layout/site.css';

class Autocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: ""
        };
    }

    onChange = e => {
        try {
            const { suggestions } = this.props;
            const userInput = e.currentTarget.value;

            if (!suggestions) {
                this.props.onChange(userInput);
                return false;
            }

            const filteredSuggestions = suggestions.filter(suggestion =>
                suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            );

            this.setState({
                activeSuggestion: 0,
                filteredSuggestions,
                showSuggestions: true,
                userInput: e.currentTarget.value
            });
            this.props.onChange(userInput);
        }
        catch (err) {
            console.log("AutoComplete.onChange error" + err);
        }
    };

    onClick = e => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
        this.props.onChange({ Text: e.currentTarget.innerText, Id: e.currentTarget.getAttribute("id") });
    };

    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }
                            return (
                                <li className={className} key={index} id={suggestion.id} onClick={onClick}>
                                    {suggestion.name}
                                </li>
                            );
                        })}
                    </ul>
                );
            }
            //else {
            //    suggestionsListComponent = (
            //        <div className="no-suggestions">
            //            <em>No suggestions available.</em>
            //        </div>
            //    );
            //}
        }

        return (
            <Fragment>
                <div style={{ width: '100%', height: 'fit-content' }}>
                    {this.props.Focus ?
                        <input
                            type="text"
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            value={userInput || this.props.value}
                            placeholder={this.props.placeholder}
                            style={this.props.style}
                            className="form-control"
                            autoFocus
                        />
                        :
                        <input
                            type="text"
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            value={userInput || this.props.value}
                            placeholder={this.props.placeholder}
                            style={this.props.style}
                            className="form-control"
                        />
                    }
                    {suggestionsListComponent}
                </div>
            </Fragment>
        );
    }
}

export default Autocomplete;