import React, { Component, useRef } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Table, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';
import Upload from "./upload-files-student.component";
import Autocomplete from "./Autocomplete";
import { IoTrashOutline } from 'react-icons/io5';
import { FaSort, FaRegTrashAlt } from 'react-icons/fa'
import Modal from 'react-modal';

export class ReplacementDetails extends Component {
    static displayName = ReplacementDetails.name;

    constructor(props) {
        super(props);

        this.state = {
            active: true,
            studentId: userFunctions.GuidEmpty(),
            firstName: '',
            preferredName: '',
            lastName: '',
            dateOfBirth: '',
            districtId: userFunctions.GuidEmpty(),
            siteId: userFunctions.GuidEmpty(),
            gradeId: userFunctions.GuidEmpty(),
            teacher: '',
            roomNumber: '',
            genderId: userFunctions.GuidEmpty(),
            ethnicityId: userFunctions.GuidEmpty(),
            medicaid: false,
            medicaidNumber: '',
            reducedLunch: false,
            examConsentOnFile: false,
            photoConsent: false,
            IEP504Plan: false,
            wearsGlassesPerNurse: false,
            parentName: '',
            parentMobilePhone: '',
            email: '',
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            districts: [],
            showToast: false,
            toastMessage: '',
            userId: '',
            showMedicaidField: false,
            siteList: [],
            gradeList: [],
            genderList: [],
            ethnicityList: [],
            teacherList: [],
            screenings: [],
            exams: [],
            uploadDisabled: true,
            a1: false,
            a2: false,
            a3: false,
            a4: false,
            a5: false,
            a6: false,
            a7: false,
            a8: false,
            a9: false,
            a10: false,
            a11: false,
            a12: false,
            a13: false,
            a14: false,
            a15: false,
            a16: false,
            a17: false,
            a18: false,
            showEmailError: false,
            toastErrorMessage: '',
            isDoctor: false,
            isSupport: false,
            labFrames: [],
            showDeleteFrameModal: false,
            showDistrictOther: false,
            school: '',
            district: '',
            dateOrderReceived: '',
            selectExamDate: '',
            isValid: false,
            reasonInvalid: '',
            phoneNumber: '',
            contactSummary: '',
            uspsNumber: '',
            mailedByDate: '',
            dateToLab: '',
            dateFromLab: '',
            address: '',
            city: '',
            stateId: '',
            stateList: [],
            zip: '',
            mailedTo: '',
            notes: '',
            Eye_Size: [
                {
                    'id': '14',
                    'size': '43',
                    'value': ''
                },
                {
                    'id': '1',
                    'size': '44',
                    'value': ''
                },
                {
                    'id': '2',
                    'size': '45',
                    'value': ''
                },
                {
                    'id': '3',
                    'size': '46',
                    'value': ''
                },
                {
                    'id': '4',
                    'size': '47',
                    'value': ''
                },
                {
                    'id': '5',
                    'size': '48',
                    'value': ''
                },
                {
                    'id': '6',
                    'size': '49',
                    'value': ''
                },
                {
                    'id': '7',
                    'size': '50',
                    'value': ''
                },
                {
                    'id': '8',
                    'size': '51',
                    'value': ''
                },
                {
                    'id': '9',
                    'size': '52',
                    'value': ''
                },
                {
                    'id': '10',
                    'size': '53',
                    'value': ''
                },
                {
                    'id': '11',
                    'size': '54',
                    'value': ''
                },
                {
                    'id': '12',
                    'size': '55',
                    'value': ''
                },
                {
                    'id': '13',
                    'size': '56+',
                    'value': ''
                }]
        };
        this.inputRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";
    }


    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.sites.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.sites.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.sites.length / pageSize) })
        }
    }

    async componentDidMount() {
        this.getStates();
        this.getFrameDropdowns('manufacturer', userFunctions.GuidEmpty());

        this.query = new URLSearchParams(window.location.search);
        this.queryId = this.query.get('Id');
        this.studentId = this.query.get('sid');

        if (this.queryId) {
            this.setState({ replacementId: this.queryId });
            //Get the replacement data and load the page fields
            this.GetStudentReplacementById(this.queryId);
        }

        if (this.studentId) {
            this.setState({ studentId: this.studentId });
            //Get the student data and load the page fields
            this.GetStudentById(this.studentId);
        }
    }

    async GetStudentById(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;

        await fetch(`api/Student/GetStudentById?Id=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((_data) => {
                var data = _data.replacementData;

                this.setState({
                    firstName: data.firstName ?? '',
                    preferredName: data.preferredName ?? '',
                    lastName: data.lastName ?? '',
                    dateOfBirthFormatted: data.dateOfBirthFormatted ?? '',
                    studentSchool: data.studentSchool ?? '',
                    studentDistrict: data.studentDistrict ?? '',
                    dateOrderReceivedFormatted: data.dateReceivedFormatted ?? '',
                    submittedBy: data.submittedBy ?? '',
                    previousExams: data.previousExams ?? '',
                    labFrames: data.previousFrames,
                    selectedExamId: data.selectedExamId ?? '',
                    valid: data.valid === null ? false : data.valid,
                    invalid: data.valid === null ? false : !data.valid,
                    replacementReason: data.replacementReason ?? '',
                    phone: data.phone ?? '',
                    email: data.email ?? '',
                    contactSummary: data.contactSummary ?? '',
                    trackingNumber: data.trackingNumber ?? '',
                    mailedByDateFormatted: data.mailedByDateFormatted,
                    dateMailedToLabFormatted: data.dateMailedToLabFormatted,
                    dateReceivedFromLabFormatted: data.dateReceivedFromLabFormatted,
                    address: data.address,
                    city: data.city,
                    stateId: data.stateId,
                    zip: data.zip,
                    mailedHome: data.mailedToSchool === null ? false : !data.mailedToSchool,
                    mailedSchool: data.mailedToSchool === null ? false : data.mailedToSchool,
                    finalSRxODSphere: data.finalSRxODSphere,
                    finalSRxODCyl: data.finalSRxODCyl,
                    finalSRxODAxis: data.finalSRxODAxis,
                    finalSRxODAdd: data.finalSRxODAdd,
                    finalSRxODPrism: data.finalSRxODPrism,
                    finalSRxODBase: data.finalSRxODBase,
                    finalSRxOSSphere: data.finalSRxOSSphere,
                    finalSRxOSCyl: data.finalSRxOSCyl,
                    finalSRxOSAxis: data.finalSRxOSAxis,
                    finalSRxOSAdd: data.finalSRxOSAdd,
                    finalSRxOSPrism: data.finalSRxOSPrism,
                    finalSRxOSBase: data.finalSRxOSBase,
                    distancePDOD: data.distancePDOD,
                    distancePDOS: data.distancePDOS,
                    distancePDTotal: data.distancePDTotal,
                    nearPDOD: data.nearPDOD,
                    nearPDOS: data.nearPDOS,
                    nearPDTotal: data.nearPDTotal,
                    loading: false
                });
            })
            .catch(error => {
                console.log("error in GetStudentById - " + error);
                this.setState({ loading: false });
            });;
    }

    async getStates() {
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetStates', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ stateList: data });
            })
            .catch((error) => {
                alert('error in getStates - ' + error.message, 'color:#ff0000')
            });
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            //this.searchSites();
        }
    }

    render() {
        return (
            <div style={{ textAlign: 'left' }}>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                {this.state.showEmailError ?
                    <Toast className="toast-error">
                        <ToastBody className="toast-body">
                            {this.state.toastErrorMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                <Container style={{ marginTop: '25px' }}>
                    <Row>
                        <Col>
                            <h3>Replacement Details</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='5' lg='6' md='6' sm='10' xs='10'>
                            <table className="studentTable" style={{ width: 'fit-content', fontSize: '16px', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ fontSize: '16px' }}>
                                            Name
                                        </td>
                                        <td style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {this.state.firstName} {this.state.lastName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: '16px' }}>
                                            Preferred Name
                                        </td>
                                        <td style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {this.state.preferredName}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: '16px' }}>
                                            Date Of Birth
                                        </td>
                                        <td style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {this.state.dateOfBirthFormatted}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: '16px' }}>
                                            School
                                        </td>
                                        <td style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {this.state.studentSchool}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: '16px' }}>
                                            District
                                        </td>
                                        <td style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {this.state.studentDistrict}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <table style={{ width: '85%', marginTop: '20px' }}>
                        <tr>
                            <td align={'left'} valign={'top'}>
                                <table className={'replacementLayoutTables'}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Date Order Received
                                            </td>
                                            <td>
                                                <Input
                                                    type='date'
                                                    onChange={(e) => {
                                                        this.setState({ dateOrderReceivedFormatted: e.target.value, a4: false });
                                                    }}
                                                    name='Date Of Birth'
                                                    placeholder={this.state.a4 ? 'Required' : 'Date Order Received'}
                                                    value={this.state.dateOrderReceivedFormatted ?? ''}
                                                    style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Submitted By
                                            </td>
                                            <td>
                                                <select className="form-control"
                                                    onChange={(e) => this.setState({ submittedBy: e.target.value, a11: false })}
                                                    value={this.state.submittedBy}
                                                    style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    <option key={1} value={'School nurse or other school staff member'}>School nurse or other school staff member</option>
                                                    <option key={2} value={'Parent or guardian'}>Parent or guardian</option>
                                                </select>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Exam Date
                                            </td>
                                            <td>
                                                <select className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            selectedExamId: e.target.value,
                                                            a11: false,
                                                            labFrames: [],
                                                            finalSRxODSphere: '',
                                                            finalSRxODCyl: '',
                                                            finalSRxODAxis: '',
                                                            finalSRxODAdd: '',
                                                            finalSRxODPrism: '',
                                                            finalSRxODBase: '',
                                                            finalSRxOSSphere: '',
                                                            finalSRxOSCyl: '',
                                                            finalSRxOSAxis: '',
                                                            finalSRxOSAdd: '',
                                                            finalSRxOSPrism: '',
                                                            finalSRxOSBase: '',
                                                            distancePDOD: '',
                                                            distancePDOS: '',
                                                            distancePDTotal: '',
                                                            nearPDOD: '',
                                                            nearPDOS: '',
                                                            nearPDTotal: ''
                                                        });

                                                        if (e.target.value !== '0') {
                                                            this.getExamDataById(e.target.value);
                                                        }
                                                    }}
                                                    value={this.state.selectedExamId}
                                                    style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                >
                                                    <option key={0} value={'0'}>---Select---</option>
                                                    {this.state.previousExams ? this.state.previousExams.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.examDate}</option>
                                                    ) : null}
                                                </select>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Prescription Status
                                            </td>
                                            <td>
                                                <Input
                                                    type='radio'
                                                    name='isValid'
                                                    onChange={(e) => { this.setState({ valid: e.target.checked, invalid: !e.target.checked }) }}
                                                    checked={this.state.valid}
                                                    className="form-control-checkbox"
                                                />&nbsp;Valid&nbsp;&nbsp;
                                                <Input
                                                    type='radio'
                                                    name='isValid'
                                                    onChange={(e) => { this.setState({ invalid: e.target.checked, valid: !e.target.checked }) }}
                                                    checked={this.state.invalid}
                                                    className="form-control-checkbox"
                                                />&nbsp;Invalid
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Reason Invalid
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ reasonInvalid: e.target.value, a3: false });
                                                    }}
                                                    name='Reason Invalid'
                                                    placeholder={this.state.a3 ? 'Required' : 'Reason Invalid'}
                                                    value={this.state.reasonInvalid ?? ''}
                                                    style={this.state.a3 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    disabled={this.state.valid}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Replacement Reason
                                            </td>
                                            <td>
                                                <select className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({ replacementReason: e.target.value, a11: false, showReplacementReasonOther: e.target.value === 'Other' ? true : false });
                                                    }}
                                                    value={this.state.replacementReason}
                                                    style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    <option key={1} value={'Lost'}>Lost</option>
                                                    <option key={2} value={'Broken'}>Broken</option>
                                                    <option key={3} value={'Other'}>Other</option>
                                                </select>
                                            </td>
                                        </tr>
                                        {this.state.showReplacementReasonOther ?
                                            <tr>
                                                <td>
                                                    Replacement Reason Other
                                                </td>
                                                <td>
                                                    <Input
                                                        type='text'
                                                        onChange={(e) => {
                                                            this.setState({ replacementReasonOther: e.target.value, a3: false });
                                                        }}
                                                        placeholder={this.state.a3 ? 'Required' : 'Replacement Reason Other'}
                                                        value={this.state.replacementReasonOther ?? ''}
                                                        style={this.state.a3 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                        autoFocus
                                                    />
                                                </td>
                                            </tr>
                                            : null}
                                        <tr>
                                            <td>
                                                Phone Number
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        var phone = this.formatPhoneNumber(e.target.value)
                                                        this.setState({ phone: phone, a15: false });
                                                    }}
                                                    maxLength={14}
                                                    placeholder={this.state.a15 ? 'Required' : 'Phone Number'}
                                                    value={this.state.phone ?? ''}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Email
                                            </td>
                                            <td>
                                                <Input
                                                    ref={this.inputRef}
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ email: e.target.value, a16: false });
                                                    }}
                                                    maxLength={100}
                                                    name='Email'
                                                    placeholder={this.state.a16 ? 'Required' : 'Email'}
                                                    value={this.state.email ?? ''}
                                                    style={this.state.a16 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td valign={'top'}>
                                                Contact Summary
                                            </td>
                                            <td colSpan={2}>
                                                <textarea
                                                    onChange={(e) => { this.setState({ contactSummary: e.target.value }) }}
                                                    value={this.state.contactSummary ?? ''}
                                                    className="form-control"
                                                    style={{ height: '120px', width: '500px', resize: 'none' }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td align={'left'}>
                                <table className={'replacementLayoutTables'}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                USPS Tracking #
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ trackingNumber: e.target.value, a3: false });
                                                    }}
                                                    name='USPS Tracking'
                                                    placeholder={this.state.a3 ? 'Required' : 'USPS Tracking #'}
                                                    value={this.state.trackingNumber ?? ''}
                                                    style={this.state.a3 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Date Mailed
                                            </td>
                                            <td>
                                                <Input
                                                    type='date'
                                                    onChange={(e) => {
                                                        this.setState({ mailedByDateFormatted: e.target.value, a4: false });
                                                    }}
                                                    placeholder={this.state.a4 ? 'Required' : 'Date Mailed'}
                                                    value={this.state.mailedByDateFormatted ?? ''}
                                                    style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Date Mailed to Lab
                                            </td>
                                            <td>
                                                <Input
                                                    type='date'
                                                    onChange={(e) => {
                                                        this.setState({ dateMailedToLabFormatted: e.target.value, a4: false });
                                                    }}
                                                    name='Date Mail to Lab'
                                                    placeholder={this.state.a4 ? 'Required' : 'Date Mail to Lab'}
                                                    value={this.state.dateMailedToLabFormatted ?? ''}
                                                    style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Date Received From Lab
                                            </td>
                                            <td>
                                                <Input
                                                    type='date'
                                                    onChange={(e) => {
                                                        this.setState({ dateReceivedFromLabFormatted: e.target.value, a4: false });
                                                    }}
                                                    name='Date Received From Lab'
                                                    placeholder={this.state.a4 ? 'Required' : 'Date Received From Lab'}
                                                    value={this.state.dateReceivedFromLabFormatted ?? ''}
                                                    style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Address
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => { this.setState({ address: e.target.value, a3: false }) }}
                                                    name='Address'
                                                    placeholder={this.state.a3 ? 'Required' : 'Address'}
                                                    value={this.state.address}
                                                    style={this.state.a3 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                City
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => { this.setState({ city: e.target.value, a4: false }); }}
                                                    name='City'
                                                    placeholder={this.state.a4 ? 'Required' : 'City'}
                                                    value={this.state.city}
                                                    style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                State
                                            </td>
                                            <td>
                                                <select className="form-control"
                                                    onChange={(e) => this.setState({ stateId: e.target.value, a5: false })}
                                                    value={this.state.stateId}
                                                    style={this.state.a5 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                >
                                                    <option key={0} value={userFunctions.GuidEmpty()}>---Select---</option>
                                                    {this.state.stateList.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.stateName}</option>
                                                    )}
                                                </select>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Zip Code
                                            </td>
                                            <td>
                                                <Input
                                                    type='text'
                                                    maxLength={5}
                                                    onChange={(e) => { this.setState({ zip: e.target.value, a6: false }); }}
                                                    name='Zip'
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={this.state.a6 ? 'Required' : 'Zip Code'}
                                                    value={this.state.zip}
                                                    style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                Mailed To
                                            </td>
                                            <td>
                                                <Input
                                                    type='radio'
                                                    name='mailedTo'
                                                    onChange={(e) => { this.setState({ mailedSchool: e.target.checked }) }}
                                                    checked={this.state.mailedSchool}
                                                    className="form-control-checkbox"
                                                />&nbsp;School&nbsp;&nbsp;
                                                <Input
                                                    type='radio'
                                                    name='mailedTo'
                                                    onChange={(e) => { this.setState({ mailedHome: e.target.checked }) }}
                                                    checked={this.state.mailedHome}
                                                    className="form-control-checkbox"
                                                />&nbsp;Home
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Replacement Glasses Experience Survey Sent
                                            </td>
                                            <td>
                                                <Input
                                                    type='date'
                                                    onChange={(e) => {
                                                        this.setState({ replacementGlassesExperienceSurveySent: e.target.value, a4: false });
                                                    }}
                                                    value={this.state.ReplacementGlassesExperienceSurveySent ?? ''}
                                                    disabled={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Replacement Glasses Impact Survey Sent
                                            </td>
                                            <td>
                                                <Input
                                                    type='date'
                                                    onChange={(e) => {
                                                        this.setState({ replacementGlassesImpactSurveySent: e.target.value, a4: false });
                                                    }}
                                                    value={this.state.replacementGlassesImpactSurveySent ?? ''}
                                                    disabled={true}
                                                />
                                            </td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td valign={'top'}>*/}
                                        {/*        Notes*/}
                                        {/*    </td>*/}
                                        {/*    <td>*/}
                                        {/*        <textarea*/}
                                        {/*            onChange={(e) => { this.setState({ notes: e.target.value }) }}*/}
                                        {/*            value={this.state.notes ?? ''}*/}
                                        {/*            className="form-control"*/}
                                        {/*            style={{ height: '120px', width: '500px', resize: 'none' }}*/}
                                        {/*        />*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='5' lg='6' md='6' sm='10' xs='10'>
                            <table>
                                <tr>
                                    <td>
                                        <table className="studentTable" style={{ width: '600px' }}>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={6} style={{ justifyContent: 'flex-start', width: '100%', fontWeight: 'bold' }}>
                                                        Final SRx
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className={'row-label'}>OD:</label>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxODSphere: e.target.value, a6: false }); }}
                                                            name='OD Sphere'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Sphere'}
                                                            value={this.state.finalSRxODSphere}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxODCyl: e.target.value, a6: false }); }}
                                                            name='OD Cyl'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Cyl'}
                                                            value={this.state.finalSRxODCyl}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxODAxis: e.target.value, a6: false }); }}
                                                            name='OD Axis'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Axis'}
                                                            value={this.state.finalSRxODAxis}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxODAdd: e.target.value, a6: false }); }}
                                                            name='OD Add'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Add'}
                                                            value={this.state.finalSRxODAdd}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxODPrism: e.target.value, a6: false }); }}
                                                            name='OD Prism'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Prism'}
                                                            value={this.state.finalSRxODPrism}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxODBase: e.target.value, a6: false }); }}
                                                            name='OD Base'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Base'}
                                                            value={this.state.finalSRxODBase}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <label className={'row-label'}>OS:</label>
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxOSSphere: e.target.value, a6: false }); }}
                                                            name='OS Sphere'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Sphere'}
                                                            value={this.state.finalSRxOSSphere}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxOSCyl: e.target.value, a6: false }); }}
                                                            name='OS Cyl'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Cyl'}
                                                            value={this.state.finalSRxOSCyl}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxOSAxis: e.target.value, a6: false }); }}
                                                            name='OS Axis'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Axis'}
                                                            value={this.state.finalSRxOSAxis}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxOSAdd: e.target.value, a6: false }); }}
                                                            name='OS Add'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Add'}
                                                            value={this.state.finalSRxOSAdd}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxOSPrism: e.target.value, a6: false }); }}
                                                            name='OS Prism'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Prism'}
                                                            value={this.state.finalSRxOSPrism}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ finalSRxOSBase: e.target.value, a6: false }); }}
                                                            name='OS Base'
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : 'Base'}
                                                            value={this.state.finalSRxOSBase}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style={{ paddingLeft: '80px' }}>
                                        <table className="distanceNearTable">
                                            <tbody>
                                                <tr>
                                                    <td style={{ justifyContent: 'flex-start', width: '100%', fontWeight: 'bold' }}>
                                                        Distance PD
                                                    </td>
                                                    <td></td>
                                                    <td>&nbsp;</td>
                                                    <td style={{ justifyContent: 'flex-start', width: '100%', fontWeight: 'bold' }}>
                                                        Near PD
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className={'row-label'}>
                                                        OD:&nbsp;
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ distancePDOD: e.target.value, a6: false }); }}
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : ''}
                                                            value={this.state.distancePDOD}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td className={'row-label'} style={{ justifyContent: 'flex-end' }}>
                                                        OS:&nbsp;
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ distancePDOS: e.target.value, a6: false }); }}
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : ''}
                                                            value={this.state.distancePDOS}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td className={'row-label'}>
                                                        OD:&nbsp;
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ nearPDOD: e.target.value, a6: false }); }}
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : ''}
                                                            value={this.state.nearPDOD}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td className={'row-label'} style={{ justifyContent: 'flex-end' }}>
                                                        OS:&nbsp;
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ nearPDOS: e.target.value, a6: false }); }}
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : ''}
                                                            value={this.state.nearPDOS}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td className={'row-label'} style={{ justifyContent: 'flex-end' }}>
                                                        Total:&nbsp;
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ distancePDTotal: e.target.value, a6: false }); }}
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : ''}
                                                            value={this.state.distancePDTotal}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td className={'row-label'} style={{ justifyContent: 'flex-end' }}>
                                                        Total:&nbsp;
                                                        <Input
                                                            type='text'
                                                            maxLength={5}
                                                            onChange={(e) => { this.setState({ nearPDTotal: e.target.value, a6: false }); }}
                                                            onKeyPress={this.handleKeyPress}
                                                            placeholder={this.state.a6 ? 'Required' : ''}
                                                            value={this.state.nearPDTotal}
                                                            style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='10' xs='10'>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color='primary'
                                    style={{ marginBottom: '5px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                    onClick={() => this.setState({
                                        model: '',
                                        color: '',
                                        eyeSize: '',
                                        segHeight: '',
                                        frameNotes: '',
                                        description: '',
                                        referenceNumber: '',
                                        labLocation: '',
                                        //mailedByDateFormatted: '',
                                        showFramesModal: true
                                    })}
                                    disabled={this.state.loading || this.state.invalid ? true : false}
                                >
                                    Add Frame
                                </Button>
                            </div>
                            <Table responsive className="gridTable">
                                <thead>
                                    <tr>
                                        <th>
                                            Delete
                                        </th>
                                        <th>
                                            Edit
                                        </th>
                                        <th>
                                            Exam Date
                                        </th>
                                        <th>
                                            Notes
                                        </th>
                                        <th>
                                            Dispensed Location
                                        </th>
                                        <th>
                                            Date Frames Dispensed
                                        </th>
                                        <th>
                                            Manufacturer
                                        </th>
                                        <th>
                                            Material
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            Color
                                        </th>
                                        <th>
                                            Eye Size
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.labFrames && this.state.labFrames.length > 0 ?
                                        this.state.labFrames.map((item, i) =>
                                            <tr key={i}>
                                                <td>{item.dispensedLocation === "Replacement" ? <IoTrashOutline color="#000" size={21} style={{ cursor: 'pointer' }} onClick={() => this.setState({ frameId: item.id, showDeleteFrameModal: true })} /> : null}</td>
                                                <td>{item.dispensedLocation === "Replacement" ? <div style={{ color: 'dodgerblue' }} onClick={() => this.editFrame(item)}>Edit</div> : null}</td>
                                                <td>{item.examDateFormatted}</td>
                                                <td>{item.dispensedLocation === "Replacement" ? <div style={{ color: 'dodgerblue' }} onClick={() => this.viewNote(item.notes)}>View</div> : null}</td>
                                                <td>{item.dispensedLocation}</td>
                                                <td>{item.dispensedDateFormatted}</td>
                                                <td>{item.manufacturer}</td>
                                                <td>{item.material}</td>
                                                <td>{item.description}</td>
                                                <td>{item.color}</td>
                                                <td>{item.eyeSize}</td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={10}></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        color='primary'
                        style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                        onClick={() => this.saveReplacement()}
                        disabled={this.state.loading ? true : false}
                    >
                        Save
                    </Button>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showDeleteFrameModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showDeleteFrameModal: false })}
                        className="et-modal"
                        contentLabel="Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Delete Frame Confirmation</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30vh', overflow: 'auto' }}>
                            <table style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{ width: '100%', display: 'flex', textAlign: 'center', fontSize: '16px' }}>
                                                Are you sure you want to delete the frame?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <Button
                                                    color='primary'
                                                    style={{
                                                        marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ showDeleteFrameModal: false })
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className='delete'
                                                    style={{
                                                        marginLeft: '40px', marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.deleteFrame();
                                                    }}
                                                >
                                                    Delete Frame
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showNotesModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showNotesModal: false })}
                        className="et-modal"
                        contentLabel="Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                            <h5>Notes</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30vh', overflow: 'auto' }}>
                            <table style={{ width: '100%', marginTop: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{ width: '100%', display: 'flex', textAlign: 'flex-start', fontSize: '16px' }}>
                                                {this.state.labNotes}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                                                <Button
                                                    color='primary'
                                                    style={{
                                                        marginBottom: '10px', whiteSpace: 'nowrap',
                                                        padding: '3px 20px', borderRadius: '5px', border: '0'
                                                    }}
                                                    onClick={() => {
                                                        this.setState({ showNotesModal: false })
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>

                <div>
                    <Modal
                        isOpen={this.state.showFramesModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showFramesModal: false })}
                        className="et-modal"
                        contentLabel="Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                            <h5>{this.state.frameId === undefined ? "Add Frame" : "Edit Frame"}</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '35vh', overflow: 'auto' }}>
                            <table className="layoutTable margin-bottom-20">
                                <tbody>
                                    <tr>
                                        <td align="right">
                                            Manufacturer
                                        </td>
                                        <td align="left">
                                            <select className="form-control static-width-15vw"
                                                style={this.state.a10 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                onChange={(e) => {
                                                    var index = e.nativeEvent.target.selectedIndex;

                                                    this.setState({ manufacturer: e.target.value, a1: false, Man: e.nativeEvent.target[index].text });
                                                    if (e.target.value !== 0) {
                                                        this.getFrameDropdowns("model", e.target.value);
                                                    }
                                                }}
                                                value={this.state.manufacturer}
                                            >
                                                {this.state.manufacturers && this.state.manufacturers.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td align="right">
                                            Seg Height
                                        </td>
                                        <td align="left">
                                            <Input
                                                type='text'
                                                onChange={(e) => { this.setState({ segHeight: e.target.value }); }}
                                                className={'static-width-15vw'}
                                                value={this.state.segHeight}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            Material
                                        </td>
                                        <td align="left">
                                            <select className="form-control static-width-15vw"
                                                style={this.state.a11 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                onChange={(e) => {
                                                    this.setState({ model: e.target.value });
                                                }}
                                                value={this.state.model}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.models && this.state.models.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td align="right">
                                            Lab Location
                                        </td>
                                        <td align="left">
                                            <select className="form-control static-width-15vw"
                                                style={this.state.a3 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                onChange={(e) => this.setState({ labLocation: e.target.value, a3: false })}
                                                value={this.state.labLocation}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                <option key={1} value={"Headquarters"}>Headquarters</option>
                                                <option key={2} value={"Midland"}>Midland</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            Color
                                        </td>
                                        <td align="left">
                                            <Input
                                                type='text'
                                                style={this.state.a12 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                onChange={(e) => { this.setState({ color: e.target.value }); }}
                                                className={'static-width-15vw'}
                                                value={this.state.color}
                                            />
                                        </td>
                                        <td align="right">
                                            Reference #
                                        </td>
                                        <td align="left">
                                            <Input
                                                type='text'
                                                maxLength={40}
                                                onChange={(e) => { this.setState({ referenceNumber: e.target.value, a6: false }); }}
                                                placeholder={this.state.a6 ? 'Required' : ''}
                                                value={this.state.referenceNumber}
                                                style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            Eye Size
                                        </td>
                                        <td align="left">
                                            <select className="form-control static-width-15vw"
                                                style={this.state.a13 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                onChange={(e) => {
                                                    this.setState({ eyeSize: e.target.value });
                                                }}
                                                value={this.state.eyeSize}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.Eye_Size && this.state.Eye_Size?.map((item, idx) =>
                                                    <option key={idx} value={item.size}>{item.size}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td align="right">
                                            Date Mailed
                                        </td>
                                        <td align="left">
                                            <Input
                                                type='date'
                                                onChange={(e) => {
                                                    this.setState({ mailedByDateFormatted: e.target.value, a4: false });
                                                }}
                                                placeholder={this.state.a4 ? 'Required' : 'Date Mailed'}
                                                value={this.state.mailedByDateFormatted ?? ''}
                                                style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right" valign="top" style={{ paddingTop: '10px' }}>
                                            Description
                                        </td>
                                        <td align="left" valign="top">
                                            <Input
                                                type='text'
                                                onChange={(e) => { this.setState({ description: e.target.value, a6: false }); }}
                                                placeholder={this.state.a6 ? 'Required' : ''}
                                                value={this.state.description}
                                                style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            />
                                        </td>
                                        <td align="right" valign="top">
                                            Notes
                                        </td>
                                        <td align="left">
                                            <textarea
                                                onChange={(e) => { this.setState({ frameNotes: e.target.value }) }}
                                                value={this.state.frameNotes ?? ''}
                                                className="form-control"
                                                style={{ height: '120px', width: '400px', resize: 'none' }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} align="right">
                                            <Button
                                                color='primary'
                                                onClick={() => this.setState({ showFramesModal: false, frameId: undefined })}
                                                style={{ whiteSpace: 'nowrap', marginRight: '20px', marginTop: '20px' }}
                                                disabled={this.state.loading ? true : false}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='primary'
                                                onClick={async () => await this.saveFrame()}
                                                style={{ whiteSpace: 'nowrap', marginTop: '20px' }}
                                                disabled={this.state.loading ? true : false}
                                            >
                                                {this.state.frameId === undefined ? "Add" : "Update"}
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </Modal>
                </div>
            </div>
        )
    }

    editFrame(item) {
        this.setState({
            frameId: item.id,
            manufacturer: item.frameManufacturerId,
            model: item.frameModelId,
            color: item.color,
            eyeSize: item.eyeSize,
            segHeight: item.segHeight,
            frameNotes: item.notes,
            description: item.description,
            referenceNumber: item.referenceNumber,
            labLocation: item.labLocation,
            mailedByDateFormatted: item.mailedByDateFormatted === '' || item.mailedByDateFormatted === null || item.mailedByDateFormatted === '0001-01-01' ? undefined : item.mailedByDateFormatted,
            showFramesModal: true,
        });
    }

    async deleteFrame() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var data = {
            Id: this.state.frameId
        }

        await fetch('api/Student/deleteReplacementFrame', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ loading: false, frameId: undefined, toastMessage: 'Frame deleted successfully', showToast: true, showDeleteFrameModal: false, labFrames: data.previousFrames });
                this.hideToast();
            })
            .catch(() => {
                this.setState({ loading: false, toastErrorMessage: 'Error deleting frame', showEmailError: true, showFramesModal: false });
                this.hideToast();
                console.log('error in deleting');
            });
    }

    viewNote(notes) {
        this.setState({ labNotes: notes, showNotesModal: true });
    }

    async saveFrame() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var data = {
            Id: this.state.frameId,
            ReplacementId: this.state.replacementId,
            ExamId: this.state.selectedExamId === '' || this.state.selectedExamId === undefined || this.state.selectedExamId === 0 ? null : this.state.selectedExamId,
            Manufacturer: this.state.manufacturer,
            Material: this.state.model,
            Color: this.state.color,
            EyeSize: this.state.eyeSize,
            Notes: this.state.frameNotes,
            ReferenceNumber: this.state.referenceNumber,
            Description: this.state.description,
            DateMailed: this.state.mailedByDateFormatted === '' || this.state.mailedByDateFormatted === undefined || this.state.mailedByDateFormatted === '0001-01-01' ? null : this.state.mailedByDateFormatted,
            SegHeight: this.state.segHeight,
            LabLocation: this.state.labLocation
        }

        await fetch('api/Student/SaveFrame', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ loading: false, frameId: undefined, toastMessage: 'Frame added successfully', showToast: true, showFramesModal: false, labFrames: data.previousFrames });
                this.hideToast();
            })
            .catch(() => {
                this.setState({ loading: false, toastErrorMessage: 'Error adding frame', showEmailError: true, showFramesModal: false });
                this.hideToast();
                console.log('error in saveFrame');
            });
    }

    validateEmail() {
        if (this.state.email && this.state.email.length !== 0) {
            if (!userFunctions.validateEmail(this.state.email)) {
                this.setState({ showEmailError: true, toastErrorMessage: "The email address enter is not a valid format", a16: true });
                this.hideToast();
                if (this.inputRef.current) {
                    this.inputRef.current.focus();
                }
                return false;
            }
        }
        return true;
    }

    async getFrameDropdowns(name, id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Frames/GetFrameDropdownByNameId?name=' + name + '&id=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (name === "manufacturer") {
                    this.setState({
                        manufacturers: data.manufacturers,
                        models: [],
                        colors: [],
                        eyeSizes: []
                    }, function () {
                        this.getFrameDropdowns('model', this.state.manufacturers[0].id);
                        this.setState({ manufacturer: this.state.manufacturers[0].id });
                    });
                }
                else if (name === "model") {
                    this.setState({
                        models: data.models,
                        colors: [],
                        eyeSizes: []
                    });
                }
                else if (name === "color") {
                    this.setState({
                        colors: data.colors,
                        eyeSizes: []
                    });
                }
                else if (name === "eyesize") {
                    this.setState({
                        eyeSizes: data.eyeSizes
                    });
                }

                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in getFrameDropdowns - ' + error.message);
                this.setState({ loading: false });
            });
    }

    async GetStudentReplacementById(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;

        await fetch(`api/Student/GetStudentReplacementById?Id=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({
                    firstName: data.firstName ?? '',
                    preferredName: data.preferredName ?? '',
                    lastName: data.lastName ?? '',
                    dateOfBirthFormatted: data.dateOfBirthFormatted ?? '',
                    studentSchool: data.studentSchool ?? '',
                    studentDistrict: data.studentDistrict ?? '',
                    dateOrderReceivedFormatted: data.dateReceivedFormatted ?? '',
                    submittedBy: data.submittedBy ?? '',
                    previousExams: data.previousExams ?? '',
                    labFrames: data.previousFrames,
                    selectedExamId: data.selectedExamId ?? '',
                    valid: data.valid === null ? false : data.valid,
                    invalid: data.valid === null ? false : !data.valid,
                    reasonInvalid: data.reasonInvalid,
                    replacementReason: data.replacementReason ?? '',
                    showReplacementReasonOther: data.replacementReason === 'Other' ? true : false,
                    replacementReasonOther: data.replacementReasonOther ?? '',
                    phone: data.phone ?? '',
                    email: data.email ?? '',
                    contactSummary: data.contactSummary ?? '',
                    trackingNumber: data.trackingNumber ?? '',
                    mailedByDateFormatted: data.mailedByDateFormatted,
                    dateMailedToLabFormatted: data.dateMailedToLabFormatted,
                    dateReceivedFromLabFormatted: data.dateReceivedFromLabFormatted,
                    address: data.address,
                    city: data.city,
                    stateId: data.stateId,
                    zip: data.zip,
                    mailedHome: data.mailedToSchool === null ? false : !data.mailedToSchool,
                    mailedSchool: data.mailedToSchool === null ? false : data.mailedToSchool,
                    notes: data.notes ?? '',
                    finalSRxODSphere: data.finalSRxODSphere,
                    finalSRxODCyl: data.finalSRxODCyl,
                    finalSRxODAxis: data.finalSRxODAxis,
                    finalSRxODAdd: data.finalSRxODAdd,
                    finalSRxODPrism: data.finalSRxODPrism,
                    finalSRxODBase: data.finalSRxODBase,
                    finalSRxOSSphere: data.finalSRxOSSphere,
                    finalSRxOSCyl: data.finalSRxOSCyl,
                    finalSRxOSAxis: data.finalSRxOSAxis,
                    finalSRxOSAdd: data.finalSRxOSAdd,
                    finalSRxOSPrism: data.finalSRxOSPrism,
                    finalSRxOSBase: data.finalSRxOSBase,
                    distancePDOD: data.distancePDOD,
                    distancePDOS: data.distancePDOS,
                    distancePDTotal: data.distancePDTotal,
                    nearPDOD: data.nearPDOD,
                    nearPDOS: data.nearPDOS,
                    nearPDTotal: data.nearPDTotal,
                    loading: false
                });
            })
            .catch(error => {
                console.log("error in GetStudentReplacementById - " + error);
                this.setState({ loading: false });
            });
    }

    async getExamDataById(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;

        await fetch(`api/Student/getExamDataById?Id=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({
                    labFrames: data.previousFrames,
                    finalSRxODSphere: data.finalSRxODSphere,
                    finalSRxODCyl: data.finalSRxODCyl,
                    finalSRxODAxis: data.finalSRxODAxis,
                    finalSRxODAdd: data.finalSRxODAdd,
                    finalSRxODPrism: data.finalSRxODPrism,
                    finalSRxODBase: data.finalSRxODBase,
                    finalSRxOSSphere: data.finalSRxOSSphere,
                    finalSRxOSCyl: data.finalSRxOSCyl,
                    finalSRxOSAxis: data.finalSRxOSAxis,
                    finalSRxOSAdd: data.finalSRxOSAdd,
                    finalSRxOSPrism: data.finalSRxOSPrism,
                    finalSRxOSBase: data.finalSRxOSBase,
                    distancePDOD: data.distancePDOD,
                    distancePDOS: data.distancePDOS,
                    distancePDTotal: data.distancePDTotal,
                    nearPDOD: data.nearPDOD,
                    nearPDOS: data.nearPDOS,
                    nearPDTotal: data.nearPDTotal,
                    loading: false
                });
            })
            .catch(error => {
                console.log("error in getExamDataById - " + error);
                this.setState({ loading: false });
            });
    }

    async saveReplacement() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var data = this.state;

        var _data = {
            id: data.replacementId ?? null,
            studentId: data.studentId ?? null,
            dateReceived: data.dateOrderReceivedFormatted ?? null,
            submittedBy: data.submittedBy ?? null,
            selectedExamId: this.state.selectedExamId === '' || this.state.selectedExamId === undefined || this.state.selectedExamId === 0 ? null : this.state.selectedExamId,
            valid: data.valid ?? false,
            reasonInvalid: data.reasonInvalid ?? null,
            replacementReason: data.replacementReason ?? null,
            replacementReasonOther: data.replacementReasonOther ?? null,
            phone: data.phone ?? null,
            email: data.email ?? null,
            contactSummary: data.contactSummary ?? null,
            trackingNumber: data.trackingNumber ?? null,
            mailedByDate: data.mailedByDateFormatted === '' || data.mailedByDateFormatted === undefined || data.mailedByDateFormatted === null ? null : data.mailedByDateFormatted,
            dateMailedToLab: data.dateMailedToLabFormatted ?? null,
            dateReceivedFromLab: data.dateReceivedFromLabFormatted ?? null,
            address: data.address ?? null,
            city: data.city ?? null,
            stateId: data.stateId ?? null,
            zip: data.zip ?? null,
            mailedToSchool: data.mailedSchool ?? false
        }

        await fetch('api/Student/SaveReplacement', {
            method: 'POST',
            body: JSON.stringify(_data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status === 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ loading: false, toastMessage: 'Replacement saved successfully', showToast: true });
                this.hideToast();

                if (this.state.replacementId === undefined || this.state.replacementId === '') {
                    const url = new URL(window.location);
                    //const params = new URLSearchParams(window.location.search);
                    url.searchParams.delete('sid');
                    url.searchParams.append('Id', data);
                    window.history.replaceState(null, null, url);

                    this.setState({ replacementId: data });
                }
            })
            .catch(() => {
                this.setState({ loading: false, toastErrorMessage: 'Error saving replacement', showEmailError: true });
                this.hideToast();
                console.log('error in saveReplacement');
            });
    }

    async signout() {
        window.location.href = "/";
    }

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.sites;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }

    async handleSaveStudent() {
        if (this.state.firstName.length == 0) {
            this.setState({ a1: true });
        }
        else if (this.state.lastName.length == 0) {
            this.setState({ a3: true });
        }
        else if (this.state.dateOfBirth.length == 0) {
            this.setState({ a4: true });
        }
        else {
            if (this.validateEmail()) {
                const token = await authService.getAccessToken();
                var dto = {
                    // student info
                    Id: this.state.studentId == userFunctions.GuidEmpty() ? null : this.state.studentId,
                    Active: this.state.active,
                    FirstName: this.state.firstName ?? null,
                    PreferredName: this.state.preferredName ?? null,
                    LastName: this.state.lastName ?? null,
                    DateOfBirth: this.state.dateOfBirth ?? null,
                    Zip: this.state.zip ?? null,
                    DistrictId: this.state.districtId == userFunctions.GuidEmpty() ? null : this.state.districtId,
                    SiteId: this.state.siteId == userFunctions.GuidEmpty() ? null : this.state.siteId,
                    GradeId: this.state.gradeId == userFunctions.GuidEmpty() ? null : this.state.gradeId,
                    Teacher: this.state.teacher ?? null,
                    RoomNumber: this.state.roomNumber ?? null,
                    GenderId: this.state.genderId == userFunctions.GuidEmpty() ? null : this.state.genderId,
                    EthnicityId: this.state.ethnicityId == userFunctions.GuidEmpty() ? null : this.state.ethnicityId,
                    // student info checkboxes
                    MedicaidPatient: this.state.medicaid,
                    MedicaidIdNumber: this.state.medicaidNumber ?? null,
                    ReducedLunch: this.state.reducedLunch,
                    ExamConsentOnFile: this.state.examConsentOnFile,
                    PhotoConsent: this.state.photoConsent,
                    IEP504Plan: this.state.IEP504Plan,
                    WearsGlassesPerNurse: this.state.wearsGlassesPerNurse,
                    // parent info
                    ParentName: this.state.parentName ?? null,
                    ParentMobilePhone: this.state.parentMobilePhone ?? null,
                    ParentEmail: this.state.email ?? null,

                    ReceivedEyeExam: this.state.ReceivedEyeExam == undefined ? null : this.state.ReceivedEyeExam == 'Yes' ? true : false,
                    ReceivedEyeExamFromEyeThrive: this.state.ReceivedEyeExamFromEyeThrive == undefined ? null : this.state.ReceivedEyeExamFromEyeThrive == 'Yes' ? true : false,
                    PrescribedGlasses: this.state.PrescribedGlasses == undefined ? null : this.state.PrescribedGlasses == 'Yes' ? true : false,
                    PrescribedGlassesHowLongAgo: this.state.PrescribedGlassesHowLongAgo == undefined ? null : this.state.PrescribedGlassesHowLongAgo,
                    ChildWearsGlassesNow: this.state.ChildWearsGlassesNow == undefined ? null : this.state.ChildWearsGlassesNow == 'Yes' ? true : false,
                    BlurryVision: this.state.BlurryVision == undefined ? null : this.state.BlurryVision == 'Yes' ? true : false,
                    SurgeriesOnEyes: this.state.SurgeriesOnEyes == undefined ? null : this.state.SurgeriesOnEyes == 'Yes' ? true : false,
                    ChildHaveDiabetes: this.state.ChildHaveDiabetes == undefined ? null : this.state.ChildHaveDiabetes == 'Yes' ? true : false,
                    ListOfMedications: this.state.ListOfMedications == undefined ? null : this.state.ListOfMedications,
                    ListOfAllergies: this.state.ListOfAllergies == undefined ? null : this.state.ListOfAllergies,
                    MedicalConditions: this.state.MedicalConditions == undefined ? null : this.state.MedicalConditions,
                    EyeDiseaseHistory: this.state.EyeDiseaseHistory == undefined ? null : this.state.EyeDiseaseHistory,
                    DistrictOther: this.state.districtOther == undefined ? null : this.state.districtOther,
                    SchoolOther: this.state.schoolOther == undefined ? null : this.state.schoolOther
                };

                await this.saveStudent(dto, token);
            }
        }
    }

    async saveStudent(dto, token) {
        this.setState({ loading: true });
        await fetch('api/Student/SaveStudent', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ toastMessage: 'Student Information saved successfully' });
                this.setState({ showToast: true });

                this.setState({ studentId: data.id });

                data.id != undefined || data.id != null || data.id != userFunctions.GuidEmpty() ? this.setState({ uploadDisabled: false }) : this.setState({ uploadDisabled: true });

                this.setState({ loading: false });
                this.hideToast();
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in saveStudent - ' + error);
            });
        //this.setState({ success: data });
    }

    hideToast() {
        setTimeout(() => {
            this.setState({ toastMessage: '', toastErrorMessage: '', showToast: false, showEmailError: false });
        }, 3500);
    }

    formatPhoneNumber(phoneNumber) {
        var result;

        result = phoneNumber.replace(/[^0-9]/g, '');
        if (result.length == 10) {
            result = result.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        else {
            result = result.replace(/\//g, "");
        }

        return result;
    }
}