import React, { Component, useRef } from "react";
import UploadService from "../services/upload-files-roster.service";

export default class UploadFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
            uploading: false
        };

        this.selectFile = this.selectFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    loadImportData(districtId, siteId) {
        var _this = this;
        UploadService.getFiles(districtId, siteId)
            .then((files) => {
                this.setState({
                    fileInfos: files.data,
                }, function () {
                    if (_this.state.fileInfos?.length > 0) {
                        _this.onDataLoaded(true);
                    }
                    else {
                        _this.onDataLoaded(false);
                    }
                });
            })
    }

    componentDidMount() {
        UploadService.getFiles(this.props.DistrictId, this.props.SiteId).then((response) => {
            if (response) {
                this.setState({
                    fileInfos: response.data,
                });
            }
        });
    }

    selectFile(event) {
        this.setState({
            progressInfos: [],
            selectedFiles: event.target.files,
        });
    }

    uploadFiles(e) {
        const selectedFiles = this.state.selectedFiles;
        let _progressInfos = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }
        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i], this.props.DistrictId, this.props.SiteId);
                }
            }
        );
    }

    upload(idx, file, districtId, siteId) {
        var _this = this;
        let _progressInfos = [...this.state.progressInfos];
        this.setState({ uploading: true });

        UploadService.upload(file, districtId, siteId, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                _progressInfos,
            });
        })
            .then((response) => {
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
                    return {
                        message: nextMessage
                    };
                });

                this.setState({ uploading: false });
                return UploadService.getFiles(districtId, siteId);

            })
            .then((files) => {
                this.setState({
                    fileInfos: files.data
                }, function () {
                    if (_this.state.fileInfos?.length > 0) {
                        _this.onDataLoaded(true);
                    }
                    else {
                        _this.onDataLoaded(false);
                    }
                });
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage
                    };
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({
                        selectedFiles: undefined,
                        progressInfos: [],
                        message: []
                    })
                }, 5000);
            });
    }

    onDataLoaded = (event) => {
        this.props.onDataLoaded(event);
        this.refs.fileUpload.value = '';
    }

    onImportSucceed = (event) => {
        this.setState({ fileInfos: [] });
        this.props.onImportSucceed();
        this.props.onDataLoaded(false);
    }

    async importData() {
        UploadService.importData(this.state.fileInfos)
            .then((result) => {
                this.onImportSucceed();
            });
    }

    render() {
        const { selectedFiles, progressInfos, message, fileInfos } = this.state;
        return (
            <div>
                {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progressInfo.percentage + "%" }}
                                >
                                    {progressInfo.percentage}%
                                </div>
                            </div>
                        </div>
                    ))}
                <div className="row my-3">
                    <div className="col-5">
                        <label className="btn btn-default p-0" style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', textAlign: 'left', fontStyle: 'italic', color: '#a0a0a0' }}>
                            <input ref="fileUpload" type="file" accept=".csv" onChange={this.selectFile} style={{ background: 'rgba(0,0,0,0.05)', border: '1px solid #ccc', borderRadius: '0 6px 6px 0', marginBottom: '5px' }} />
                            *File must be uploaded in CSV format*
                        </label>
                    </div>
                    <div className="col-4" style={{ paddingTop: '2px' }}>
                        <button
                            className="button"
                            disabled={!selectedFiles || this.state.uploading}
                            onClick={(e) => this.uploadFiles(e)}
                        >
                            Upload
                        </button>
                    </div>
                </div>
                {message.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <div className="card">
                    <div className="card-header">Roster Import Results</div>
                    <table className="gridTable">
                        <thead>
                            <tr>
                                <th>
                                    Last Name From File
                                </th>
                                <th>
                                    First Name From File
                                </th>
                                <th>
                                    Birth Date From File
                                </th>
                                <th>
                                    Merge
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Last Name Found
                                </th>
                                <th>
                                    First Name Found
                                </th>
                                <th>
                                    Birth Date Found
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.fileInfos.length > 0 ?
                                    fileInfos && fileInfos.map((roster, index) =>
                                        <tr key={index}>
                                            <td>
                                                {roster.lastNameFromFile}
                                            </td>
                                            <td>
                                                {roster.firstNameFromFile}
                                            </td>
                                            <td>
                                                {roster.birthDateFromFile}
                                            </td>
                                            <td>
                                                {roster.status != 'Add New Student' ? <input name="merge" type="checkbox" checked={roster.mergeData} onChange={() => {
                                                    var roster = this.state.fileInfos;
                                                    var checked = roster[index].mergeData;
                                                    roster[index].mergeData = !checked;
                                                    this.setState({ fileInfos: roster });
                                                }} /> : null}
                                            </td>
                                            <td>
                                                {roster.status}
                                            </td>
                                            <td>
                                                {roster.lastNameFound}
                                            </td>
                                            <td>
                                                {roster.firstNameFound}
                                            </td>
                                            <td>
                                                {roster.birthDateFound}
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan={8} style={{ fontWeight: '500', textAlign: 'center' }}>
                                            No Results Found
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}