import React, { Component } from 'react';
import userFunctions from './UserFunctions';
import AdminFunctions from './admin/AdminFunctions';
import FrameFunctions from './FrameFunctions';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaSort, FaRegTrashAlt } from 'react-icons/fa'
import { DIMENSIONS } from './util/utilities';
import './layout/site.css';
import Modal from 'react-bootstrap/Modal';
import Moment from 'react-moment';

export class FrameInventory extends Component {
    static displayName = FrameInventory.name;

    constructor(props) {
        super(props);

        this.state = {
            outOfStock: false,
            frames: [],
            dispensedFrames: [],
            search: "",
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            dispensedSort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            noResultsDispensed: false,
            currentPage: 1,
            currentPageDispensed: 1,
            itemsPerPage: 10,
            itemsPerPageDispensed: 10,
            numberOfPages: 0,
            numberOfPagesDispensed: 0,
            showModal1: false,
            showInventory: false,
            showDeleteFrame: false,
            showDeleteDispensedFrame: false,
            selectedFrame: {},
            deleteFrame: { bin: {}, manufacturer: {}, model: {}, color: {}, eyeSize: {}, bridge: {}, temple: {} },
            dispensedFrame: {},
            deleteDispensedFrame: { bin: {}, manufacturer: {}, model: {}, color: {}, eyeSize: {}, bridge: {}, temple: {}, dispensedLocation: {} },
            updateValid: false,
            dropdowns: {
                bins: [], manufacturers: [], colors: [], bridges: [], eyeSizes: [], temples: [], models: [], dispensedLocations: [],
                selectedColors: [], selectedEyeSizes: [],
            },
            showAddNewModal: false,
            AddNewModalPlaceholderText: '',
            AddNewModalTitle: '',
            AddNewModalLabel: '',
            AddNewModalText: '',
            showAddNewModel: false,
            showAddNewColor: false,
            showAddNewEyeSize: false,
            isMisc: false,
            MiscEyeSize: '',
            Eye_Size: [{
                'id': '14',
                'size': '43',
                'value': ''
            },
            {
                'id': '1',
                'size': '44',
                'value': ''
            },
            {
                'id': '2',
                'size': '45',
                'value': ''
            },
            {
                'id': '3',
                'size': '46',
                'value': ''
            },
            {
                'id': '4',
                'size': '47',
                'value': ''
            },
            {
                'id': '5',
                'size': '48',
                'value': ''
            },
            {
                'id': '6',
                'size': '49',
                'value': ''
            },
            {
                'id': '7',
                'size': '50',
                'value': ''
            },
            {
                'id': '8',
                'size': '51',
                'value': ''
            },
            {
                'id': '9',
                'size': '52',
                'value': ''
            },
            {
                'id': '10',
                'size': '53',
                'value': ''
            },
            {
                'id': '11',
                'size': '54',
                'value': ''
            },
            {
                'id': '12',
                'size': '55',
                'value': ''
            },
            {
                'id': '13',
                'size': '56+',
                'value': ''
            }],
            Man: 'Misc',
            MiscColor: '',
            Range: ''
        };

        this.handleClose = this.handleClose.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeDispensed = this.handlePageChangeDispensed.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageSizeChangeDispensed = this.handlePageSizeChangeDispensed.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
        this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
        this.handleOpenDispenseModal = this.handleOpenDispenseModal.bind(this);
        this.handleOpenInventoryModal = this.handleOpenInventoryModal.bind(this);
        this.handleDeleteFrame = this.handleDeleteFrame.bind(this);
        this.handleDeleteDispensedFrame = this.handleDeleteDispensedFrame.bind(this);
        this.handleValidateFrame = this.handleValidateFrame.bind(this);

        window.addEventListener('resize', this.setWindowDimensions);
    }

    handleClose() {
        var selectedFrame = {};
        var dispensedFrame = {};
        var deleteFrame = { bin: {}, manufacturer: {}, model: {}, color: {}, eyeSize: {}, bridge: {}, temple: {} };
        var deleteDispensedFrame = { bin: {}, manufacturer: {}, model: {}, color: {}, eyeSize: {}, bridge: {}, temple: {}, dispensedLocation: {} };

        this.setState({
            showModal1: false,
            showModal2: false,
            showInventory: false,
            showDeleteFrame: false,
            showDeleteDispensedFrame: false,
            selectedFrame: selectedFrame,
            dispensedFrame: dispensedFrame,
            deleteFrame: deleteFrame,
            deleteDispensedFrame: deleteDispensedFrame,
            Man: undefined
        });
    }

    handlePageChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageChangeDispensed(event) {
        this.setState({ currentPageDispensed: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.frames.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.frames.length / pageSize) })
        }
    }

    handlePageSizeChangeDispensed(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPageDispensed: pageSize });
        if (this.state.currentPageDispensed > Math.ceil(this.state.dispensedFrames.length / pageSize)) {
            this.setState({ currentPageDispensed: Math.ceil(this.state.dispensedFrames.length / pageSize) })
        }
    }

    handleDropdownChange(frameType, dropdown, value) {
        var filtered = [];

        if (dropdown == 'binId') {
            filtered = this.state.dropdowns.bins.filter(item => {
                return item.id == value;
            });
        } else if (dropdown == 'manufacturerId') {
            filtered = this.state.dropdowns.manufacturers.filter(item => {
                return item.id == value;
            });
            if (value && value != '') {
                this.setState({ showAddNewModel: true });
            }
            else {
                this.setState({ showAddNewModel: false });
            }
        } else if (dropdown == 'modelId') {
            filtered = this.state.dropdowns.models.filter(item => {
                return item.id == value;
            });
            if (value && value != '') {
                this.setState({ showAddNewColor: true });
            }
            else {
                this.setState({ showAddNewColor: false });
            }
        } else if (dropdown == 'colorId') {
            filtered = this.state.dropdowns.colors.filter(item => {
                return item.id == value;
            });
            if (value && value != '') {
                this.setState({ showAddNewEyeSize: true });
            }
            else {
                this.setState({ showAddNewEyeSize: false });
            }
        } else if (dropdown == 'bridgeId') {
            filtered = this.state.dropdowns.bridges.filter(item => {
                return item.id == value;
            });
        } else if (dropdown == 'templeId') {
            filtered = this.state.dropdowns.temples.filter(item => {
                return item.id == value;
            });
        } else if (dropdown == 'dispensedLocationId') {
            filtered = this.state.dropdowns.dispensedLocations.filter(item => {
                return item.id == value;
            });
        }

        var frame = frameType === 'frame' ? this.state.selectedFrame : this.state.dispensedFrame;

        if (filtered.length != 0) {
            if (dropdown == 'manufacturerId') {
                var dropdowns = this.state.dropdowns;
                dropdowns.models = filtered[0].models;
                dropdowns.selectedColors = [];
                dropdowns.selectedEyeSizes = [];

                frame.modelId = '';
                frame.colorId = '';
                frame.eyeSizeId = '';

                this.setState({ dropdowns: dropdowns });
            } else if (dropdown == 'modelId') {
                var dropdowns = this.state.dropdowns;
                dropdowns.colors = filtered[0].colors;
                frame.colorId = '';
                frame.eyeSizeId = '';
                this.setState({ dropdowns: dropdowns });
            } else if (dropdown == 'colorId') {
                var dropdowns = this.state.dropdowns;
                dropdowns.eyeSizes = filtered[0].eyeSizes;
                frame.eyeSizeId = '';
                this.setState({ dropdowns: dropdowns });
            }
        } else {
            if (dropdown == 'manufacturerId') {
                var dropdowns = this.state.dropdowns;
                dropdowns.models = [];
                dropdowns.selectedColors = [];
                dropdowns.selectedEyeSizes = [];
                frame.modelId = '';
                frame.colorId = '';
                frame.eyeSizeId = '';
                this.setState({ dropdowns: dropdowns });
            } else if (dropdown == 'modelId') {
                var dropdowns = this.state.dropdowns;
                dropdowns.colors = [];
                frame.colorId = '';
                frame.eyeSizeId = '';
                this.setState({ dropdowns: dropdowns });
            } else if (dropdown == 'colorId') {
                var dropdowns = this.state.dropdowns;
                dropdowns.eyeSizes = [];
                frame.eyeSizeId = '';
                this.setState({ dropdowns: dropdowns });
            }
        }

        frame[dropdown] = value;

        this.handleValidateFrame(frame, frameType === 'frame' ? undefined : 1);

        if (frameType === 'frame') {
            this.setState({ selectedFrame: frame });
        } else {
            this.setState({ dispensedFrame: frame });
        }
    }

    handleOpenAddModal() {
        var selectedFrame = {
            id: '',
            currentInventory: 0,
            binId: null,
            manufacturerId: null,
            modelId: null,
            colorId: null,
            eyeSizeId: null,
            bridgeId: null,
            templeId: null,
        }

        this.handleValidateFrame(selectedFrame);

        var dropdowns = this.state.dropdowns;
        dropdowns.models = [];
        dropdowns.colors = [];
        dropdowns.eyeSizes = [];

        this.setState({ dropdowns: dropdowns, selectedFrame: selectedFrame, showModal1: true, Man: 'Misc' }, function () {
            var mans = this.state.dropdowns.manufacturers;
            this.handleDropdownChange('frame', 'manufacturerId', mans[0].id)
        });
    }

    handleOpenEditModal(id) {
        var frame = this.state.frames.filter(function (frame) {
            return frame.id == id;
        });

        if (frame.length != 0) {
            var selected = frame[0];

            var selectedFrame = {
                id: selected.id,
                currentInventory: selected.currentInventory,
                binId: selected.bin == null ? null : selected.bin.id,
                manufacturerId: selected.manufacturer == null ? null : selected.manufacturer.id,
                modelId: selected.model == null ? null : selected.model.id,
                colorId: selected.color == null ? null : selected.color.id,
                eyeSizeId: selected.eyeSize == null ? null : selected.eyeSize.id,
                bridgeId: selected.bridge == null ? null : selected.bridge.id,
                templeId: selected.temple == null ? null : selected.temple.id,
                description: selected.description,
                miscEyeSize: selected.miscEyeSize,
                miscEyeSizeText: selected.miscEyeSizeText,
                miscColor: selected.miscColor,
                miscRange: selected.miscRange,
                //jobLocation: selected.jobLocation,
                //segHeight: selected.segHeight,
                comment: selected.comment,
                man: selected.manufacturer.name
            }

            this.setState({
                Man: 'Misc',
                manufacturerId: selected.manufacturer.id,
                materialId: selected.model.id,
                Range: selectedFrame.miscRange
            });

            var dropdowns = this.state.dropdowns;

            if (selectedFrame.manufacturerId != null) {

                var manufacturer = dropdowns.manufacturers.filter(mfg => {
                    return mfg.id == selectedFrame.manufacturerId;
                });

                if (manufacturer.length != 0) {
                    dropdowns.models = manufacturer[0].models;

                    var model = dropdowns.models.filter(model => {
                        return model.id == selectedFrame.modelId;
                    });

                    if (model.length != 0) {
                        dropdowns.colors = model[0].colors;

                        var color = dropdowns.colors.filter(color => {
                            return color.id == selectedFrame.colorId;
                        });

                        if (color.length != 0) {
                            dropdowns.eyeSizes = color[0].eyeSizes;
                        } else {
                            dropdowns.eyeSizes = [];
                        }
                    }
                } else {
                    dropdowns.models = [];
                    dropdowns.colors = [];
                    dropdowns.eyeSizes = [];
                }
            } else {
                dropdowns.models = [];
                dropdowns.colors = [];
                dropdowns.eyeSizes = [];
            }

            this.handleValidateFrame(selectedFrame);
            this.setState({ dropdowns: dropdowns, selectedFrame: selectedFrame, showModal1: true, Man: 'Misc' }, function () {
                //var mans = this.state.dropdowns.manufacturers;
                //this.handleDropdownChange('frame', 'manufacturerId', mans[0].id)
                //alert(selected.model.id);
            });
        }
    }

    handleOpenDispenseModal() {
        var dispensedFrame = {
            binId: '',
            manufacturerId: '',
            modelId: '',
            colorId: '',
            eyeSizeId: '',
            bridgeId: '',
            templeId: '',
            dispensedLocationId: '',
            comments: '',
            man: 'Misc'
        }
        this.setState({ dispensedFrame: dispensedFrame, Man: 'Misc', showModal2: true }, function () {
            var mans = this.state.dropdowns.manufacturers;
            this.handleDropdownChange('frame', 'manufacturerId', mans[0].id)
        });
    }

    handleOpenInventoryModal(id) {
        var frame = this.state.frames.filter(function (frame) {
            return frame.id == id;
        });

        if (frame.length != 0) {
            var selected = frame[0];
            var selectedFrame = {
                id: selected.id,
                currentInventory: selected.currentInventory,
                binId: selected.bin == null ? null : selected.bin.id,
                manufacturerId: selected.manufacturer == null ? null : selected.manufacturer.id,
                modelId: selected.model == null ? null : selected.model.id,
                colorId: selected.color == null ? null : selected.color.id,
                eyeSizeId: selected.eyeSize == null ? null : selected.eyeSize.id,
                bridgeId: selected.bridge == null ? null : selected.bridge.id,
                templeId: selected.temple == null ? null : selected.temple.id,
                description: selected.description,
                miscEyeSize: selected.miscEyeSize,
                miscEyeSizeText: selected.miscEyeSizeText,
                miscColor: selected.miscColor,
                miscRange: selected.miscRange,
                //jobLocation: selected.jobLocation,
                //segHeight: selected.segHeight,
                comment: selected.comment,
                man: 'Misc'
            }

            this.handleValidateFrame(selectedFrame);
            this.setState({ selectedFrame: selectedFrame, showInventory: true });
        }
    }

    handleDeleteFrame(id) {
        var frames = this.state.frames.filter(frame => {
            return frame.id == id;
        });

        if (frames.length != 0) {
            var selected = frames[0];
            var selectedFrame = {
                id: selected.id,
                currentInventory: selected.currentInventory,
                binId: selected.bin == null ? null : selected.bin.id,
                manufacturerId: selected.manufacturer == null ? null : selected.manufacturer.id,
                modelId: selected.model == null ? null : selected.model.id,
                colorId: selected.color == null ? null : selected.color.id,
                eyeSizeId: selected.eyeSize == null ? null : selected.eyeSize.id,
                bridgeId: selected.bridge == null ? null : selected.bridge.id,
                templeId: selected.temple == null ? null : selected.temple.id,
                description: selected.description,
                miscEyeSize: selected.miscEyeSize,
                miscEyeSizeText: selected.miscEyeSizeText,
                miscColor: selected.miscColor,
                miscRange: selected.miscRange,
                //jobLocation: selected.jobLocation,
                //segHeight: selected.segHeight,
                comment: selected.comment,
                man: 'Misc'
            }

            this.setState({ deleteFrame: selected, selectedFrame: selectedFrame, showDeleteFrame: true });
        }
    }

    handleDeleteDispensedFrame(id) {
        var frames = this.state.dispensedFrames.filter(frame => {
            return frame.id == id;
        });

        if (frames.length != 0) {
            var selected = frames[0];
            var selectedFrame = {
                id: selected.id,
                currentInventory: 0,
                binId: selected.bin == null ? null : selected.bin.id,
                manufacturerId: selected.manufacturer == null ? null : selected.manufacturer.id,
                modelId: selected.model == null ? null : selected.model.id,
                colorId: selected.color == null ? null : selected.color.id,
                eyeSizeId: selected.eyeSize == null ? null : selected.eyeSize.id,
                bridgeId: selected.bridge == null ? null : selected.bridge.id,
                templeId: selected.temple == null ? null : selected.temple.id,
                miscEyeSize: selected.miscEyeSize,
                miscEyeSizeText: selected.miscEyeSizeText,
                miscColor: selected.miscColor,
                man: 'Misc'
            };
            var dispensedFrame = {
                id: selected.id,
                binId: selected.bin == null ? null : selected.bin.id,
                manufacturerId: selected.manufacturer == null ? null : selected.manufacturer.id,
                modelId: selected.model == null ? null : selected.model.id,
                colorId: selected.color == null ? null : selected.color.id,
                eyeSizeId: selected.eyeSize == null ? null : selected.eyeSize.id,
                bridgeId: selected.bridge == null ? null : selected.bridge.id,
                templeId: selected.temple == null ? null : selected.temple.id,
                dispensedLocationId: selected.dispensedLocation == null ? null : selected.dispensedLocation.id,
                comments: selected.comments,
                miscEyeSize: selected.miscEyeSize,
                miscEyeSizeText: selected.miscEyeSizeText,
                miscColor: selected.miscColor,
                man: 'Misc'
            };
            this.setState({ deleteDispensedFrame: selected, selectedFrame: selectedFrame, dispensedFrame: dispensedFrame, showDeleteDispensedFrame: true });
        }
    }

    handleValidateFrame(frame, dispensed) {
        if (frame !== undefined) {
            if (frame.man === 'Misc') {
                if (dispensed === undefined) {
                    if ((frame.binId === '' || frame.modelId === '' || frame.miscEyeSize === '')
                        || (frame.binId === null || frame.modelId === null || frame.miscEyeSize === null) &&
                        frame.currentInventory === undefined || frame.currentInventory === null || frame.currentInventory === null) {
                        this.setState({ updateValid: false });
                    }
                    else {
                        this.setState({ updateValid: true });
                    }
                }
                else {
                    if ((frame.binId === '' || frame.modelId === '' || frame.miscEyeSizeText === '') ||
                        (frame.binId === null || frame.modelId === null || frame.miscEyeSizeText === null) ||
                        (frame.dispensedLocationId === undefined || frame.dispensedLocationId === null || frame.dispensedLocationId === '')) {
                        this.setState({ updateValid: false });
                    }
                    else {
                        this.setState({ updateValid: true });
                    }
                }
            }
            else {
                if ((frame.binId === '' || frame.modelId === '' || frame.colorId === '' || frame.bridgeId === '' || frame.templeId === '' || frame.eyeSizeId === '') ||
                    (frame.binId === null || frame.modelId === null || frame.colorId === null || frame.bridgeId === null || frame.templeId === null || frame.eyeSizeId === null)) {
                    this.setState({ updateValid: false });
                } else {
                    if (frame.dispensedLocationId != undefined && (frame.dispensedLocationId === null || frame.dispensedLocationId === '')) {
                        this.setState({ updateValid: false });
                    } else {
                        if (frame.currentInventory != undefined) {
                            var numberVal = Number(frame.currentInventory);
                            if (Number.isFinite(numberVal) && numberVal >= 0) {
                                this.setState({ updateValid: true });
                            } else {
                                this.setState({ updateValid: false });
                            }
                        } else {
                            this.setState({ updateValid: true });
                        }
                    }
                }
            }
        }
        else {
            this.setState({ updateValid: true });
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.getFrames();
        }
    }

    async getFrames() {
        //debugger
        this.setState({ loading: true });
        this.setState({ frames: [] });

        var dto = {
            QueryString: this.state.search.length == 0 ? "" : this.state.search,
            OutOfStock: this.state.outOfStock
        }

        const response = await FrameFunctions.postFrameInventorySearchAsync(dto);

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            this.setState({ frames: data, loading: false, noResults: data.length === 0 ? true : false });
        } else {
            this.setState({ loading: false });
        }
    }

    async getDispensedFrames() {
        //debugger
        this.setState({ loading: true });
        this.setState({ dispensedFrames: [] });

        const response = await FrameFunctions.getDispensedFramesAsync();

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            this.setState({ dispensedFrames: data, loading: false, noResultsDispensed: data.length === 0 ? true : false });
        } else {
            this.setState({ loading: false });
        }
    }

    async getDropdowns() {
        const response = await FrameFunctions.getFrameDropdownsAsync();

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            data.models = [];
            data.colors = [];
            data.eyeSizes = [];

            this.setState({ dropdowns: data, loading: false });
        } else {
            this.setState({ loading: false });
        }
    }

    async saveFrame() {
        if (this.state.updateValid) {
            this.setState({ loading: true });

            var dto = {
                frame: this.state.selectedFrame,
                queryString: this.state.search,
                outOfStock: this.state.outOfStock,
            }

            this.handleClose();

            var response = await FrameFunctions.postSaveFrameAsync(dto);

            if (response.status == 401) {
                await userFunctions.signout();
            } else if (response.status == 200) {

                var data = await response.json();
                this.setState({
                    frames: data,
                    loading: false,
                    noResults: data.length === 0 ? true : false,
                    Man: 'Misc'
                });
                await this.getDropdowns();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    async saveDispensedFrame() {
        if (this.state.updateValid) {
            this.setState({ loading: true });
            var dispensedFrame = this.state.dispensedFrame;
            dispensedFrame.manufacturerId = this.state.dropdowns.manufacturers[0].id;

            this.handleClose();

            var response = await FrameFunctions.postAddDispensedFrameAsync(dispensedFrame);

            if (response.status == 401) {
                await userFunctions.signout();
            } else if (response.status == 200) {
                var data = await response.json();
                this.setState({
                    dispensedFrames: data,
                    loading: false,
                    noResultsDispensed: data.length === 0 ? true : false,
                    Man: 'Misc'
                });
                this.getFrames();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    async deleteFrame() {
        this.setState({ loading: true });

        var dto = {
            frame: this.state.selectedFrame,
            queryString: this.state.search,
            outOfStock: this.state.outOfStock,
        }

        this.handleClose();

        var response = await FrameFunctions.postDeleteFrameAsync(dto);

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {

            var data = await response.json();
            this.setState({ frames: data, loading: false, noResults: data.length === 0 ? true : false });
        } else {
            this.setState({ loading: false });
        }
    }

    async deleteDispensedFrame() {
        this.setState({ loading: true });
        var dispensedFrame = this.state.dispensedFrame;

        this.handleClose();

        var response = await FrameFunctions.postDeleteDispensedFrameAsync(dispensedFrame);

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            this.setState({ dispensedFrames: data, loading: false, noResultsDispensed: data.length === 0 ? true : false });
            this.getFrames();
        } else {
            this.setState({ loading: false });
        }
    }

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const frames = this.state.frames;

        frames.sort((a, b) => {
            if (typeof a[column] === 'object' && a[column] !== null) {
                return a[column].name.localeCompare(b[column].name);
            } else if (typeof a[column] === 'number') {
                return a[column] - b[column];
            } else {
                return (a[column] instanceof Date ? (b[column] - a[column]) : a[column].localeCompare(b[column]));
            }
        });

        if (direction === 'desc') {
            frames.reverse();
        }

        this.setState({
            frames: frames,
            sort: {
                column,
                direction
            }
        })
    }

    handleDispensedSort(column) {
        const direction = this.state.dispensedSort.column ? (this.state.dispensedSort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const frames = this.state.dispensedFrames;

        frames.sort((a, b) => {
            if (typeof a[column] === 'object' && a[column] !== null) {
                return a[column].name.localeCompare(b[column].name);
            } else if (typeof a[column] === 'number') {
                return a[column] - b[column];
            } else {
                return (a[column] instanceof Date ? (b[column] - a[column]) : a[column].localeCompare(b[column]));
            }
        });

        if (direction === 'desc') {
            frames.reverse();
        }

        this.setState({
            dispensedFrames: frames,
            dispensedSort: {
                column,
                direction
            }
        })
    }

    async componentDidMount() {
        this.setWindowDimensions();

        this.getFrames();
        this.getDropdowns();
        this.getDispensedFrames();
    }

    saveMiscFrameInventory(id) {
        var data = {
            Id: id === undefined ? null : id,
            Quantity: 0,
            Bin: '',
            ManufacturerId: '',
            Description: '',
            MaterialId: '',
            Color: '',
            EyeSizeId: '',
            EyeSizeRange: '',
            //SegHeight: '',
            //JobLocation: '',
        };
        FrameFunctions.saveMiscFrameInventory(data);
    }

    render() {
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;

        const indexOfLastDispensed = this.state.currentPageDispensed * this.state.itemsPerPageDispensed;
        const indexOfFirstDispensed = indexOfLastDispensed - this.state.itemsPerPageDispensed;

        const currentFrames = this.state.frames.slice(indexOfFirstItem, indexOfLastItem);
        const currentDispensed = this.state.dispensedFrames.slice(indexOfFirstDispensed, indexOfLastDispensed);

        const renderItems = currentFrames.map((frame, i) => {
            return (
                <tr key={frame.id} style={{ cursor: 'pointer' }}>
                    <td><a href="#" onClick={(e) => {
                        this.handleOpenEditModal(frame.id);
                        e.preventDefault();
                    }}>Edit</a></td>
                    <td>
                        <FaRegTrashAlt
                            style={{ margin: 'auto', width: '100%' }}
                            color={'black'}
                            onClick={() => this.handleDeleteFrame(frame.id)}
                        />
                    </td>
                    <td>{frame.bin != null ? frame.bin?.name : ''}</td>
                    <td><a href="#" onClick={(e) => {
                        this.handleOpenInventoryModal(frame.id);
                        e.preventDefault();
                    }}>{frame.currentInventory}</a></td>
                    <td>{frame.manufacturer != null ? frame.manufacturer?.name : ''}</td>
                    <td>{frame.model != null ? frame.manufacturer?.name === 'Misc' ? '' : frame.model?.name : ''}</td>
                    <td>{frame.model != null ? frame.manufacturer?.name === 'Misc' ? frame.model?.name : '' : ''}</td>
                    <td>{frame.description != null ? frame.description : ''}</td>
                    <td>{frame.color != null || frame.miscColor != null ? frame.manufacturer?.name === 'Misc' ? frame.miscColor : frame.color?.name : ''}</td>
                    <td>{frame.eyeSize != null || frame.miscEyeSizeText != null ? frame.manufacturer?.name === 'Misc' ? frame.miscEyeSizeText : frame.eyeSize?.name : ''}</td>
                    <td>{frame.miscRange != null ? frame.miscRange : ''}</td>
                    <td>{frame.bridge != null ? frame.bridge?.name : ''}</td>
                    <td>{frame.temple != null ? frame.temple?.name : ''}</td>
                    <td>{frame.dispensed}</td>
                </tr>
            )
        });

        const renderDispensedItems = currentDispensed.map((frame, i) => {
            return (
                <tr key={frame.id} style={{ cursor: 'pointer' }}>
                    <td>
                        <FaRegTrashAlt
                            style={{ margin: 'auto', width: '100%' }}
                            color={'black'}
                            onClick={() => this.handleDeleteDispensedFrame(frame.id)}
                        />
                    </td>
                    <td>{frame.dateDispensed != null && <Moment format="M/D/YY">{frame.dateDispensed}</Moment>}</td>
                    <td>{frame.bin != null ? frame.bin?.name : ''}</td>
                    <td>{frame.manufacturer != null ? frame.manufacturer?.name : ''}</td>
                    <td>{frame.model != null ? frame.manufacturer?.name === 'Misc' ? '' : frame.model?.name : ''}</td>
                    <td>{frame.model != null ? frame.manufacturer?.name === 'Misc' ? frame.model?.name : '' : ''}</td>
                    <td>{frame.color != null || frame.miscColor != null ? frame.manufacturer?.name === 'Misc' ? frame.miscColor : frame.color?.name : ''}</td>
                    <td>{frame.eyeSize != null || frame.miscEyeSizeText != null ? frame.manufacturer?.name === 'Misc' ? frame.miscEyeSizeText : frame.eyeSize?.name : ''}</td>
                    <td>{frame.bridge != null ? frame.bridge?.name : ''}</td>
                    <td>{frame.temple != null ? frame.temple?.name : ''}</td>
                    <td>{frame.dispensedLocation != null ? frame.dispensedLocation?.name : ''}</td>
                    <td>{frame.comments}</td>
                    <td>{frame.dispensedBy}</td>
                </tr>
            )
        });


        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.frames.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const pageNumbersDispensed = [];
        for (let i = 1; i <= Math.ceil(this.state.dispensedFrames.length / this.state.itemsPerPageDispensed); i++) {
            pageNumbersDispensed.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option key={number} value={number}>{number}</option>
            );
        });

        const renderPageNumbersDispensed = pageNumbersDispensed.map(number => {
            return (
                <option key={number} value={number}>{number}</option>
            );
        });

        const renderManufacturers = this.state.dropdowns.manufacturers?.map(manufacturer => {
            return (
                <option key={manufacturer.id} value={manufacturer.id}>{manufacturer.name}</option>
            );
        });

        const renderModels = this.state.dropdowns.models?.map(model => {
            return (
                <option key={model.id} value={model.id}>{model.name}</option>
            );
        });

        const renderColors = this.state.dropdowns.colors?.map(color => {
            return (
                <option key={color.id} value={color.id}>{color.name}</option>
            );
        });

        const renderEyeSizes = this.state.dropdowns.eyeSizes?.map(eyeSize => {
            return (
                <option key={eyeSize.id} value={eyeSize.id}>{eyeSize.name}</option>
            );
        });

        const renderBins = this.state.dropdowns.bins?.map(bin => {
            return (
                <option key={bin.id} value={bin.id}>{bin.name}</option>
            );
        });

        const renderBridges = this.state.dropdowns.bridges.map(bridge => {
            return (
                <option key={bridge.id} value={bridge.id}>{bridge.name}</option>
            );
        });

        const renderTemples = this.state.dropdowns.temples.map(temple => {
            return (
                <option key={temple.id} value={temple.id}>{temple.name}</option>
            );
        });

        const renderLocations = this.state.dropdowns.dispensedLocations.map(location => {
            return (
                <option key={location.id} value={location.id}>{location.name}</option>
            );
        });

        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Frames Inventory</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                name='search'
                                id='search'
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search'
                            />
                        </Col>
                        <Col xl='4' lg='4' md='4' sm='4' xs='4' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Button
                                    color='primary'
                                    onClick={() => this.getFrames()}
                                    style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                    disabled={this.state.loading ? true : false}
                                >
                                    Search
                                </Button>
                                {/*TODO: Why does this not line up well with the button?*/}
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ outOfStock: !this.state.outOfStock }) }}
                                    id='chkActive'
                                    checked={this.state.siteActive}
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginLeft: '10px' }}
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '10px' }}>Show Out of Stock Frames</label>
                            </div>
                        </Col>
                    </Row>
                    {this.state.frames.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPage} onChange={this.handlePageChange}>
                                {renderPageNumbers}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    )}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive className="gridTable">
                                <thead>
                                    <tr>
                                        <th>
                                            Edit
                                        </th>
                                        <th>
                                            Delete
                                        </th>
                                        <th>
                                            Bin #
                                            <FaSort
                                                color={this.state.sort.column === 'bin' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('bin')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Current Inventory
                                            <FaSort
                                                color={this.state.sort.column === 'currentInventory' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('currentInventory')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Manufacturer
                                            <FaSort
                                                color={this.state.sort.column === 'manufacturer' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('manufacturer')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Model
                                            <FaSort
                                                color={this.state.sort.column === 'model' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('model')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Material
                                            <FaSort
                                                color={this.state.sort.column === 'material' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('material')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Description
                                            <FaSort
                                                color={this.state.sort.column === 'description' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('description')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Color
                                            <FaSort
                                                color={this.state.sort.column === 'color' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('color')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Eye Size
                                            <FaSort
                                                color={this.state.sort.column === 'eyeSize' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('eyeSize')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Size Range
                                            <FaSort
                                                color={this.state.sort.column === 'eyeSizeRange' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('eyeSizeRange')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Bridge
                                            <FaSort
                                                color={this.state.sort.column === 'bridge' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('bridge')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Temple
                                            <FaSort
                                                color={this.state.sort.column === 'temple' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('temple')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            # Dispensed
                                            <FaSort
                                                color={this.state.sort.column === 'dispensed' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('dispensed')}
                                                className='sort-button'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true && this.state.loadingDocument === false ? (
                                        <tr>
                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                <Spinner color="primary" children='' />
                                            </td>
                                        </tr>

                                    ) : (
                                        <>
                                            {!this.state.noResults ? (
                                                <>
                                                    {renderItems}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td className={'absolute-center'}>
                                                        <h5>No Results Found</h5>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Button
                                color='primary'
                                onClick={() => this.handleOpenAddModal()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Add Frames
                            </Button>
                        </Col>
                        <Col>
                            {this.state.frames.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPage} onChange={this.handlePageChange}>
                                        {renderPageNumbers}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <h3>Dispensed Broken/Unusable Frames</h3>
                        </Col>
                    </Row>
                    {this.state.dispensedFrames.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPageDispensed} onChange={this.handlePageChangeDispensed}>
                                {renderPageNumbersDispensed}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPageDispensed} onChange={this.handlePageSizeChangeDispensed}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    )}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive className="gridTable">
                                <thead>
                                    <tr>
                                        <th>
                                            Delete
                                        </th>
                                        <th>
                                            Date Dispensed
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'dateDispensed' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('dateDispensed')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Bin #
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'bin' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('bin')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Manufacturer
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'manufacturer' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('manufacturer')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Model
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'model' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('model')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Material
                                            <FaSort
                                                color={this.state.sort.column === 'material' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('material')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Color
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'color' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('color')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Eye Size
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'eyeSize' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('eyeSize')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Bridge
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'bridge' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('bridge')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Temple
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'temple' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('temple')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Dispensed Location
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'dispensedLocation' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('dispensedLocation')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Comments
                                        </th>
                                        <th>
                                            Dispensed By
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'dispensedBy' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('dispensedBy')}
                                                className='sort-button'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true && this.state.loadingDocument === false ? (
                                        <tr>
                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                <Spinner color="primary" children='' />
                                            </td>
                                        </tr>

                                    ) : (
                                        <>
                                            {!this.state.noResultsDispensed ? (
                                                <>
                                                    {renderDispensedItems}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td className={'absolute-center'}>
                                                        <h5>No Results Found</h5>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Button
                                color='primary'
                                onClick={() => this.handleOpenDispenseModal()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px', marginBottom: '15px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px', marginBottom: '15px' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Dispense Frame
                            </Button>
                        </Col>
                        <Col>
                            {this.state.dispensedFrames.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPageDispensed} onChange={this.handlePageChangeDispensed}>
                                        {renderPageNumbersDispensed}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerDispensedPage} onChange={this.handlePageSizeChangeDispensed}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
                <div>
                    <Modal show={this.state.showModal1} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header>
                            <Modal.Title>Edit Inventory</Modal.Title>
                        </Modal.Header>
                        {this.state.Man === "Misc" ?
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bin #</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.binId}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.binId = e.target.value;
                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });
                                                this.handleDropdownChange('frame', 'binId', e.target.value);
                                            }}>
                                            <option value="">Select Bin...</option>
                                            {renderBins}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.currentInventory = e.target.value;

                                                this.setState({ selectedFrame: selected });

                                                if (e.target.value == undefined || e.target.value == "") {
                                                    this.handleValidateFrame(this.state.selectedFrame);
                                                }
                                                else {
                                                    this.setState({ updateValid: true });
                                                }
                                            }}
                                            placeholder={this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}
                                            value={this.state.selectedFrame.currentInventory}
                                            style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Manufacturer</label>

                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Description</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            var index = e.nativeEvent.target.selectedIndex;
                                            this.setState({ Man: e.nativeEvent.target[index].text });

                                            var selected = this.state.selectedFrame;
                                            selected.manufacturerId = e.target.value;
                                            selected.man = e.nativeEvent.target[index].text;

                                            this.setState({ selectedFrame: selected },
                                                function () {
                                                    this.handleValidateFrame(this.state.selectedFrame);
                                                });

                                            this.handleDropdownChange('dispense', 'manufacturerId', e.target.value);
                                        }}
                                            value={this.state.selectedFrame.manufacturerId}
                                        >
                                            {renderManufacturers}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.description = e.target.value;

                                                this.setState({ selectedFrame: selected });
                                            }}
                                            className="form-control"
                                            value={this.state.selectedFrame.description}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Material</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Color</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select
                                            value={this.state.selectedFrame.modelId}
                                            className="form-control"
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.modelId = e.target.value;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });

                                                this.handleDropdownChange('dispense', 'modelId', e.target.value);
                                            }}>
                                            <option value="">Select Material...</option>
                                            {renderModels}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.miscColor = e.target.value;

                                                this.setState({ selectedFrame: selected });
                                            }}
                                            className="form-control"
                                            value={this.state.selectedFrame.miscColor}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bridge</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Temple</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.bridgeId}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.bridgeId = e.target.value;

                                                this.setState({ selectedFrame: selected });
                                                this.handleDropdownChange('frame', 'bridgeId', e.target.value);
                                            }}>
                                            <option value="">Select Bridge...</option>
                                            {renderBridges}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.templeId}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.templeId = e.target.value;

                                                this.setState({ selectedFrame: selected });
                                                this.handleDropdownChange('frame', 'templeId', e.target.value);
                                            }}>
                                            <option value="">Select Temple...</option>
                                            {renderTemples}
                                        </select>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Eye Size</label>
                                    </Col>
                                    <Col>
                                        
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            style={this.state.a13 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            onChange={(e) => {
                                                if (e.target.value != 0) {
                                                    var selected = this.state.selectedFrame;
                                                    selected.miscEyeSize = e.target.value;

                                                    var range = this.state.Eye_Size.find(f => f.id == e.target.value);

                                                    selected.miscRange = range.value;
                                                    selected.miscEyeSizeText = range.size;

                                                    this.setState({
                                                        Range: range.value,
                                                        EyeSizeText: range.size,
                                                        selectedFrame: selected
                                                    },
                                                        function () {
                                                            this.handleValidateFrame();
                                                        })
                                                }
                                            }}
                                            value={this.state.selectedFrame.miscEyeSize}
                                        >
                                            <option key={0} value={0}>---Select---</option>
                                            {this.state.Eye_Size?.map((item, idx) =>
                                                <option key={idx} value={item.id}>{item.size}</option>
                                            )}
                                        </select>
                                    </Col>
                                    <Col>
                                       
                                    </Col>
                                </Row>

                                {/*<Row style={{ marginTop: '10px' }}>*/}
                                {/*    <Col>*/}
                                {/*        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Seg Height</label>*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Job Location</label>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row style={{ marginTop: '5px' }}>*/}
                                {/*    <Col>*/}
                                {/*        <Input*/}
                                {/*            type='text'*/}
                                {/*            onChange={(e) => {*/}
                                {/*                var selected = this.state.selectedFrame;*/}
                                {/*                selected.segHeight = e.target.value;*/}

                                {/*                this.setState({*/}
                                {/*                    SegHeight: e.target.value,*/}
                                {/*                    selectedFrame: selected*/}
                                {/*                },*/}
                                {/*                    function () {*/}
                                {/*                        this.handleValidateFrame();*/}
                                {/*                    });*/}
                                {/*            }}*/}
                                {/*            className="form-control"*/}
                                {/*            value={this.state.selectedFrame.segHeight}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <select className="form-control"*/}
                                {/*            style={this.state.a3 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}*/}
                                {/*            onChange={(e) => {*/}
                                {/*                var selected = this.state.selectedFrame;*/}
                                {/*                selected.jobLocation = e.target.value;*/}

                                {/*                this.setState({*/}
                                {/*                    JobLocation: e.target.value,*/}
                                {/*                    selectedFrame: selected*/}
                                {/*                },*/}
                                {/*                    function () {*/}
                                {/*                        this.handleValidateFrame();*/}
                                {/*                    });*/}
                                {/*            }}*/}
                                {/*            value={this.state.selectedFrame.jobLocation}*/}
                                {/*        >*/}
                                {/*            <option key={0} value={0}>---Select---</option>*/}
                                {/*            <option key={1} value={"Off Site"}>Off Site</option>*/}
                                {/*            <option key={2} value={"On Site"}>On Site</option>*/}
                                {/*        </select>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <textarea
                                            className='form-control'
                                            name='Comments'
                                            id='Comments'
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.comment = e.target.value;
                                                this.setState({ selectedFrame: selected });
                                            }
                                            }
                                            placeholder='Comments'
                                            value={this.state.selectedFrame.comment}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            :
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bin #</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.binId}
                                            onChange={(e) => {
                                                this.handleDropdownChange('frame', 'binId', e.target.value);
                                            }}>
                                            <option value="">Select Bin...</option>
                                            {renderBins}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.currentInventory = e.target.value;
                                                this.setState({ selectedFrame: selected });
                                            }}
                                            placeholder={this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}
                                            value={this.state.selectedFrame.currentInventory}
                                            style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Manufacturer</label>
                                        <label style={{ fontSize: '0.7rem', marginLeft: '0.5rem', marginBottom: '0.0rem', color: 'cornflowerblue', cursor: 'pointer' }}
                                            onClick={() => { this.setState({ showAddNewModal: true, AddNewModalTitle: 'Add Manufacturer', AddNewModalPlaceholderText: 'Manufacturer' }) }}>Add New</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Eye Size</label>
                                        <label style={{ fontSize: '0.7rem', marginLeft: '0.5rem', marginBottom: '0.0rem', color: 'cornflowerblue', cursor: this.state.showAddNewEyeSize ? 'pointer' : 'default' }}
                                            onClick={() => {
                                                if (this.state.showAddNewEyeSize) {
                                                    this.setState({
                                                        showAddNewModal: true,
                                                        AddNewModalTitle: 'Add Eye Size',
                                                        AddNewModalPlaceholderText: 'Eye Size'
                                                    });
                                                }
                                            }}>Add New</label> </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.manufacturerId}
                                            onChange={(e) => {
                                                var index = e.nativeEvent.target.selectedIndex;
                                                this.setState({ Man: e.nativeEvent.target[index].text });
                                                this.handleDropdownChange('frame', 'manufacturerId', e.target.value);
                                            }}>
                                            {renderManufacturers}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.eyeSizeId}
                                            onChange={(e) => {
                                                this.handleDropdownChange('frame', 'eyeSizeId', e.target.value);
                                            }}>
                                            <option value="">Select Eye Size...</option>
                                            {renderEyeSizes}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Model</label>
                                        <label style={{ fontSize: '0.7rem', marginLeft: '0.5rem', marginBottom: '0.0rem', color: 'cornflowerblue', cursor: this.state.showAddNewModel ? 'pointer' : 'default' }}
                                            onClick={() => {
                                                if (this.state.showAddNewModel)
                                                    this.setState({
                                                        showAddNewModal: true,
                                                        AddNewModalTitle: 'Add Model',
                                                        AddNewModalPlaceholderText: 'Model',
                                                    });
                                            }}>Add New</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bridge</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.modelId}
                                            onChange={(e) => {
                                                this.handleDropdownChange('frame', 'modelId', e.target.value);
                                            }}>
                                            <option value="">Select Model...</option>
                                            {renderModels}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.bridgeId}
                                            onChange={(e) => {
                                                this.handleDropdownChange('frame', 'bridgeId', e.target.value);
                                            }}>
                                            <option value="">Select Bridge...</option>
                                            {renderBridges}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Color</label>
                                        <label style={{ fontSize: '0.7rem', marginLeft: '0.5rem', marginBottom: '0.0rem', color: 'cornflowerblue', cursor: this.state.showAddNewColor ? 'pointer' : 'default' }}
                                            onClick={() => {
                                                if (this.state.showAddNewColor) {
                                                    this.setState({
                                                        showAddNewModal: true,
                                                        AddNewModalTitle: 'Add Color',
                                                        AddNewModalPlaceholderText: 'Color'
                                                    });
                                                }
                                            }}>Add New</label></Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Temple</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.colorId}
                                            onChange={(e) => {
                                                this.handleDropdownChange('frame', 'colorId', e.target.value);
                                            }}>
                                            <option value="">Select Color...</option>
                                            {renderColors}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.templeId}
                                            onChange={(e) => {
                                                this.handleDropdownChange('frame', 'templeId', e.target.value);
                                            }}>
                                            <option value="">Select Temple...</option>
                                            {renderTemples}
                                        </select>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        }
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Close
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.saveFrame()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={!this.state.updateValid}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showModal2} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header>
                            <Modal.Title>Dispense Frame</Modal.Title>
                        </Modal.Header>
                        {this.state.Man === "Misc" ?
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bin #</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Manufacturer</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'binId', e.target.value);
                                        }}>
                                            <option value="">Select Bin...</option>
                                            {renderBins}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            var index = e.nativeEvent.target.selectedIndex;
                                            var selected = this.state.dispensedFrame;
                                            selected.man = e.nativeEvent.target[index].text;

                                            this.setState({
                                                Man: e.nativeEvent.target[index].text,
                                                dispensedFrame: selected
                                            });

                                            this.handleDropdownChange('dispense', 'manufacturerId', e.target.value);
                                        }}>
                                            {renderManufacturers}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Model</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Eye Size</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'modelId', e.target.value);

                                            var selected = this.state.dispensedFrame;
                                            selected.modelId = e.target.value;

                                            this.setState({ dispensedFrame: selected });
                                        }}>
                                            <option value="">Select Model...</option>
                                            {renderModels}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control"
                                            style={this.state.a13 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            onChange={(e) => {
                                                if (e.target.value != 0) {
                                                    var selected = this.state.dispensedFrame;
                                                    selected.miscEyeSize = e.target.value;

                                                    var range = this.state.Eye_Size.find(f => f.id == e.target.value);

                                                    selected.miscRange = range.value;
                                                    selected.miscEyeSizeText = range.size;

                                                    this.setState({
                                                        Range: range.value,
                                                        EyeSizeText: range.size,
                                                        dispensedFrame: selected
                                                    },
                                                        function () {
                                                            this.handleValidateFrame(this.state.dispensedFrame, 1);
                                                        })
                                                }
                                            }}
                                            value={this.state.dispensedFrame.miscEyeSize}
                                        >
                                            <option key={0} value={0}>---Select---</option>
                                            {this.state.Eye_Size?.map((item, idx) =>
                                                <option key={idx} value={item.id}>{item.size}</option>
                                            )}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Color</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bridge</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <Input
                                            type='text'
                                            onChange={(e) => {
                                                var selected = this.state.dispensedFrame;
                                                selected.miscColor = e.target.value;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.dispensedFrame, 1);
                                                    });
                                            }}
                                            className="form-control"
                                            value={this.state.dispensedFrame.miscColor}
                                        />
                                    </Col>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'bridgeId', e.target.value);
                                        }}>
                                            <option value="">Select Bridge...</option>
                                            {renderBridges}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Temple</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Dispensed Location</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'templeId', e.target.value);
                                        }}>
                                            <option value="">Select Temple...</option>
                                            {renderTemples}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'dispensedLocationId', e.target.value);
                                        }}>
                                            <option value="">Select Dispensed Location...</option>
                                            {renderLocations}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <textarea
                                            className='form-control'
                                            name='Comments'
                                            id='Comments'
                                            onChange={(e) => {
                                                var dispensed = this.state.dispensedFrame;
                                                dispensed.comments = e.target.value;
                                                this.setState({ dispensedFrame: dispensed });
                                            }
                                            }
                                            placeholder='Comments'
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            :
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bin #</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Manufacturer</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'binId', e.target.value);
                                        }}>
                                            <option value="">Select Bin...</option>
                                            {renderBins}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            var index = e.nativeEvent.target.selectedIndex;
                                            var selected = this.state.dispensedFrame;
                                            selected.man = e.nativeEvent.target[index].text;

                                            this.setState({ Man: e.nativeEvent.target[index].text, dispensedFrame: selected });

                                            this.handleDropdownChange('dispense', 'manufacturerId', e.target.value);
                                        }}>
                                            {renderManufacturers}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Model</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Eye Size</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'modelId', e.target.value);
                                        }}>
                                            <option value="">Select Model...</option>
                                            {renderModels}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'eyeSizeId', e.target.value);
                                        }}>
                                            <option value="">Select Eye Size...</option>
                                            {renderEyeSizes}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Color</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bridge</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'colorId', e.target.value);
                                        }}>
                                            <option value="">Select Color...</option>
                                            {renderColors}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'bridgeId', e.target.value);
                                        }}>
                                            <option value="">Select Bridge...</option>
                                            {renderBridges}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Temple</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Dispensed Location</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'templeId', e.target.value);
                                        }}>
                                            <option value="">Select Temple...</option>
                                            {renderTemples}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" onChange={(e) => {
                                            this.handleDropdownChange('dispense', 'dispensedLocationId', e.target.value);
                                        }}>
                                            <option value="">Select Dispensed Location...</option>
                                            {renderLocations}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <textarea
                                            className='form-control'
                                            name='Comments'
                                            id='Comments'
                                            onChange={(e) => {
                                                var dispensed = this.state.dispensedFrame;
                                                dispensed.comments = e.target.value;
                                                this.setState({ dispensedFrame: dispensed });
                                            }
                                            }
                                            placeholder='Comments'
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                        }
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Close
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.saveDispensedFrame()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={!this.state.updateValid}
                            >
                                Add
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showInventory} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header >
                            <Modal.Title>Update Current Inventory</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var selected = this.state.selectedFrame;
                                            selected.currentInventory = e.target.value;
                                            this.handleValidateFrame(selected);
                                            this.setState({ selectedFrame: selected });
                                        }}
                                        placeholder={this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}
                                        value={this.state.selectedFrame.currentInventory}
                                        style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Close
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.saveFrame()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={!this.state.updateValid}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={this.state.showDeleteFrame} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header >
                            <Modal.Title>This will delete the frame below.  Do you want to continue?</Modal.Title>
                        </Modal.Header>
                        {this.state.selectedFrame.man === "Misc" ?
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bin #</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            disabled={true}
                                            value={this.state.selectedFrame.binId}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.binId = e.target.value;
                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });
                                                this.handleDropdownChange('frame', 'binId', e.target.value);
                                            }}>
                                            <option value="">Select Bin...</option>
                                            {renderBins}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            disabled={true}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.currentInventory = e.target.value;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });
                                            }}
                                            placeholder={this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}
                                            value={this.state.selectedFrame.currentInventory}
                                            style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Manufacturer</label>

                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Description</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select
                                            className="form-control"
                                            disabled={true}
                                            onChange={(e) => {
                                                var index = e.nativeEvent.target.selectedIndex;
                                                this.setState({ Man: e.nativeEvent.target[index].text });

                                                var selected = this.state.selectedFrame;
                                                selected.manufacturerId = e.target.value;
                                                selected.man = e.nativeEvent.target[index].text;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });

                                                this.handleDropdownChange('dispense', 'manufacturerId', e.target.value);
                                            }}
                                            value={this.state.selectedFrame.manufacturerId}
                                        >
                                            {renderManufacturers}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            disabled={true}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.description = e.target.value;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });
                                            }}
                                            className="form-control"
                                            value={this.state.selectedFrame.description}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Material</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Color</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select
                                            value={this.state.selectedFrame.modelId}
                                            disabled={true}
                                            className="form-control"
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.modelId = e.target.value;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });

                                                this.handleDropdownChange('dispense', 'modelId', e.target.value);
                                            }}>
                                            <option value="">Select Material...</option>
                                            {renderModels}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            disabled={true}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.miscColor = e.target.value;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.selectedFrame);
                                                    });
                                            }}
                                            className="form-control"
                                            value={this.state.selectedFrame.miscColor}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bridge</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Temple</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.bridgeId}
                                            disabled={true}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.bridgeId = e.target.value;

                                                this.setState({ selectedFrame: selected });
                                                this.handleDropdownChange('frame', 'bridgeId', e.target.value);
                                            }}>
                                            <option value="">Select Bridge...</option>
                                            {renderBridges}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control"
                                            value={this.state.selectedFrame.templeId}
                                            disabled={true}
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.templeId = e.target.value;

                                                this.setState({ selectedFrame: selected });
                                                this.handleDropdownChange('frame', 'templeId', e.target.value);
                                            }}>
                                            <option value="">Select Temple...</option>
                                            {renderTemples}
                                        </select>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Eye Size</label>
                                    </Col>
                                    <Col>
                                        
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control"
                                            style={this.state.a13 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            disabled={true}
                                            onChange={(e) => {
                                                if (e.target.value != 0) {
                                                    var selected = this.state.selectedFrame;
                                                    selected.miscEyeSize = e.target.value;

                                                    var range = this.state.Eye_Size.find(f => f.id == e.target.value);

                                                    selected.miscRange = range.value;
                                                    selected.miscEyeSizeText = range.size;

                                                    this.setState({
                                                        Range: range.value,
                                                        EyeSizeText: range.size,
                                                        selectedFrame: selected
                                                    },
                                                        function () {
                                                            this.handleValidateFrame();
                                                        })
                                                }
                                            }}
                                            value={this.state.selectedFrame.miscEyeSize}
                                        >
                                            <option key={0} value={0}>---Select---</option>
                                            {this.state.Eye_Size?.map((item, idx) =>
                                                <option key={idx} value={item.id}>{item.size}</option>
                                            )}
                                        </select>
                                    </Col>
                                    <Col>
                                        
                                    </Col>
                                </Row>

                                {/*<Row style={{ marginTop: '10px' }}>*/}
                                {/*    <Col>*/}
                                {/*        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Seg Height</label>*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Job Location</label>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row style={{ marginTop: '5px' }}>*/}
                                {/*    <Col>*/}
                                {/*        <Input*/}
                                {/*            type='text'*/}
                                {/*            disabled={true}*/}
                                {/*            onChange={(e) => {*/}
                                {/*                var selected = this.state.selectedFrame;*/}
                                {/*                selected.segHeight = e.target.value;*/}

                                {/*                this.setState({*/}
                                {/*                    SegHeight: e.target.value,*/}
                                {/*                    selectedFrame: selected*/}
                                {/*                },*/}
                                {/*                    function () {*/}
                                {/*                        this.handleValidateFrame();*/}
                                {/*                    });*/}
                                {/*            }}*/}
                                {/*            className="form-control"*/}
                                {/*            value={this.state.selectedFrame.segHeight}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <select className="form-control"*/}
                                {/*            style={this.state.a3 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}*/}
                                {/*            disabled={true}*/}
                                {/*            onChange={(e) => {*/}
                                {/*                var selected = this.state.selectedFrame;*/}
                                {/*                selected.jobLocation = e.target.value;*/}

                                {/*                this.setState({*/}
                                {/*                    JobLocation: e.target.value,*/}
                                {/*                    selectedFrame: selected*/}
                                {/*                },*/}
                                {/*                    function () {*/}
                                {/*                        this.handleValidateFrame();*/}
                                {/*                    });*/}
                                {/*            }}*/}
                                {/*            value={this.state.selectedFrame.jobLocation}*/}
                                {/*        >*/}
                                {/*            <option key={0} value={0}>---Select---</option>*/}
                                {/*            <option key={1} value={"Off Site"}>Off Site</option>*/}
                                {/*            <option key={2} value={"On Site"}>On Site</option>*/}
                                {/*        </select>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <textarea
                                            className='form-control'
                                            disabled={true}
                                            name='Comments'
                                            id='Comments'
                                            onChange={(e) => {
                                                var selected = this.state.selectedFrame;
                                                selected.comment = e.target.value;
                                                this.setState({ selectedFrame: selected });
                                            }
                                            }
                                            placeholder='Comments'
                                            value={this.state.selectedFrame.comment}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            :
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bin #</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" disabled={true} value={this.state.selectedFrame.binId}>
                                            <option value="">Select Bin...</option>
                                            {renderBins}
                                        </select>
                                    </Col>
                                    <Col>
                                        <Input
                                            type='text'
                                            disabled={true}
                                            placeholder={this.state.selectedFrame.id == '' ? 'Quantity' : 'Current Inventory'}
                                            value={this.state.selectedFrame.currentInventory}
                                            style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Manufacturer</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Eye Size</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" disabled={true} value={this.state.selectedFrame.manufacturerId}>
                                            {renderManufacturers}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" disabled={true} value={this.state.selectedFrame.eyeSizeId}>
                                            <option value="">Select Eye Size...</option>
                                            {renderEyeSizes}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Model</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bridge</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" disabled={true} value={this.state.selectedFrame.modelId}>
                                            <option value="">Select Model...</option>
                                            {renderModels}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" disabled={true} value={this.state.selectedFrame.bridgeId}>
                                            <option value="">Select Bridge...</option>
                                            {renderBridges}
                                        </select>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '10px' }}>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Color</label>
                                    </Col>
                                    <Col>
                                        <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Temple</label>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col>
                                        <select className="form-control" disabled={true} value={this.state.selectedFrame.colorId}>
                                            <option value="">Select Color...</option>
                                            {renderColors}
                                        </select>
                                    </Col>
                                    <Col>
                                        <select className="form-control" disabled={true} value={this.state.selectedFrame.templeId}>
                                            <option value="">Select Temple...</option>
                                            {renderTemples}
                                        </select>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        }
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.deleteFrame()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                            >
                                Delete Frame
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showDeleteDispensedFrame} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header >
                            <Modal.Title>This will delete the dispensed frame below.  Do you want to continue?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bin #</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Manufacturer</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.dispensedFrame.binId}>
                                        <option value="">Select Bin...</option>
                                        {renderBins}
                                    </select>
                                </Col>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.dispensedFrame.manufacturerId}>
                                        {renderManufacturers}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Model</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Eye Size</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.dispensedFrame.modelId}>
                                        <option value="">Select Model...</option>
                                        {renderModels}
                                    </select>
                                </Col>
                                <Col>
                                    {this.state.dispensedFrame.man === "Misc" ?
                                        <select className="form-control"
                                            disabled={true}
                                            style={this.state.a13 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            onChange={(e) => {
                                                if (e.target.value != 0) {
                                                    var selected = this.state.dispensedFrame;
                                                    selected.miscEyeSize = e.target.value;

                                                    var range = this.state.Eye_Size.find(f => f.id == e.target.value);

                                                    selected.miscRange = range.value;
                                                    selected.miscEyeSizeText = range.size;

                                                    this.setState({
                                                        Range: range.value,
                                                        EyeSizeText: range.size,
                                                        dispensedFrame: selected
                                                    },
                                                        function () {
                                                            this.handleValidateFrame(this.state.dispensedFrame, 1);
                                                        })
                                                }
                                            }}
                                            value={this.state.dispensedFrame.miscEyeSize}
                                        >
                                            <option key={0} value={0}>---Select---</option>
                                            {this.state.Eye_Size?.map((item, idx) =>
                                                <option key={idx} value={item.id}>{item.size}</option>
                                            )}
                                        </select>
                                        :
                                        <select className="form-control" disabled={true} value={this.state.dispensedFrame.eyeSizeId}>
                                            <option value="">Select Eye Size...</option>
                                            {renderEyeSizes}
                                        </select>
                                    }
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Color</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Bridge</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    {this.state.dispensedFrame.man === "Misc" ?
                                        <Input
                                            type='text'
                                            disabled={true}
                                            onChange={(e) => {
                                                var selected = this.state.dispensedFrame;
                                                selected.miscColor = e.target.value;

                                                this.setState({ selectedFrame: selected },
                                                    function () {
                                                        this.handleValidateFrame(this.state.dispensedFrame, 1);
                                                    });
                                            }}
                                            className="form-control"
                                            value={this.state.dispensedFrame.miscColor}
                                        />
                                        :
                                        <select className="form-control" disabled={true} value={this.state.dispensedFrame.colorId}>
                                            <option value="">Select Color...</option>
                                            {renderColors}
                                        </select>
                                    }
                                </Col>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.dispensedFrame.bridgeId}>
                                        <option value="">Select Bridge...</option>
                                        {renderBridges}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Temple</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Dispensed Location</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.dispensedFrame.templeId}>
                                        <option value="">Select Temple...</option>
                                        {renderTemples}
                                    </select>
                                </Col>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.dispensedFrame.dispensedLocationId}>
                                        <option value="">Select Dispensed Location...</option>
                                        {renderLocations}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <textarea
                                        className='form-control'
                                        name='Comments'
                                        id='Comments'
                                        disabled={true}
                                        value={this.state.dispensedFrame.comments}
                                        placeholder='Comments'
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.deleteDispensedFrame()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                            >
                                Delete Dispensed Frame
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showAddNewModal} onHide={this.handleClose} dialogClassName="inventory-modal2" style={{ backgroundColor: '#0000dd11' }}>
                        <Modal.Header style={{ backgroundColor: '#f7f7f721' }}>
                            <Modal.Title>{this.state.AddNewModalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: '#f7f5f231' }}>
                            <Row>
                                <Col>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: '10px' }}>
                                        <label style={{ marginRight: '5px', marginBottom: '0' }}>{this.state.AddNewModalLabel}</label>
                                        <input
                                            type="text"
                                            className="form-control min-width-textinput"
                                            placeholder={"Add " + this.state.AddNewModalPlaceholderText}
                                            onChange={(e) => this.setState({ AddNewModalText: e.currentTarget.value })}
                                            autoFocus>
                                        </input>
                                        <Button
                                            color='primary'
                                            onClick={async () => {
                                                var manufacturerId = null;
                                                var modelId = null;
                                                var colorId = null;

                                                if (this.state.selectedFrame.manufacturerId && this.state.selectedFrame.manufacturerId.length > 0) {
                                                    manufacturerId = this.state.selectedFrame.manufacturerId;
                                                }
                                                if (this.state.selectedFrame.modelId && this.state.selectedFrame.modelId.length > 0) {
                                                    modelId = this.state.selectedFrame.modelId;
                                                }
                                                if (this.state.selectedFrame.colorId && this.state.selectedFrame.colorId.length > 0) {
                                                    colorId = this.state.selectedFrame.colorId;
                                                }

                                                var dto = {
                                                    What: this.state.AddNewModalPlaceholderText,
                                                    Name: this.state.AddNewModalText,
                                                    ManufacturerId: manufacturerId,
                                                    ModelId: modelId,
                                                    ColorId: colorId
                                                };

                                                await FrameFunctions.AddNew(dto)
                                                    .then((resp) => {
                                                        return resp.json();
                                                    })
                                                    .then(async (data) => {
                                                        //this.getDropdowns();
                                                        var ddlData = this.state.dropdowns;
                                                        if (this.state.AddNewModalPlaceholderText == "Manufacturer") {
                                                            var data = {
                                                                id: data,
                                                                name: this.state.AddNewModalText,
                                                                active: true
                                                            }
                                                            if (ddlData.manufacturers == undefined) ddlData.manufacturers = [];
                                                            ddlData.manufacturers.push(data);
                                                            var sorted = ddlData.manufacturers.sort((a, b) => a.name.localeCompare(b.name));
                                                            ddlData.manufacturers = sorted;

                                                            this.setState({
                                                                dropdowns: ddlData
                                                            }, function () {
                                                                var selected = this.state.selectedFrame;
                                                                selected.manufacturerId = data.id;
                                                                this.setState({
                                                                    selectedFrame: selected
                                                                });
                                                                if (data && data.id != undefined) {
                                                                    this.setState({ showAddNewModel: true });
                                                                }
                                                            });
                                                        }
                                                        else if (this.state.AddNewModalPlaceholderText == "Model") {
                                                            var data = {
                                                                id: data,
                                                                name: this.state.AddNewModalText,
                                                                active: true,
                                                                manufacturerId: manufacturerId
                                                            }
                                                            if (ddlData.models == undefined) ddlData.models = [];
                                                            ddlData.models.push(data);
                                                            var sorted = ddlData.models.sort((a, b) => a.name.localeCompare(b.name));
                                                            ddlData.models = sorted;
                                                            this.setState({
                                                                dropdowns: ddlData
                                                            }, function () {
                                                                var selected = this.state.selectedFrame;
                                                                selected.modelId = data.id;
                                                                this.setState({
                                                                    selectedFrame: selected
                                                                });
                                                                if (data && data.id != undefined) {
                                                                    this.setState({ showAddNewColor: true });
                                                                }
                                                            });
                                                        }
                                                        else if (this.state.AddNewModalPlaceholderText == "Color") {
                                                            var data = {
                                                                id: data,
                                                                name: this.state.AddNewModalText,
                                                                active: true,
                                                                modelId: modelId
                                                            }
                                                            if (ddlData.colors == undefined) ddlData.colors = [];
                                                            ddlData.colors.push(data);
                                                            var sorted = ddlData.colors.sort((a, b) => a.name.localeCompare(b.name));
                                                            ddlData.colors = sorted;
                                                            this.setState({
                                                                dropdowns: ddlData
                                                            }, function () {
                                                                var selected = this.state.selectedFrame;
                                                                selected.colorId = data.id;
                                                                this.setState({
                                                                    selectedFrame: selected
                                                                });
                                                                if (data && data.id != undefined) {
                                                                    this.setState({ showAddNewEyeSize: true });
                                                                }
                                                            });
                                                        }
                                                        else if (this.state.AddNewModalPlaceholderText == "Eye Size") {
                                                            var data = {
                                                                id: data,
                                                                name: this.state.AddNewModalText,
                                                                active: true,
                                                                colorId: colorId
                                                            }
                                                            if (ddlData.eyeSizes == undefined) ddlData.eyeSizes = [];
                                                            ddlData.eyeSizes.push(data);
                                                            var sorted = ddlData.eyeSizes.sort((a, b) => a.name.localeCompare(b.name));
                                                            ddlData.eyeSizes = sorted;
                                                            this.setState({
                                                                dropdowns: ddlData
                                                            }, function () {
                                                                var selected = this.state.selectedFrame;
                                                                selected.eyeSizeId = data.id;
                                                                this.setState({
                                                                    selectedFrame: selected
                                                                });
                                                            });
                                                        }
                                                        this.setState({ showAddNewModal: false });
                                                    });
                                            }}
                                            style={{ flex: '1', whiteSpace: 'nowrap', padding: '6px 15px', marginLeft: '5px', height: '38px' }}
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            color='primary'
                                            onClick={() => this.setState({ showAddNewModal: false })}
                                            style={{ flex: '1', whiteSpace: 'nowrap', padding: '6px 15px', marginLeft: '5px', height: '38px' }}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </div>
            </div >
        )
    }
}
