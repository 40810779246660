import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip } from 'reactstrap';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from './util/utilities';
import './layout/site.css';
import { useHistory, useLocation } from 'react-router-dom';

export class SiteSearch extends Component {
    static displayName = SiteSearch.name;

    constructor(props) {
        super(props);

        this.state = {
            siteList: [],
            client: null,
            search: null,
            sites: [],
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";
    }

    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.sites.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.sites.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.sites.length / pageSize) })
        }
    }

    async componentDidMount() {
        this.setWindowDimensions();
        const isAdmin = await AdminFunctions.isAdminAsync();
        this.setState({ isAdmin: isAdmin });
        if (isAdmin) {

        }
        else {
        }
        this.searchSites();
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchSites();
        }
    }

    newSite() {
        window.location.href = '/SiteDetails';
    }

    render() {
        // Logic for displaying sites!
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = this.state.sites;

        const renderItems = currentItems.map((site, i) => {
            return (
                <tr key={i} style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/SiteDetails?Id=' + site.id}>
                    <td>{site.siteName}</td>
                    <td><input type="checkbox" checked={site.active} readOnly disabled /></td>
                    <td>{site.primaryContact}</td>
                    <td>{site.email}</td>
                    <td>{site.officePhone}</td>
                </tr>
            )
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.sites.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option value={number}>{number}</option>
            );
        });

        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Site Search</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                value={this.state.search}
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search Site Name, Position, or Status'
                            />
                        </Col>
                        <Col xl='2' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Button
                                onClick={() => this.searchSites()}
                                className="button"
                                disabled={this.state.loading ? true : false}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col xl='4' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                onClick={() => this.newSite()}
                                className="button"
                                disabled={this.state.loading ? true : false}
                            >
                                New Site
                            </Button>
                        </Col>
                    </Row>
                    {/*{this.state.sites.length !== 0 && (*/}
                    {/*    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>*/}
                    {/*        <label style={{ marginRight: '10px' }}>Page</label>*/}
                    {/*        <select value={this.state.currentPage} onChange={this.handleChange}>*/}
                    {/*            {renderPageNumbers}*/}
                    {/*        </select>*/}
                    {/*        <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>*/}
                    {/*        <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>*/}
                    {/*            <option value="10">10</option>*/}
                    {/*            <option value="25">25</option>*/}
                    {/*            <option value="50">50</option>*/}
                    {/*            <option value="100">100</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <div style={{ maxHeight: '57vh', height: '100%', overflow:'auto' }}>
                                <Table responsive className="gridTable">
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                            </th>
                                            <th>
                                                Active
                                            </th>
                                            <th>
                                                Primary Contact
                                            </th>
                                            <th>
                                                Email Address
                                            </th>
                                            <th>
                                                Office Phone
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!this.state.noResults ?
                                            <>
                                                {renderItems}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={5} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                    No Results Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    // sites

    async searchSites() {
        this.setState({ loading: true, loadingDocument: false });
        const token = await authService.getAccessToken();
        const query = this.state.search ? this.state.search : 'allsites';
        var client = (this.state.client && this.state.client.length !== 0) ? this.state.client[0] : '';

        if (typeof client !== 'string' && !(client instanceof String)) {
            client = client.label;
        }

        const response = await fetch(`api/Sites/SearchSites?query=${query}&client=${client}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ sites: data, loading: false, noResults: data.length === 0 ? true : false });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.sites;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }
}