import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';
//import Select from 'react-select';
import { Container, Row, Col, Input, Button, Table, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { FaSort } from 'react-icons/fa'
//import { DIMENSIONS } from './util/utilities';
import './layout/site.css';
import userFunctions from './UserFunctions';
import Upload from "./upload-files.component";

export class SiteDetails extends Component {
    static displayName = SiteDetails.name;

    constructor(props) {
        super(props);

        this.state = {
            siteId: userFunctions.GuidEmpty(),
            stateList: [],
            client: null,
            site: { notes: [] },
            siteName: '',
            districtId: userFunctions.GuidEmpty(),
            address: '',
            city: '',
            stateId: userFunctions.GuidEmpty(),
            zip: '',
            officePhone: '',
            staffMemberId: userFunctions.GuidEmpty(),
            startTime: undefined,
            endTime: undefined,
            generalNotes: '',
            parkingNotes: '',
            contactsContactId: userFunctions.GuidEmpty(),
            contactName: '',
            contactTitle: '',
            contactOfficePhone: '',
            contactMobilePhone: '',
            contactEmail: '',
            siteNotesContactId: userFunctions.GuidEmpty(),
            siteNotesContactName: '',
            siteNotesNote: '',
            sites: [],
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            districts: [],
            districtDefault: {},
            users: [],
            userDefault: {},
            siteActive: true,
            contactActive: true,
            primaryContact: true,
            contactList: [],
            showToast: false,
            toastMessage: '',
            contactsDisabled: true,
            siteNotesDisabled: true,
            notesContactList: [],
            showNotesContactName: false,
            siteNotes: [],
            userId: '',
            siteNoteId: userFunctions.GuidEmpty(),
            a1: false,
            a2: false,
            a3: false,
            a4: false,
            a5: false,
            a6: false,
            a7: false,
            a8: false,
            a9: false,
            a10: false,
            a11: false,
            a12: false,
            a13: false,
            a14: false,
            a15: false,
            a16: false,
            a17: false,
            a18: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";
    }

    handleClick(event) {
        let id = Number(event.target.id);

        if (id === 0) {
            this.setState({
                currentPage: 1
            });
        } else if (id === -1) {
            this.setState({
                currentPage: Math.ceil(this.state.sites.length / this.state.itemsPerPage)
            });
        } else {
            this.setState({
                currentPage: Number(event.target.id)
            });
        }
    }

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.sites.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.sites.length / pageSize) })
        }
    }

    async componentWillMount() {
        this.query = new URLSearchParams(window.location.search);
        this.queryId = this.query.get('Id');
        if (this.queryId) {
            this.setState({ siteId: this.queryId });
            //Get the site and load it into the state!
            this.GetSiteById(this.queryId);
        }
    }

    async componentDidMount() {
        this.setWindowDimensions();
        const isAdmin = await AdminFunctions.isAdminAsync();
        const userId = await userFunctions.UserId();

        this.setState({ userId: userId });
        this.setState({ isAdmin: isAdmin ?? false });
        this.getStates();
        this.getStaffMembers();
        this.getDistricts();

        if (isAdmin) {

        }
        else {
        }

        this.query = new URLSearchParams(window.location.search);
        this.queryId = this.query.get('Id');
        if (this.queryId) {
            this.setState({ siteId: this.queryId });
            //Get the site and load it into the state!
            this.GetSiteById(this.queryId);
            this.loadNotesContacts();
            this.loadSiteNotes();
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            //this.searchSites();
        }
    }

    render() {
        // Logic for displaying sites!
        // Logic for displaying sites!
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        const currentItems = this.state.siteNotes.length > 0 ? this.state.siteNotes.slice(indexOfFirstItem, indexOfLastItem) : [];

        const renderItems = currentItems.map((note, i) => {
            return (
                <tr key={i} onClick={(e) => {
                    this.setState({
                        siteNoteId: note.id,
                        siteNotesContactId: note.contactId == null ? userFunctions.GuidEmpty() : note.contactId,
                        showNotesContactName: note.contactId == null ? true : false,
                        siteNotesContactName: note.name,
                        siteNotesNote: note.notes
                    });

                }}>
                    <td>{note.shortNote}</td>
                    <td>{note.name}</td>
                    <td>{note.noteDateTime}</td>
                    <td>{note.user}</td>
                </tr >
            )
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.sites.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option value={number}>{number}</option>
            );
        });

        return (
            <div>
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}

                <Container style={{ marginTop: '25px' }}>
                    <Row>
                        <Col>
                            <h3>Site Details</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>Site Name</label>
                                <Input
                                    type='text'
                                    onChange={(e) => {
                                        this.setState({ siteName: e.target.value, a1: false });
                                    }}
                                    name='Site Name'
                                    id='name'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={this.state.a1 ? 'Required' : 'Site Name'}
                                    value={this.state.siteName}
                                    style={this.state.a1 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>District</label>
                                <select className="form-control"
                                    onChange={(e) => this.setState({ districtId: e.target.value, a2: false })}
                                    value={this.state.districtId}
                                    style={this.state.a2 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                >
                                    <option key={0} value={0}>---Select---</option>
                                    {this.state.districts.map((item) =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>Address</label>
                                <Input
                                    type='text'
                                    onChange={(e) => { this.setState({ address: e.target.value, a3: false }) }}
                                    name='Address'
                                    id='address'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={this.state.a3 ? 'Required' : 'Address'}
                                    value={this.state.address}
                                    style={this.state.a3 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>City</label>
                                <Input
                                    type='text'
                                    onChange={(e) => { this.setState({ city: e.target.value, a4: false }); }}
                                    name='City'
                                    id='city'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={this.state.a4 ? 'Required' : 'City'}
                                    value={this.state.city}
                                    style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>State</label>
                                <select className="form-control"
                                    onChange={(e) => this.setState({ stateId: e.target.value, a5: false })}
                                    value={this.state.stateId}
                                    style={this.state.a5 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                >
                                    <option key={0} value={userFunctions.GuidEmpty()}>---Select---</option>
                                    {this.state.stateList.map((item, i) =>
                                        <option key={i + 1} value={item.id}>{item.stateName}</option>
                                    )}
                                </select>
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>Zip Code</label>
                                <Input
                                    type='text'
                                    maxLength={5}
                                    onChange={(e) => { this.setState({ zip: e.target.value, a6: false }); }}
                                    name='Zip'
                                    id='zip'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={this.state.a6 ? 'Required' : 'Zip Code'}
                                    value={this.state.zip}
                                    style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>Office Phone</label>
                                <Input
                                    type='text'
                                    maxLength={14}
                                    onChange={(e) => {
                                        var phone = this.formatPhoneNumber(e.target.value)
                                        this.setState({ officePhone: phone, a7: false });
                                    }}
                                    name='OfficePhone'
                                    id='officePhone'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={this.state.a7 ? 'Required' : 'Office Phone'}
                                    value={this.state.officePhone ?? ""}
                                    style={this.state.a7 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>Eye Thrive Staff Member</label>
                                <select className="form-control"
                                    onChange={(e) => this.setState({ staffMemberId: e.target.value, a8: false })}
                                    value={this.state.staffMemberId}
                                    style={this.state.a8 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                >
                                    <option key={0} value={0}>---Select---</option>
                                    {this.state.users.map((item) =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>School Day Start Time</label>
                                <Input
                                    type='time'
                                    onChange={(e) => { this.setState({ startTime: e.target.value, a9: false }); }}
                                    name='StartTime'
                                    id='startTime'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={this.state.a9 ? 'Required' : 'School Day Start Time'}
                                    value={this.state.startTime ?? ""}
                                    style={this.state.a9 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>School Day End Time</label>
                                <Input
                                    type='time'
                                    onChange={(e) => { this.setState({ endTime: e.target.value, a10: false }); }}
                                    name='EndTime'
                                    id='EndTime'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder={this.state.a10 ? 'Required' : 'School Day End Time'}
                                    value={this.state.endTime ?? ""}
                                    style={this.state.a10 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input'}>
                                <label className={'row-label-textarea'}>Site General Notes</label>
                                <textarea
                                    onChange={(e) => {
                                        this.setState({ generalNotes: e.target.value });
                                    }}
                                    name='SiteGeneralNotes'
                                    id='siteGeneralNotes'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder=''
                                    value={this.state.generalNotes}
                                    style={{ width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                />
                            </div>
                            <div className={'row-label-input'}>
                                <label className={'row-label-textarea'}>Parking Notes</label>
                                <textarea
                                    onChange={(e) => {
                                        this.setState({ parkingNotes: e.target.value });
                                    }}
                                    name='ParkingNotes'
                                    id='parkingNotes'
                                    onKeyPress={this.handleKeyPress}
                                    placeholder=''
                                    value={this.state.parkingNotes}
                                    style={{ width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color='primary'
                                    onClick={() => this.handleSave()}
                                    style={{ marginTop: '10px', whiteSpace: 'nowrap', }}
                                    disabled={this.state.loading ? true : false}
                                >
                                    Save Site Details
                                </Button>
                            </div>
                        </Col>
                        <Col xl='1' lg='2' md='2' sm='2' xs='2' style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <div className={'row-label-input'}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ siteActive: !this.state.siteActive }) }}
                                    id='chkActive'
                                    checked={this.state.siteActive}
                                    style={{ marginTop: '19px' }}
                                    className="form-control-checkbox"
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '18px' }}>Active</label>
                            </div>
                        </Col>
                        <Col xl='4' lg='2' md='2' sm='2' xs='2' style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <div style={this.state.contactsDisabled ? { pointerEvents: 'none', opacity: '0.7' } : {}}>

                                <Row>
                                    <Col>
                                        <label style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px', fontSize: '22px', fontWeight: '600' }}>Contacts</label>
                                    </Col>
                                </Row><div className={'row-label-input'}>
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Contact</label>
                                    <select className="form-control" value={this.state.contactsContactId} onChange={(e) => {
                                        // load the contact info
                                        var id = e.target.value;
                                        this.loadContact(id);
                                        this.setState({ contactsContactId: id });
                                    }}>
                                        <option key={0} value={0}>---Select---</option>
                                        {this.state.contactList.map((item) =>
                                            this.state.contactsContactId == item.id ? <option key={item.id} value={item.id} selected={true}>{item.name}</option> : <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Contact Name</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ contactName: e.target.value, a11: false }); }}
                                        name='Contact Name'
                                        id='name'
                                        placeholder={this.state.a11 ? 'Required' : 'Contact Name'}
                                        value={this.state.contactName}
                                        style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Title</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ contactTitle: e.target.value, a12: false }); }}
                                        placeholder={this.state.a12 ? 'Required' : 'Title'}
                                        value={this.state.contactTitle}
                                        style={this.state.a12 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Office Phone</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var phone = this.formatPhoneNumber(e.target.value)
                                            this.setState({ contactOfficePhone: phone, a13: false });
                                        }}
                                        maxLength={14}
                                        name='Office Phone'
                                        id='name'
                                        placeholder={this.state.a13 ? 'Required' : 'Office Phone'}
                                        value={this.state.contactOfficePhone}
                                        style={this.state.a13 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Mobile Phone</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var phone = this.formatPhoneNumber(e.target.value)
                                            this.setState({ contactMobilePhone: phone, a14: false });
                                        }}
                                        maxLength={14}
                                        name='Mobile Phone'
                                        id='name'
                                        onKeyPress={this.handleKeyPress}
                                        placeholder={this.state.a14 ? 'Required' : 'Mobile Phone'}
                                        value={this.state.contactMobilePhone}
                                        style={this.state.a14 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Email</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ contactEmail: e.target.value, a15: false }); }}
                                        name='Email'
                                        id='name'
                                        onKeyPress={this.handleKeyPress}
                                        placeholder={this.state.a15 ? 'Required' : 'Email'}
                                        value={this.state.contactEmail}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        color='primary'
                                        onClick={() => { this.saveContact(); }}
                                        style={{ whiteSpace: 'nowrap', marginTop: '10px', justifyContent: 'center' }}
                                        disabled={this.state.loading ? true : false}
                                    >
                                        Save Contact
                                    </Button>
                                </div>
                            </div>
                            <div style={this.state.siteNotesDisabled ? { pointerEvents: 'none', opacity: '0.7' } : {}}>
                                <Container style={{ marginTop: '35px' }}>
                                    <Row style={{ marginTop: '10px', width: '40vw' }}>
                                        <Col xl='10' lg='12' md='12' sm='12' xs='12'>
                                            <label style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px', fontSize: '22px', fontWeight: '600', marginBottom:'10px' }}>Site Contact Notes</label>
                                            <Table className="gridTable" responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Note
                                                        </th>
                                                        <th>
                                                            Contact
                                                        </th>
                                                        <th>
                                                            Day / Time
                                                        </th>
                                                        <th>
                                                            User
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.loading === true ? (
                                                        <tr>
                                                            <td className="spinner-cell">
                                                                <Spinner color="primary" children='' />
                                                            </td>
                                                        </tr>

                                                    ) : (
                                                        <>
                                                            {!this.state.noResults ? (
                                                                <>
                                                                    {renderItems}
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                                        <h5>No Results Found</h5>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>

                                            <div className={'row-label-input'}>
                                                <div className={'row-label-input'}>
                                                    <label className="row-label-0">Contact</label>
                                                    <select className="form-control" value={this.state.siteNotesContactId} onChange={(e) => {
                                                        this.setState({ showNotesContactName: e.target.value == 9999 ? true : false, siteNotesContactId: e.target.value, a16: false });
                                                    }}
                                                        style={this.state.a16 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    >
                                                        <option key={0} value={0}>---Select---</option>
                                                        {this.state.notesContactList.map((item, i) =>
                                                            <option key={i + 1} value={item.id}>{item.name}</option>
                                                        )}
                                                        <option key={9999} value={9999}>Other</option>
                                                    </select>
                                                </div>
                                                <label className={'row-label'} style={{ opacity: this.state.showNotesContactName ? 1 : 0 }}>Contact Name</label>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => { this.setState({ siteNotesContactName: e.target.value, a17: false }); }}
                                                    name='Contact Name'
                                                    id='name'
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={this.state.a17 ? 'Required' : 'Contact Name'}
                                                    value={this.state.siteNotesContactName}
                                                    style={this.state.a17 ? { opacity: this.state.showNotesContactName ? 1 : 0, border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { opacity: this.state.showNotesContactName ? 1 : 0 }}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className={'row-label-input'}>
                                                <textarea
                                                    onChange={(e) => {
                                                        this.setState({ siteNotesNote: e.target.value, a18: false });
                                                    }}
                                                    name='Note'
                                                    id='Note'
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={this.state.a18 ? 'Required' : 'Note'}
                                                    value={this.state.siteNotesNote}
                                                    style={this.state.a18 ? { width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ff0000', backgroundColor: '#ff000010', borderRadius: '5px', padding: '5px 8px' } : { width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '5px 8px' }}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    color='primary'
                                                    onClick={() => { this.saveSiteNote(); }}
                                                    style={{ whiteSpace: 'nowrap', marginTop: '10px', justifyContent: 'center' }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Save Site Note
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.sites.length !== 0 && (
                                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px', marginBottom: '50px' }}>
                                            <label style={{ marginRight: '10px' }}>Page</label>
                                            <select value={this.state.currentPage} onChange={this.handleChange}>
                                                {renderPageNumbers}
                                            </select>
                                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    )}
                                    <Row>
                                        <Col>
                                            <label style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px', fontSize: '22px', fontWeight: '600' }}>Uploads</label>
                                        </Col>
                                    </Row>
                                    <Upload SiteId={this.state.siteId} UserId={this.state.userId} />
                                    <br />
                                </Container>
                            </div>
                        </Col>
                        <Col xl='1' lg='2' md='2' sm='2' xs='2' style={{ maxHeight: '10vh', marginTop: '3.6vh' }}>
                            <div className={'row-label-input'}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ contactActive: !this.state.contactActive }) }}
                                    checked={this.state.contactActive}
                                    value={this.state.contactActive}
                                    style={{ marginTop: '16px' }}
                                    className="form-control-checkbox"
                                />
                                <label className="checkboxLabel">Active</label>
                            </div>
                            <div className={'row-label-input'} style={{ marginTop: '0.8vh' }}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ primaryContact: !this.state.primaryContact }) }}
                                    checked={this.state.primaryContact}
                                    value={this.state.primaryContact}
                                    style={{ marginTop: '16px' }}
                                    className="form-control-checkbox"
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '15px', whiteSpace: 'nowrap' }}>Primary Contact</label>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/*Site Notes*/}

            </div >
        )
    }

    // sites

    async getStates() {
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetStates', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ stateList: data });
            })
            .catch((error) => {
                alert('error in getStates - ' + error.message, 'color:#ff0000')
            });
    }

    async getStaffMembers() {
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetStaffMembers', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ users: data });
            })
            .catch((error) => {
                console.log('error in getStaffMembers - ' + error, 'color:#ff0000');
            });
    }

    // table sort
    GetIdFromQuery(location) {
        //#!?lid=2&sales=2
        var id = location.search().Id;
        if (id == null) return 0;
        else return id;
    }

    async GetSiteById(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        const query = id;

        await fetch(`api/Sites/GetSiteById?Id=${query}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({
                    siteName: data.name,
                    districtId: data.districtId,
                    address: data.address1,
                    city: data.city,
                    stateId: data.stateId,
                    zip: data.zip,
                    officePhone: data.officePhone,
                    staffMemberId: data.staffMemberId,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    generalNotes: data.siteGeneralNotes,
                    parkingNotes: data.parkingNotes,
                    loading: false,
                    noResults: data.length === 0 ? true : false,
                    contactsDisabled: false,
                    siteNotesDisabled: false,
                    siteActive: data.active
                })
                this.loadContacts();
                this.setState({ loading: false });
            })
            .catch(error => {
                console.log("error in getSiteById - " + error);
                this.setState({ loading: false });
            });;
    }

    async signout() {
        window.location.href = "/";
    }

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.sites;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }

    async handleSave() {
        if (this.state.siteName.length == 0) {
            this.setState({ a1: true });
        }
        else if (this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0) {
            this.setState({ a2: true });
        }
        //else if (this.state.address.length == 0) {
        //    this.setState({ a3: true });
        //}
        //else if (this.state.city.length == 0) {
        //    this.setState({ a4: true });
        //}
        //else if (this.state.stateId == userFunctions.GuidEmpty()) {
        //    this.setState({ a5: true });
        //}
        //else if (this.state.zip.length == 0) {
        //    this.setState({ a6: true });
        //}
        //else if (this.state.officePhone.length == 0) {
        //    this.setState({ a7: true });
        //}
        //else if (this.state.staffMemberId == userFunctions.GuidEmpty()) {
        //    this.setState({ a8: true });
        //}
        //else if (!this.state.startTime) {
        //    this.setState({ a9: true });
        //}
        //else if (!this.state.endTime) {
        //    this.setState({ a10: true });
        //}
        else {
            const token = await authService.getAccessToken();
            var dto = {
                Id: (this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == undefined) ? null : this.state.siteId,
                Name: this.state.siteName,
                Active: this.state.siteActive,
                Address1: this.state.address,
                City: this.state.city,
                StateId: this.state.stateId,
                Zip: this.state.zip,
                SiteGeneralNotes: this.state.generalNotes,
                ParkingNotes: this.state.parkingNotes,
                OfficePhone: this.state.officePhone,
                StartTime: this.state.startTime == undefined ? '' : this.state.startTime,
                EndTime: this.state.endTime == undefined ? '' : this.state.endTime,
                DistrictId: this.state.districtId,
                StaffMemberId: (this.state.staffMemberId == userFunctions.GuidEmpty() || this.state.staffMemberId == 0) ? null : this.state.staffMemberId
            };

            await this.saveSite(dto, token);
        }
    }

    async saveSite(dto, token) {
        this.setState({ loading: true });
        await fetch('api/Sites/SaveSite', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ toastMessage: 'Site saved successfully' });
                this.setState({ showToast: true });

                this.setState({ siteId: data.id });

                data.id != undefined || data.id != null || data.id != userFunctions.GuidEmpty() ? this.setState({ contactsDisabled: false, siteNotesDisabled: false }) : this.setState({ contactsDisabled: true, siteNotesDisabled: true });

                this.loadContacts();

                this.loadNotesContacts();

                this.hideToast();
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in saveSite - ' + error);
                this.setState({ loading: false });
            });
        //this.setState({ success: data });
    }

    hideToast() {
        setTimeout(() => {
            this.setState({ toastMessage: '' });
            this.setState({ showToast: false });
        }, 3500);
    }

    formatPhoneNumber(phoneNumber) {
        var result;

        result = phoneNumber.replace(/[^0-9]/g, '');
        if (result.length == 10) {
            result = result.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        else {
            result = result.replace(/\//g, "");
        }

        return result;
    }

    async saveContact() {
        if (this.state.contactName.length == 0) {
            this.setState({ a11: true });
        }
        //else if (this.state.contactTitle.length == 0) {
        //    this.setState({ a12: true });
        //}
        //else if (this.state.contactOfficePhone.length == 0) {
        //    this.setState({ a13: true });
        //}
        //else if (this.state.contactMobilePhone.length == 0) {
        //    this.setState({ a14: true });
        //}
        //else if (this.state.contactEmail.length == 0) {
        //    this.setState({ a15: true });
        //}
        else {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            var dto = {
                Id: this.state.contactsContactId == 0 ? null : this.state.contactsContactId,
                Name: this.state.contactName,
                Title: this.state.contactTitle,
                OfficePhone: this.state.contactOfficePhone,
                MobilePhone: this.state.contactMobilePhone,
                EmailAddress: this.state.contactEmail,
                Active: this.state.contactActive,
                Primary: this.state.primaryContact,
                SiteId: this.state.siteId
            }

            await fetch('api/Sites/SaveContact', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    if (response.status == 401) {
                        await this.signout();
                    }
                    else {
                        return response.json()
                    }
                })
                .then((data) => {
                    this.setState({ toastMessage: 'Contact saved successfully' });
                    this.setState({ showToast: true });

                    this.setState({ contactsContactId: data.id });
                    this.loadContacts();
                    this.loadNotesContacts();
                    this.hideToast();
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    console.log('error in saveContact - ' + error);
                    this.setState({ loading: false });
                });
        }
    }

    async loadContacts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/Sites/GetContacts?siteId=' + this.state.siteId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {

                this.setState({ contactList: data });
                var id = this.state.contactList.find(f => f.primary == true).id;
                if (id != undefined) {
                    this.setState({ contactsContactId: id });
                    this.loadContact(id);
                }
                this.setState({ loading: false });

            })
            .catch((error) => {
                console.log('error in loadContacts - ' + error);
                this.setState({ loading: false });
            });
    }

    async loadContact(id) {
        if (id == 0) {
            this.clearContact();
        }
        else {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            await fetch('api/Sites/GetContactById?id=' + id, {
                method: 'GET',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    if (response.status == 401) {
                        await this.signout();
                    }
                    else {
                        return response.json()
                    }
                })
                .then((data) => {
                    this.clearContact();

                    this.setState({
                        contactName: data.name,
                        contactTitle: data.title,
                        contactOfficePhone: data.officePhone,
                        contactMobilePhone: data.mobilePhone,
                        contactEmail: data.emailAddress,
                        contactActive: data.active,
                        primaryContact: data.primary
                    });
                    this.setState({ loading: false });

                })
                .catch((error) => {
                    console.log('error in loadContacts - ' + error);
                    this.setState({ loading: false });
                });
        }
    }

    clearContact() {
        this.setState({
            contactName: "",
            contactTitle: "",
            contactOfficePhone: "",
            contactMobilePhone: "",
            contactEmail: "",
            contactActive: false,
            primaryContact: false
        });
    }

    async loadNotesContacts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/Sites/GetNotesContacts?siteId=' + this.state.siteId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    this.setState({ notesContactList: data });
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in loadNotesContacts - ' + error);
                this.setState({ loading: false });
            });
    }

    async saveSiteNote() {
        debugger

        if (this.state.siteNotesContactId == 0) {
            this.setState({ a16: true });
        }
        else if (this.state.siteNotesContactId == 9999 && this.state.siteNotesContactName.length == 0) {
            this.setState({ a17: true });
        }
        else if (this.state.siteNotesNote.length == 0) {
            this.setState({ a18: true });
        }
        else {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            var dto = {
                Id: this.state.siteNoteId == userFunctions.GuidEmpty() ? null : this.state.siteNoteId,
                ContactId: this.state.siteNotesContactId == 9999 ? null : this.state.siteNotesContactId,
                ContactName: this.state.siteNotesContactName,
                Notes: this.state.siteNotesNote,
                SiteId: this.state.siteId,
                UserId: this.state.userId
            }

            await fetch('api/Sites/SaveSiteNote', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    if (response.status == 401) {
                        await this.signout();
                    }
                    else {
                        return response.json()
                    }
                })
                .then((data) => {
                    this.setState({ toastMessage: 'Site Note saved successfully' });
                    this.setState({ showToast: true });

                    this.setState({ siteNotes: data });
                    //this.loadContacts();
                    this.loadNotesContacts();
                    this.hideToast();
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    console.log('error in saveContact - ' + error);
                    this.setState({ loading: false });
                });
        }
    }

    async loadSiteNotes() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/Sites/LoadSiteNotes?siteId=' + this.state.siteId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ siteNotes: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in loadSiteNotes - ' + error);
                this.setState({ loading: false });
            });
    }

    async getDistricts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetDistricts', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ districts: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getDistricts - ' + error.message)
                this.setState({ loading: false });
            });
    }
}