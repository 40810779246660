import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import './layout/site.css';
import { Container, Row, Col, Input, Button, Spinner, Toast, ToastBody } from 'reactstrap';
import userFunctions from './UserFunctions';
import { IoTrashOutline } from 'react-icons/io5';
import Modal from 'react-modal';

export class Lab extends Component {
    static displayName = Lab.name;

    constructor(props) {
        super(props);

        this.state = {
            labId: userFunctions.GuidEmpty(),
            examId: userFunctions.GuidEmpty(),
            labFrameId: userFunctions.GuidEmpty(),
            examDate: '',
            site: '',
            firstName: '',
            lastName: '',
            active: false,
            TwoPairGlasses: false,
            studentId: 0,
            preferredName: '',
            dateOfBirthText: '',
            DistancePDOD: '',
            DistancePDOS: '',
            DistanceTotal: '',
            NearPDOD: '',
            NearPDOS: '',
            NearTotal: '',
            FinalSRxODSphere: userFunctions.GuidEmpty(),
            FinalSRxODCyl: userFunctions.GuidEmpty(),
            FinalSRxODAxis: '',
            FinalSRxODAdd: userFunctions.GuidEmpty(),
            FinalSRxODPrism: userFunctions.GuidEmpty(),
            FinalSRxODBase: userFunctions.GuidEmpty(),
            FinalSRxOSSphere: userFunctions.GuidEmpty(),
            FinalSRxOSCyl: userFunctions.GuidEmpty(),
            FinalSRxOSAxis: '',
            FinalSRxOSAdd: userFunctions.GuidEmpty(),
            FinalSRxOSPrism: userFunctions.GuidEmpty(),
            FinalSRxOSBase: userFunctions.GuidEmpty(),
            Manufactuter: userFunctions.GuidEmpty(),
            Color: userFunctions.GuidEmpty(),
            Model: userFunctions.GuidEmpty(),
            EyeSize: userFunctions.GuidEmpty(),
            JobLocation: '',
            SegHeight: '',
            examSphere: [],
            examCyl: [],
            examAdd: [],
            examPrism: [],
            examBase: [],
            frames: [],
            manufacturers: [],
            models: [],
            colors: [],
            eyeSizes: [],
            segHeight: '',
            jobLocations: [],
            Notes: '',
            IEP504Plan: false,
            showModal: false,
            loading: false,
            noResults: false,
            showToast: false,
            toastMessage: '',
            userId: '',
            labData: [],
            printReferralButtonDisabled: true,
            a1: false,
            a2: false,
            a3: false,
            a4: false,
            a5: false,
            showToast: false,
            toastMessage: '',
            showToastError: false,
            toastErrorMessage: '',
            isMisc: false,
            MiscEyeSize: '',
            Eye_Size: [
            {
                'id': '14',
                'size': '43',
                'value': ''
            },
            {
                'id': '1',
                'size': '44',
                'value': ''
            },
            {
                'id': '2',
                'size': '45',
                'value': ''
            },
            {
                'id': '3',
                'size': '46',
                'value': ''
            },
            {
                'id': '4',
                'size': '47',
                'value': ''
            },
            {
                'id': '5',
                'size': '48',
                'value': ''
            },
            {
                'id': '6',
                'size': '49',
                'value': ''
            },
            {
                'id': '7',
                'size': '50',
                'value': ''
            },
            {
                'id': '8',
                'size': '51',
                'value': ''
            },
            {
                'id': '9',
                'size': '52',
                'value': ''
            },
            {
                'id': '10',
                'size': '53',
                'value': ''
            },
            {
                'id': '11',
                'size': '54',
                'value': ''
            },
            {
                'id': '12',
                'size': '55',
                'value': ''
            },
            {
                'id': '13',
                'size': '56+',
                'value': ''
            }],
            Man: 'Misc',
            MiscColor: '',
            Range: ''
        };
    }

    async componentDidMount() {
        const userId = await userFunctions.UserId();
        this.setState({ userId: userId });

        this.getDropdownValues();
        this.getFrameDropdowns('manufacturer', userFunctions.GuidEmpty());

        this.query = new URLSearchParams(window.location.search);
        this.examId = this.query.get('id');
        if (this.examId) {
            this.setState({ examId: this.examId });
            // 02b5f22e-d3d8-41b6-ba18-9583e6ef63fb
            this.getLabData(this.examId, true);
        }
    }

    render() {
        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%', zIndex: '9999' }} />
                    </div>
                    : null
                }
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                {this.state.showToastError ?
                    <Toast className="toast-error">
                        <ToastBody className="toast-body">
                            {this.state.toastErrorMessage}
                        </ToastBody>
                    </Toast>
                    : null}

                <Container style={{ marginTop: '25px' }}>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col>
                            <h3>Lab</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='5' lg='6' md='6' sm='12' xs='12'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table className="preExamTable">
                                                <tbody>
                                                    <tr>
                                                        <td align="right" valign="middle">
                                                            Exam Date
                                                        </td>
                                                        <td align="left" valign="middle">
                                                            {this.state.examDate}
                                                        </td>
                                                        <td align="right" valign="middle" style={{ paddingLeft: '3vw' }}>
                                                            <Input
                                                                type='checkbox'
                                                                onChange={(e) => { this.setState({ IEP504Plan: !this.state.IEP504Plan }) }}
                                                                checked={this.state.IEP504Plan}
                                                                className="form-control-checkbox"
                                                                style={{ display: 'flex', alignItems: 'center' }}
                                                            />
                                                        </td>
                                                        <td align="left" valign="middle">
                                                            IEP / 504 Plan
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">
                                                            MVC Visit Location
                                                        </td>
                                                        <td align="left" valign="middle">
                                                            {this.state.site}
                                                        </td>
                                                        <td align="right" valign="middle" style={{ paddingLeft: '3vw' }}>
                                                            <Input
                                                                type='checkbox'
                                                                onChange={(e) => { this.setState({ Referral: !this.state.Referral }) }}
                                                                checked={this.state.Referral}
                                                                className="form-control-checkbox"
                                                                style={{ display: 'flex', alignItems: 'center', pointerEvents: 'none', cursor: 'none' }}
                                                            />
                                                        </td>
                                                        <td align="left" valign="middle">
                                                            Referral
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">
                                                            Name
                                                        </td>
                                                        <td align="left" valign="middle">
                                                            {this.state.firstName} {this.state.lastName}
                                                        </td>
                                                        <td align="right" valign="middle" style={{ paddingLeft: '3vw' }}>
                                                            <Input
                                                                type='checkbox'
                                                                onChange={(e) => { this.setState({ TwoPairGlasses: !this.state.TwoPairGlasses }) }}
                                                                checked={this.state.TwoPairGlasses}
                                                                className="form-control-checkbox"
                                                                style={{ display: 'flex', alignItems: 'center', pointerEvents: 'none', cursor: 'none' }}
                                                            />
                                                        </td>
                                                        <td align="left" valign="middle">
                                                            Two Pair Glasses
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4}></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">
                                                            Preferred Name
                                                        </td>
                                                        <td colSpan={3} align="left" valign="middle">
                                                            <Input
                                                                type='text'
                                                                onChange={(e) => {
                                                                    this.setState({ preferredName: e.target.value });
                                                                }}
                                                                placeholder='Preferred Name'
                                                                value={this.state.preferredName}
                                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="right" valign="middle">
                                                            DOB
                                                        </td>
                                                        <td colSpan={3} align="left" valign="middle">
                                                            {this.state.dateOfBirthText}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table>
                                <tbody>
                                    <tr>
                                        <td colSpan={7} style={{ fontWeight: '500' }}>
                                            Final SRx
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            OD
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxODSphere: e.target.value })}
                                                value={this.state.FinalSRxODSphere}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---sphere---</option>
                                                {this.state.examSphere.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxODCyl: e.target.value })}
                                                value={this.state.FinalSRxODCyl}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---cyl---</option>
                                                {this.state.examCyl.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                disabled="disabled"
                                                onChange={(e) => {
                                                    this.setState({ FinalSRxODAxis: e.target.value });
                                                }}
                                                placeholder='axis'
                                                maxLength={3}
                                                onBlur={() => this.setState({
                                                    FinalSRxODAxis: userFunctions.PadWithZeros(this.state.FinalSRxODAxis, 3)
                                                })}
                                                className={'form-control static-width-6vw'}
                                                value={this.state.FinalSRxODAxis}
                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                            />
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxODAdd: e.target.value })}
                                                value={this.state.FinalSRxODAdd}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---add---</option>
                                                {this.state.examAdd.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxODPrism: e.target.value })}
                                                value={this.state.FinalSRxODPrism}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---prism---</option>
                                                {this.state.examPrism.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxODBase: e.target.value })}
                                                value={this.state.FinalSRxODBase}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---base---</option>
                                                {this.state.examBase.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            OS
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxOSSphere: e.target.value })}
                                                value={this.state.FinalSRxOSSphere}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---sphere---</option>
                                                {this.state.examSphere.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxOSCyl: e.target.value })}
                                                value={this.state.FinalSRxOSCyl}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---cyl---</option>
                                                {this.state.examCyl.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <Input
                                                type='text'
                                                disabled="disabled"
                                                onChange={(e) => {
                                                    this.setState({ FinalSRxOSAxis: e.target.value });
                                                }}
                                                placeholder='axis'
                                                maxLength={3}
                                                onBlur={() => this.setState({
                                                    FinalSRxOSAxis: userFunctions.PadWithZeros(this.state.FinalSRxOSAxis, 3)
                                                })}
                                                className={'form-control static-width-6vw'}
                                                value={this.state.FinalSRxOSAxis}
                                                style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { margin: '0' }}
                                            />
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxOSAdd: e.target.value })}
                                                value={this.state.FinalSRxOSAdd}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---add---</option>
                                                {this.state.examAdd.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxOSPrism: e.target.value })}
                                                value={this.state.FinalSRxOSPrism}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---prism---</option>
                                                {this.state.examPrism.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                        <td>
                                            <select className="form-control static-width-6vw"
                                                onChange={(e) => this.setState({ FinalSRxOSBase: e.target.value })}
                                                value={this.state.FinalSRxOSBase}
                                                disabled="disabled"
                                            >
                                                <option key={0} value={0}>---base---</option>
                                                {this.state.examBase.map((item, i) =>
                                                    <option key={i + 1} value={item.id}>{item.name}</option>
                                                )}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <div style={{ marginRight: '10.5vw' }}>
                                <div className={'row-label-input'}>
                                    <label className={'valueLabel'} style={{ fontWeight: '500' }}>Distance PD</label>
                                </div>
                                <div className={'row-label-input'} style={{ display: 'flex', width: 'fit-content', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <label style={{ margin: '0 0 5px 9px' }}>OD</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ DistancePDOD: e.target.value }); }}
                                        className={'miniInput'}
                                        value={this.state.DistancePDOD}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { marginBottom: '5px' }}
                                    />
                                    <label style={{ margin: '0 0 5px 10px' }}>OS</label>
                                    <Input
                                        onBlur={() => {
                                            var total = userFunctions.CalculateTotal(this.state.DistancePDOD, this.state.DistancePDOS);
                                            if (total != 0) {
                                                this.setState({ DistanceTotal: total });
                                            }
                                        }}
                                        type='text'
                                        onChange={(e) => { this.setState({ DistancePDOS: e.target.value }); }}
                                        className={'miniInput'}
                                        value={this.state.DistancePDOS}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { marginBottom: '5px' }}
                                    />
                                </div>
                                <div className={'row-label-input'} style={{ display: 'flex', width: 'fit-content', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <label style={{ margin: '0', verticalAlign: 'middle' }}>Total</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ DistanceTotal: e.target.value }); }}
                                        className={'miniInput'}
                                        value={this.state.DistanceTotal}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />

                                </div>
                            </div>
                            <div>
                                <div className={'row-label-input'}>
                                    <label className={'valueLabel'} style={{ fontWeight: '500' }}>Near PD</label>
                                </div>
                                <div className={'row-label-input'} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <label style={{ margin: '0 0 5px 9px' }}>OD</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ NearPDOD: e.target.value }); }}
                                        className={'miniInput'}
                                        value={this.state.NearPDOD}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { marginBottom: '5px' }}
                                    />
                                    <label style={{ margin: '0 0 5px 9px' }}>OS</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ NearPDOS: e.target.value }); }}
                                        onBlur={() => {
                                            var total = userFunctions.CalculateTotal(this.state.NearPDOD, this.state.NearPDOS);
                                            if (total != 0) {
                                                this.setState({ NearTotal: total });
                                            }
                                        }}
                                        className={'miniInput'}
                                        value={this.state.NearPDOS}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { marginBottom: '5px' }}
                                    />
                                </div>
                                <div className={'row-label-input'} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <label style={{ margin: '0', verticalAlign: 'middle' }}>Total</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ NearTotal: e.target.value }); }}
                                        className={'miniInput'}
                                        value={this.state.NearTotal}
                                        style={this.state.a15 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '15px' }}>
                        <Col>
                            <textarea
                                onChange={(e) => {
                                    this.setState({ Notes: e.target.value });
                                }}
                                placeholder="Notes"
                                value={this.state.Notes}
                                style={{ width: '73%', height: '125px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col align="right" style={{ paddingRight: '27.5%' }}>
                            <Button
                                color='primary'
                                onClick={async () => await this.saveLab()}
                                style={{ marginTop: '5px', whiteSpace: 'nowrap', }}
                                disabled={this.state.loading ? true : false}
                            >
                                Save Lab
                            </Button>
                        </Col>
                    </Row>

                    {/*<Row style={{ marginTop: '15px' }}>*/}
                    {/*    <Col>*/}
                    {/*        <Button*/}
                    {/*            color='primary'*/}
                    {/*            onClick={async () => await this.addLabData()}*/}
                    {/*            style={{ whiteSpace: 'nowrap', }}*/}
                    {/*            disabled={this.state.loading ? true : false}*/}
                    {/*        >*/}
                    {/*            Save Lab*/}
                    {/*        </Button>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row style={{ marginTop: '15px' }}>
                        <Col>
                            {this.state.Man === "Misc" ?
                                <table className="layoutTable margin-bottom-20">
                                    <tbody>
                                        <tr>
                                            <td colSpan={7} style={{ fontWeight: '500' }}>
                                                Frames
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Manufacturer
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a10 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => {
                                                        var index = e.nativeEvent.target.selectedIndex;

                                                        this.setState({ Manufacturer: e.target.value, a1: false, Man: e.nativeEvent.target[index].text });
                                                        if (e.target.value != 0) {
                                                            this.getFrameDropdowns("model", e.target.value);
                                                        }
                                                    }}
                                                    value={this.state.Manufacturer}
                                                >
                                                    {this.state.manufacturers.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td align="right">
                                                Seg Height
                                            </td>
                                            <td align="left">
                                                <Input
                                                    type='text'
                                                    onChange={(e) => { this.setState({ SegHeight: e.target.value }); }}
                                                    className={'static-width-15vw'}
                                                    value={this.state.SegHeight}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Description
                                            </td>
                                            <td align="left">
                                                <Input
                                                    type='text'
                                                    onChange={(e) => { this.setState({ Description: e.target.value }); }}
                                                    className={'static-width-15vw'}
                                                    value={this.state.Description}
                                                />
                                            </td>
                                            <td align="right">
                                                Job Location
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a3 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => this.setState({ JobLocation: e.target.value, a3: false })}
                                                    value={this.state.JobLocation}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    <option key={1} value={"Off Site"}>Off Site</option>
                                                    <option key={2} value={"On Site"}>On Site</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Material
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a11 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => {
                                                        this.setState({ Model: e.target.value });
                                                    }}
                                                    value={this.state.Model}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.models.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td align="right">
                                                       
                                            </td>
                                            <td align="left">
                                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Color
                                            </td>
                                            <td align="left">
                                                <Input
                                                    type='text'
                                                    style={this.state.a12 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => { this.setState({ MiscColor: e.target.value }); }}
                                                    className={'static-width-15vw'}
                                                    value={this.state.MiscColor}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Eye Size
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a13 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => {
                                                        this.setState({ MiscEyeSize: e.target.value });
                                                        if (e.target.value != 0) {
                                                            var range = this.state.Eye_Size.find(f => f.id == e.target.value);

                                                            this.setState({ Range: range.value, EyeSizeText: range.size })
                                                        }
                                                    }}
                                                    value={this.state.MiscEyeSize}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.Eye_Size?.map((item, idx) =>
                                                        <option key={idx} value={item.id}>{item.size}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td></td>
                                            <td align="right">
                                                <Button
                                                    color='primary'
                                                    onClick={async () => await this.saveLabAddFrame()}
                                                    style={{ whiteSpace: 'nowrap', }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Add Frame
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <table className="layoutTable margin-bottom-20">
                                    <tbody>
                                        <tr>
                                            <td colSpan={7} style={{ fontWeight: '500' }}>
                                                Frames
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Manufacturer
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a1 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => {
                                                        var index = e.nativeEvent.target.selectedIndex;

                                                        this.setState({ Manufacturer: e.target.value, a1: false, Man: e.nativeEvent.target[index].text });
                                                        if (e.target.value != 0) {
                                                            this.getFrameDropdowns("model", e.target.value);
                                                        }
                                                    }}
                                                    value={this.state.Manufacturer}
                                                >
                                                    {this.state.manufacturers.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td align="right" style={{ paddingLeft: '2.4vw' }}>
                                                Seg Height
                                            </td>
                                            <td align="left">
                                                <Input
                                                    type='text'
                                                    onChange={(e) => {
                                                        this.setState({ SegHeight: e.target.value });
                                                    }}
                                                    placeholder='Seg Height'
                                                    value={this.state.SegHeight}
                                                    className="static-width-15vw"
                                                    style={{ margin: '0' }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Model
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a2 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => {
                                                        this.setState({ Model: e.target.value, a2: false });
                                                        this.getFrameDropdowns("color", e.target.value);
                                                    }}
                                                    value={this.state.Model}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.models.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td align="right">
                                                Job Location
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a14 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => this.setState({ JobLocation: e.target.value, a3: false })}
                                                    value={this.state.JobLocation}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    <option key={1} value={"Off Site"}>Off Site</option>
                                                    <option key={2} value={"On Site"}>On Site</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Color
                                            </td>
                                            <td align="left" colSpan={3}>
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a4 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => {
                                                        this.setState({ Color: e.target.value, a4: false });
                                                        this.getFrameDropdowns("eyesize", e.target.value);

                                                    }}
                                                    value={this.state.Color}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.colors.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right">
                                                Eye Size
                                            </td>
                                            <td align="left">
                                                <select className="form-control static-width-15vw"
                                                    style={this.state.a5 ? { margin: '0', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    onChange={(e) => this.setState({ EyeSize: e.target.value, a5: false })}
                                                    value={this.state.EyeSize}
                                                >
                                                    <option key={0} value={0}>---Select---</option>
                                                    {this.state.eyeSizes.map((item, i) =>
                                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td></td>
                                            <td align="right">
                                                <Button
                                                    color='primary'
                                                    onClick={async () => await this.saveLabAddFrame()}
                                                    style={{ whiteSpace: 'nowrap', }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Add Frame
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            <table className="gridTable" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                <thead>
                                    <tr>
                                        <th>
                                            Manufacturer
                                        </th>
                                        <th>
                                            Model
                                        </th>
                                        <th>
                                            Material
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            Color
                                        </th>
                                        <th>
                                            Eye Size
                                        </th>
                                        <th>
                                            Seg Height
                                        </th>
                                        <th colSpan={2}>
                                            Job Location
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.frames && this.state.frames.length > 0 ?
                                        this.state.frames.map((item, i) =>
                                            <tr>
                                                <td>
                                                    {item.manufacturer}
                                                </td>
                                                <td>
                                                    {item.manufacturer === 'Misc' ? "" : item.model}
                                                </td>
                                                <td>
                                                    {item.manufacturer === 'Misc' ? item.model : ""}
                                                </td>
                                                <td>
                                                    {item.description}
                                                </td>
                                                <td>
                                                    {item.color}
                                                </td>
                                                <td>
                                                    {item.eyeSize}
                                                </td>
                                                <td>
                                                    {item.segHeight}
                                                </td>
                                                <td>
                                                    {item.jobLocation}
                                                </td>
                                                <td className="deleteUploadCell" align="right" valign="middle">
                                                    <IoTrashOutline color="#000" size={21} style={{ cursor: 'pointer' }} onClick={() => this.deleteFramePrompt(item.id)} />
                                                </td>
                                            </tr>
                                        ) :
                                        <tr>
                                            <td colSpan={10} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                No Results Found
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <table style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={7} align="middle" valign="middle">
                                            <Button
                                                color='primary'
                                                onClick={async () => await this.printReferral()}
                                                style={{ marginTop: '20px', marginRight: '15px', whiteSpace: 'nowrap', }}
                                                disabled={this.state.loading || this.state.printReferralButtonDisabled}
                                            >
                                                Print Referral
                                            </Button>
                                            <Button
                                                color='primary'
                                                onClick={async () => await this.printRx()}
                                                style={{ marginTop: '20px', whiteSpace: 'nowrap', }}
                                                disabled={this.state.loading ? true : false}
                                            >
                                                Print Rx
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
                <div>
                    <Modal
                        isOpen={this.state.showModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showModal: false })}
                        className="et-modal"
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Delete Frame?</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
                            <button
                                color='primary'
                                style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                onClick={() => this.setState({ showModal: false })}
                            >
                                No
                            </button>
                            <button
                                color='primary'
                                style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                onClick={() => this.deleteFrame()}
                            >
                                Yes
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }

    async printReferral() {
        await this.openReferral(this.state.examId);
    }


    async saveLab() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var data = {
            ExamId: this.state.examId,
            IEP504Plan: this.state.IEP504Plan,
            PreferredName: userFunctions.ValueOrNull(this.state.preferredName),
            DistancePDOD: userFunctions.ValueOrNull(this.state.DistancePDOD, true),
            DistancePDOS: userFunctions.ValueOrNull(this.state.DistancePDOS, true),
            DistancePDTotal: userFunctions.ValueOrNull(this.state.DistanceTotal?.toString()),
            NearPDOD: userFunctions.ValueOrNull(this.state.NearPDOD, true),
            NearPDOS: userFunctions.ValueOrNull(this.state.NearPDOS, true),
            NearPDTotal: userFunctions.ValueOrNull(this.state.NearTotal?.toString()),
            Notes: userFunctions.ValueOrNull(this.state.Notes),
            TwoPairGlasses: userFunctions.ValueOrNull(this.state.TwoPairGlasses, true)
        };

        await fetch('api/Lab/SaveLabData', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ labId: data.labId });
                this.setState({ loading: false });

                this.setState({ toastMessage: 'Lab saved successfully', showToast: true });

                this.hideToast();
            })
            .catch((error) => {
                console.log('error in saveLab - ' + error);
                this.setState({ loading: false });
            });
    }

    async deleteFrame() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/Lab/DeleteFrame?labFrameId=' + this.state.labFrameId + "&adjustInventory=true", {
            method: 'POST',
            body: {},
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ loading: false, showModal: false });
                this.getLabData(this.state.examId, false);
            })
            .catch((error) => {
                console.log('error in deleteFrame - ' + error);
                this.setState({ loading: false });
            });
    }

    async deleteFramePrompt(id) {
        this.setState({ labFrameId: id, showModal: true });
    }

    async saveLabAddFrame() {
        var okToSave = true;

        if (this.state.Man !== "Misc") {
            if (this.state.Manufacturer == userFunctions.GuidEmpty() || this.state.Manufacturer == undefined) {
                this.setState({ a1: true });
                okToSave = false;
            }
            if (this.state.Model == userFunctions.GuidEmpty() || this.state.Model == undefined) {
                this.setState({ a2: true });
                okToSave = false;
            }
            if (this.state.Color == userFunctions.GuidEmpty() || this.state.Color == undefined) {
                this.setState({ a4: true });
                okToSave = false;
            }
            if (this.state.EyeSize == userFunctions.GuidEmpty() || this.state.EyeSize == undefined) {
                this.setState({ a5: true });
                okToSave = false;
            }
            if (this.state.JobLocation == undefined || this.state.JobLocation.length == 0) {
                this.setState({ a3: true });
                okToSave = false;
            }
        }
        else {
            if (this.state.Manufacturer == userFunctions.GuidEmpty() || this.state.Manufacturer == undefined) {
                this.setState({ a10: true });
                okToSave = false;
            }
            if (this.state.Model === "0" || this.state.Model == undefined) {
                this.setState({ a11: true });
                okToSave = false;
            }
            if (this.state.MiscColor === "0" || this.state.MiscColor == undefined) {
                this.setState({ a12: true });
                okToSave = false;
            }
            if (this.state.EyeSize === "0" || this.state.EyeSize == undefined) {
                this.setState({ a13: true });
                okToSave = false;
            }
            if (this.state.JobLocation == undefined || this.state.JobLocation.length == 0) {
                this.setState({ a14: true });
                okToSave = false;
            }
        }
        if (okToSave) {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            var data = {
                LabId: this.state.labId,
                ExamId: this.state.examId,
                IEP504Plan: this.state.IEP504Plan,
                PreferredName: userFunctions.ValueOrNull(this.state.preferredName, true),
                DistancePDOD: userFunctions.ValueOrNull(this.state.DistancePDOD, true),
                DistancePDOS: userFunctions.ValueOrNull(this.state.DistancePDOS, true),
                DistancePDTotal: userFunctions.ValueOrNull(this.state.DistanceTotal, true),
                NearPDOD: userFunctions.ValueOrNull(this.state.NearPDOD, true),
                NearPDOS: userFunctions.ValueOrNull(this.state.NearPDOS, true),
                NearPDTotal: userFunctions.ValueOrNull(this.state.NearTotal, true),
                Notes: userFunctions.ValueOrNull(this.state.Notes, true),
                TwoPairGlasses: userFunctions.ValueOrNull(this.state.TwoPairGlasses, true),
                LabFrames: {
                    FrameManufacturerId: this.state.Manufacturer,
                    FrameModelId: this.state.Model,
                    FrameColorId: this.state.Color,
                    FrameEyeSizeId: this.state.EyeSize,
                    SegHeight: userFunctions.ValueOrNull(this.state.SegHeight, true),
                    JobLocation: this.state.JobLocation,
                    Description: this.state.Description,
                    Color: this.state.MiscColor,
                    EyeSize: this.state.MiscEyeSize,
                    Material: this.state.Model,
                    Range: this.state.Range,
                    EyeSizeText: this.state.EyeSizeText
                }
            };

            await fetch('api/Lab/SaveLabData', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({ labId: data.labId });
                    this.setState({ loading: false });
                    if (data.success) {
                        this.getLabData(this.state.examId, false);

                        this.setState({ toastMessage: 'Lab and Frame saved successfully', showToast: true });

                        this.hideToast();
                    }
                    else {
                        this.setState({ toastErrorMessage: data.error, showToastError: true, loading: false });

                        this.hideToast();
                    }
                })
                .catch((error) => {
                    console.log('error in saveLabAddFrame - ' + error);
                    this.setState({ loading: false });
                });
        }
    }

    async getFrameDropdowns(name, id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Frames/GetFrameDropdownByNameId?name=' + name + '&id=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (name == "manufacturer") {
                    this.setState({
                        manufacturers: data.manufacturers,
                        models: [],
                        colors: [],
                        eyeSizes: []
                    }, function () {
                        this.getFrameDropdowns('model', this.state.manufacturers[0].id);
                        this.setState({ Manufacturer: this.state.manufacturers[0].id });
                    });
                }
                else if (name == "model") {
                    this.setState({
                        models: data.models,
                        colors: [],
                        eyeSizes: []
                    });
                }
                else if (name == "color") {
                    this.setState({
                        colors: data.colors,
                        eyeSizes: []
                    });
                }
                else if (name == "eyesize") {
                    this.setState({
                        eyeSizes: data.eyeSizes
                    });
                }

                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in getFrameDropdowns - ' + error.message);
                this.setState({ loading: false });
            });
    }

    async getLabData(id, initialLoad) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Lab/GetLabData?examId=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    labId: data.labId,
                    labData: data,
                    studentId: data.studentId,
                    examDate: data.examDateText,
                    site: data.site,
                    IEP504Plan: data.ieP504Plan,
                    Referral: data.referral,
                    printReferralButtonDisabled: data.referral ? false : true,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    preferredName: data.preferredName,
                    dateOfBirthText: data.dateOfBirth,
                    FinalSRxODSphere: data.finalSRxODSphere,
                    FinalSRxODCyl: data.finalSRxODCyl,
                    FinalSRxODAxis: data.finalSRxODAxis,
                    FinalSRxODAdd: data.finalSRxODAdd,
                    FinalSRxODPrism: data.finalSRxODPrism,
                    FinalSRxODBase: data.finalSRxODBase,
                    FinalSRxOSSphere: data.finalSRxOSSphere,
                    FinalSRxOSCyl: data.finalSRxOSCyl,
                    FinalSRxOSAxis: data.finalSRxOSAxis,
                    FinalSRxOSAdd: data.finalSRxOSAdd,
                    FinalSRxOSPrism: data.finalSRxOSPrism,
                    FinalSRxOSBase: data.finalSRxOSBase,
                    DistancePDOD: data.distancePDOD,
                    DistancePDOS: data.distancePDOS,
                    DistanceTotal: data.distancePDTotal,
                    NearPDOD: data.nearPDOD,
                    NearPDOS: data.nearPDOS,
                    NearTotal: data.nearPDTotal,
                    Notes: data.notes,
                    frames: data.labFramesGrid,
                    TwoPairGlasses: data.twoPairGlasses
                }, function () {
                    setTimeout(() => {
                        this.setState({ loading: false });
                    }, initialLoad ? 2000 : 100);
                });
            })
            .catch((error) => {
                console.log('error in getLabData - ' + error.message);
                this.setState({ loading: false });
            });
    }

    async getDropdownValues() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Exam/GetDropdownValues', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    examSphere: data.examSphere,
                    examCyl: data.examCyl,
                    examAdd: data.examAdd,
                    examPrism: data.examPrism,
                    examBase: data.examBase,
                    loading: false
                });
            })
            .catch((error) => {
                console.log('error in getDropdownValues - ' + error.message);
                this.setState({ loading: false });
            });
    }

    hideToast() {
        setTimeout(() => {
            this.setState({ toastMessage: '', toastErrorMessage: '' });
            this.setState({ showToast: false, showToastError: false });
        }, 5000);
    }

    async openReferral(id) {
        const token = await authService.getAccessToken();

        await fetch('api/Exam/GetReferralPdf?examId=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                var w = window.open(URL.createObjectURL(data), "_blank");
                w.window.print();
            })
    }

    async printRx() {
        const token = await authService.getAccessToken();

        await fetch('api/Lab/GenerateRxPdf?examId=' + this.state.examId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                var w = window.open(URL.createObjectURL(data), "_blank");
                w.window.print();
            })
    }
}