import React, { Component } from 'react';
import { Route } from 'react-router';
import { AppBody } from './components/AppBody';
import { Home } from './components/Home';
import { SiteDetails } from './components/SiteDetails';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Roles } from './components/admin/Roles';
import { CreateRole } from './components/admin/CreateRole';
import { AccessDenied } from './components/util/AccessDenied';
import './custom.css'
import { Users } from './components/admin/Users';
import { Clients } from './components/admin/Clients';
import { RoleDetails } from './components/admin/RoleDetails';
import { UserDetails } from './components/admin/UserDetails';
import { CreateClient } from './components/admin/CreateClient';
import { ClientDetails } from './components/admin/ClientDetails';
import { Admin } from './components/admin/Admin';
import { SiteSearch } from './components/SiteSearch';
import { StudentDetails } from './components/StudentDetails';
import { StudentSearch } from './components/StudentSearch';
import { RosterImport } from './components/rosterImport';
import { DoctorExamSelection } from './components/DoctorExamSelection';
import { Lab } from './components/Lab';
import { FrameInventory } from './components/FrameInventory';
import { Screening } from './components/screening';
import { DistrictSearch } from './components/DistrictSearch';
import { DistrictDetails } from './components/DistrictDetails';
import { LensInventory } from './components/LensInventory';
import { ExamPreTest } from './components/ExamPreTest';
import { DataSync } from './components/DataSync';
import { Reporting } from './components/Reporting';
import { ReplacementDetails } from './components/ReplacementDetails';
import { StudentForms } from './components/StudentForms';
import { ReplacementSearch } from './components/ReplacementSearch';

import "bootstrap/dist/css/bootstrap.min.css";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AppBody>
                <Route path='/counter' component={Counter} />
                <Route exact path='/' component={Home} />
                <AuthorizeRoute path='/fetch-data' component={FetchData} />
                <AuthorizeRoute exact path='/SiteDetails' component={SiteDetails} />
                <AuthorizeRoute exact path='/ExamPreTest' component={ExamPreTest} />
                <AuthorizeRoute exact path='/StudentDetails' component={StudentDetails} />
                <AuthorizeRoute exact path='/admin' component={Admin} />
                <AuthorizeRoute exact path='/admin/roles' component={Roles} />
                <AuthorizeRoute exact path='/admin/roles/create' component={CreateRole} />
                <AuthorizeRoute exact path='/admin/roles/details' component={RoleDetails} />
                <AuthorizeRoute exact path='/admin/users' component={Users} />
                <AuthorizeRoute exact path='/admin/users/details' component={UserDetails} />
                <AuthorizeRoute exact path='/admin/clients' component={Clients} />
                <AuthorizeRoute exact path='/admin/clients/create' component={CreateClient} />
                <AuthorizeRoute exact path='/admin/clients/details' component={ClientDetails} />
                <AuthorizeRoute exact path='/SiteSearch' component={SiteSearch} />
                <AuthorizeRoute exact path='/StudentSearch' component={StudentSearch} />
                <AuthorizeRoute exact path='/DoctorExamSelection' component={DoctorExamSelection} />
                <AuthorizeRoute exact path='/Lab' component={Lab} />
                <AuthorizeRoute exact path='/FrameInventory' component={FrameInventory} />
                <AuthorizeRoute exact path='/RosterImport' component={RosterImport} />
                <AuthorizeRoute exact path='/Screening' component={Screening} />
                <AuthorizeRoute exact path='/DistrictSearch' component={DistrictSearch} />
                <AuthorizeRoute exact path='/DistrictDetails' component={DistrictDetails} />
                <AuthorizeRoute exact path='/DataSync' component={DataSync} />
                <AuthorizeRoute exact path='/Reporting' component={Reporting} />
                <AuthorizeRoute exact path='/StudentForms' component={StudentForms} />
                <AuthorizeRoute path='/LensInventory' component={LensInventory} />
                <AuthorizeRoute exact path='/ReplacementDetails' component={ReplacementDetails} />
                <AuthorizeRoute exact path='/ReplacementSearch' component={ReplacementSearch} />

                <Route exact path='/accessdenied' component={AccessDenied} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </AppBody>
        );
    }
}
