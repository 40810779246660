import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Spinner, Toast, ToastBody } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';

export class DoctorExamSelection extends Component {
    static displayName = DoctorExamSelection.name;

    constructor(props) {
        super(props);

        this.state = {
            examDate: '',
            studentId: userFunctions.GuidEmpty(),
            labStudentId: userFunctions.GuidEmpty(),
            siteId: userFunctions.GuidEmpty(),
            loading: false,
            siteList: [],
            students: [],
            labStudents: [],
            a1: false,
            a2: false,
            a3: false,
            gotoExamDisabled: true,
            gotoLabDisabled: true,
            showErrorToast: false,
            toastErrorMessage: '',
            showAllLabs: false,
            isDoctor: false,
            isSupport: false,
            isExamDropdownLoading: false,
            isLabDropdownLoading: false,
        };
    }

    async componentDidMount() {
        const userId = await userFunctions.UserId();
        this.setState({ userId: userId });

        const isInRole = await userFunctions.IsInRole("Doctor");
        const isInRoleSupport = await userFunctions.IsInRole("Operational Support");

        this.setState({ isDoctor: isInRole, isSupport: isInRoleSupport });

        this.getSites();
    }

    render() {
        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                {this.state.showErrorToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-error">
                            {this.state.toastErrorMessage}
                        </ToastBody>
                    </Toast>
                    : null}

                <Container style={{ marginTop: '25px' }}>
                    <Row>
                        <Col>
                            <h3>Doctor Exam / Lab Selection</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <table cellPadding={5}>
                            <tbody>
                                <tr>
                                    <td align="right" valign="middle">
                                        <label className={'row-label'}>Exam Date</label>
                                    </td>
                                    <td align="left" valign="middle">
                                        <Input
                                            type='date'
                                            onChange={(e) => {
                                                this.setState({ examDate: e.target.value, a1: false });
                                                if (this.state.siteId && this.state.siteId.length > 0 && this.state.siteId != userFunctions.GuidEmpty()) {
                                                    this.getExamPreTestStudents(this.state.siteId, e.target.value);
                                                    this.getLabStudents(this.state.siteId, e.target.value);
                                                }
                                            }}
                                            name='First Name'
                                            value={this.state.examDate}
                                            style={this.state.a1 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                            className={'timeInput'}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td align="right" valign="middle">
                                        <label className={'row-label'}>MVC Visit Location</label>
                                    </td>
                                    <td align="left" valign="middle">
                                        <select className="form-control"
                                            onChange={(e) => {
                                                this.setState({
                                                    siteId: e.target.value
                                                });
                                                if (e.target.value === 0) {
                                                    localStorage.removeItem("SelectionMVCVisitLocation");
                                                }
                                                else {
                                                    this.getExamPreTestStudents(e.target.value, this.state.examDate);
                                                    this.getLabStudents(e.target.value, this.state.examDate);
                                                    localStorage.setItem("SelectionMVCVisitLocation", e.target.value);
                                                }
                                            }}
                                            value={this.state.siteId}
                                        >
                                            <option key={0} value={0}>---Select---</option>
                                            {this.state.siteList !== undefined && this.state.siteList.map((item, i) =>
                                                <option key={i} value={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} align="left" valign="bottom">
                                        <label className={'title'} style={{ borderBottom: '1px solid #ddd', width: '100%', fontSize: '16px', marginTop: '15px' }}>Ready For Doctor</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="right" valign="middle">
                                        Student Name
                                    </td>
                                    <td align="left" valign="middle">
                                        <div style={{ position: 'relative' }}>
                                            <select className="form-control"
                                                disabled={(!this.state.isDoctor && !this.state.isSupport) || this.state.isExamDropdownLoading}
                                                onChange={(e) => {
                                                    if (e.currentTarget.value !== userFunctions.GuidEmpty()) {
                                                        this.setState({ studentId: e.currentTarget.value });
                                                        this.setState({ gotoExamDisabled: false });
                                                    }
                                                    else {
                                                        this.setState({ gotoExamDisabled: true });
                                                    }
                                                }}
                                                value={this.state.studentId}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.students.length > 0 ? this.state.students.map((item, i) =>
                                                    <option key={i} value={item.id}>{item.firstName} {item.lastName}</option>
                                                ) : null}
                                            </select>
                                            {this.state.isExamDropdownLoading === true ?
                                                <div style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '18%', width: 'fit-content', height: 'fit-content' }}>
                                                    <Spinner color="#000" children='' style={{ width: '24px', height: '24px' }} />
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} align="right" valign="middle">
                                        <Input
                                            type='checkbox'
                                            onChange={(e) => {
                                                this.setState({
                                                    showAllExams: !this.state.showAllExams
                                                }, async function () {
                                                    await this.getExamStudents(this.state.siteId, this.state.examDate);
                                                });
                                            }}
                                            checked={this.state.showAllExams}
                                            className="form-control-checkbox"
                                        />
                                        <label style={{ whiteSpace: 'nowrap', margin: '0', marginLeft: '5px' }}>Show All Exams</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} align="right" valign="middle">
                                        <Button
                                            color='primary'
                                            onClick={async () => await this.startExam(this.state.studentId, this.state.examDate)}
                                            style={{ marginTop: '10px', whiteSpace: 'nowrap', }}
                                            disabled={this.state.loading || this.state.gotoExamDisabled || (!this.state.isDoctor && !this.state.isSupport) ? true : false}
                                        >
                                            Go To Exam
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} align="left" valign="middle">
                                        <label className={'title'} style={{ borderBottom: '1px solid #ddd', width: '100%', fontSize: '16px', marginTop: '15px' }}>Ready For Lab</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="right" valign="middle">
                                        Student Name
                                    </td>
                                    <td align="left" valign="middle">
                                        <div style={{ position: 'relative' }}>
                                            <select className="form-control"
                                                disabled={this.state.isLabDropdownLoading}
                                                onChange={(e) => {
                                                    this.setState({ labStudentId: e.target.value, a6: false });
                                                    if (e.target.value !== 0) {
                                                        this.setState({ gotoLabDisabled: false });
                                                    }
                                                }}
                                                value={this.state.labStudentId}
                                                style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { marginRight: '10px' }}
                                            >
                                                <option key={0} value={0}>---Select---</option>
                                                {this.state.labStudents.length > 0 ? this.state.labStudents.map((item, i) =>
                                                    <option key={i} value={item.id}>{item.firstName} {item.lastName}</option>
                                                ) : null}
                                            </select>
                                            {this.state.isLabDropdownLoading === true ?
                                                <div style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '18%', width: 'fit-content', height: 'fit-content' }}>
                                                    <Spinner color="#000" children='' style={{ width: '24px', height: '24px' }} />
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} align="right" valign="middle">
                                        <Input
                                            type='checkbox'
                                            onChange={(e) => {
                                                this.setState({
                                                    showAllLabs: !this.state.showAllLabs
                                                }, async function () {
                                                    await this.getLabStudents(this.state.siteId, this.state.examDate);
                                                });
                                            }}
                                            checked={this.state.showAllLabs}
                                            className="form-control-checkbox"
                                        />
                                        <label style={{ whiteSpace: 'nowrap', margin: '0', marginLeft: '5px' }}>Show All Labs</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} align="right" valign="middle">
                                        <Button
                                            color='primary'
                                            onClick={async () => await this.startLab(this.state.labStudentId, this.state.examDate)}
                                            style={{ marginTop: '10px', whiteSpace: 'nowrap', }}
                                            disabled={this.state.loading || this.state.gotoLabDisabled ? true : false}
                                        >
                                            Go To Lab
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                </Container>
                {/*Site Notes*/}
            </div >
        )
    }

    async startExam(studentId, date) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Exam/GetExamIdByStudentId?studentId=' + studentId + '&date=' + date, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then(async (data) => {
                this.setState({ loading: false });
                if (data != userFunctions.GuidEmpty()) {
                    await this.updateExamStarted(data, studentId);
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in startExam - ' + error.message);
            });
    }

    async startLab(studentId, date) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Exam/GetExamIdByStudentId?studentId=' + studentId + '&date=' + date, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then(async (data) => {
                this.setState({ loading: false });
                window.location.href = "/Lab?id=" + data;

            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in startLab - ' + error.message);
            });
    }

    async getExamPreTestStudents(siteId, date) {
        if (this.state.examDate && this.state.examDate.length === 0) {
            this.setState({ toastErrorMessage: 'Exam Date is required', showErrorToast: true });
            this.hideToast();
        }
        else if (this.state.siteId && siteId.length === 0) {
            this.setState({ toastErrorMessage: 'Site is required', showErrorToast: true });
            this.hideToast();
        }
        else {
            var empty = [];
            this.setState({ students: empty, loading: true, isExamDropdownLoading: true, studentId: userFunctions.GuidEmpty() });
            const token = await authService.getAccessToken();
            await fetch('api/Exam/GetExamPreTestStudent?siteId=' + siteId + '&date=' + date, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({ students: data });
                    this.setState({ loading: false, isExamDropdownLoading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false, isExamDropdownLoading: false });
                    console.log('error in getExamPreTestStudents - ' + error.message);
                });
        }
    }

    async getLabStudents(siteId, date) {
        if (this.state.examDate && this.state.examDate.length === 0) {
            this.setState({ toastErrorMessage: 'Exam Date is required', showErrorToast: true })
            this.hideToast();
        }
        else if (this.state.siteId && siteId.length === 0) {
            this.setState({ toastErrorMessage: 'Site is required', showErrorToast: true })
            this.hideToast();
        }
        else {
            this.setState({ labStudents: [], loading: true, isLabDropdownLoading: true, labStudentId: userFunctions.GuidEmpty() });
            const token = await authService.getAccessToken();
            await fetch('api/Exam/GetLabStudents?siteId=' + siteId + '&date=' + date + '&all=' + this.state.showAllLabs, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({ labStudents: data });
                    this.setState({ loading: false, isLabDropdownLoading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false, isLabDropdownLoading: false });
                    console.log('error in getLabStudents - ' + error.message);
                });
        }
    }

    async getExamStudents(siteId, date) {
        if (this.state.examDate && this.state.examDate.length === 0) {
            this.setState({ toastErrorMessage: 'Exam Date is required', showErrorToast: true })
            this.hideToast();
        }
        else if (this.state.siteId && siteId.length === 0) {
            this.setState({ toastErrorMessage: 'Site is required', showErrorToast: true })
            this.hideToast();
        }
        else {
            this.setState({ loading: true, isExamDropdownLoading: true, studentId: userFunctions.GuidEmpty() });
            const token = await authService.getAccessToken();
            await fetch('api/Exam/GetExamPreTestStudent?siteId=' + siteId + '&date=' + date + '&all=' + this.state.showAllExams, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({ students: data });
                    this.setState({ loading: false, isExamDropdownLoading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false, isExamDropdownLoading: false });
                    console.log('error in getExamStudents - ' + error.message);
                });
        }
    }

    async updateExamStarted(examId, studentId) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var data = {
            examId: examId
        }

        await fetch('api/Exam/UpdateExamStarted?examId=' + examId, {
            method: 'POST',
            body: {}, //JSON.stringify(data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response;
            })
            .then(async (data) => {
                this.setState({ loading: false });
                window.location.href = "/ExamPreTest?studentId=" + studentId + "&examId=" + examId;
            })
            .catch((error) => {
                console.log('error in updateExamStarted - ' + error);
                this.setState({ loading: false });
            });
    }


    async getSites() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSites', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ siteList: data, examDate: data[0].date },
                    function () {
                        var siteId = localStorage.getItem("SelectionMVCVisitLocation");
                        if (siteId !== undefined) {
                            this.setState({ siteId: siteId });
                            this.getExamPreTestStudents(siteId, this.state.examDate);
                            this.getLabStudents(siteId, this.state.examDate)
                        }
                    });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getSites - ' + error.message);
            });
    }
}