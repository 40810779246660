import React, { Component, useRef } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Table, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';
import Upload from "./upload-files-student.component";
import Autocomplete from "./Autocomplete";
import { IoTrashOutline } from 'react-icons/io5';
import { FaSort, FaRegTrashAlt } from 'react-icons/fa'
import Modal from 'react-modal';

export class ReplacementSearch extends Component {
    static displayName = ReplacementSearch.name;

    constructor(props) {
        super(props);

        this.state = {
            replacements: [],
            loading: false
        }
    }

    async componentDidMount() {
        this.getReplacements();
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchReplacements();
        }
    }

    async searchReplacements() {
        if (this.state.search && this.state.search.length > 0) {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            await fetch('api/Student/searchReplacements?s=' + this.state.search, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    this.setState({ replacements: data, loading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('error in searchReplacements - ' + error.message);
                });
        }
    }

    async getReplacements() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/Student/GetReplacements', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ replacements: data, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getReplacements - ' + error.message);
            });
    }

    render() {
        const renderItems = this.state.replacements?.map((replacement, i) => {
            return (
                <tr key={i} style={{ cursor: 'pointer' }}>
                    <td onClick={() => window.location.href = '/ReplacementDetails?Id=' + replacement.id}>{replacement.lastName}</td>
                    <td onClick={() => window.location.href = '/ReplacementDetails?Id=' + replacement.id}>{replacement.firstName}</td>
                    <td onClick={() => window.location.href = '/ReplacementDetails?Id=' + replacement.id}>{replacement.dateOfBirthFormatted}</td>
                    <td onClick={() => window.location.href = '/ReplacementDetails?Id=' + replacement.id}>{replacement.dateReceivedFormatted}</td>
                    <td onClick={() => window.location.href = '/ReplacementDetails?Id=' + replacement.id}>{replacement.dateDispensedFormatted}</td>
                    <td onClick={() => window.location.href = '/ReplacementDetails?Id=' + replacement.id}>{replacement.mailedByDateFormatted}</td>
                    <td onClick={() => window.location.href = '/ReplacementDetails?Id=' + replacement.id}>{replacement.trackingNumber}</td>
                </tr>
            )
        });
        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%', zIndex: '9999' }} />
                    </div>
                    : null
                }
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Replacements</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                value={this.state.search}
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search'
                            />
                        </Col>
                        <Col xl='2' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Button
                                color='primary'
                                onClick={() => this.searchReplacements()}
                                style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <div style={{ maxHeight: '58vh', height: '100%', maxWidth: '80vw', width: '100%', overflow: 'auto auto' }}>
                                <Table responsive className="gridTable">
                                    <thead >
                                        <tr>
                                            <th>
                                                Last Name
                                            </th>
                                            <th>
                                                First Name
                                            </th>
                                            <th>
                                                Date of Birth
                                            </th>
                                            <th>
                                                Date Order Received
                                            </th>
                                            <th>
                                                Date Frames Dispensed
                                            </th>
                                            <th>
                                                Date Mailed
                                            </th>
                                            <th>
                                                USPS Tracking #
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.replacements && this.state.replacements.length !== 0 ?
                                            <>
                                                {renderItems}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={7} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                    No replacements data found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}