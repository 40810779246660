import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import AdminFunctions from './admin/AdminFunctions';
import { Container, Row, Col, Input, Button, Table, Spinner } from 'reactstrap';
import { FaSort } from 'react-icons/fa'
import { DIMENSIONS } from './util/utilities';
import './layout/site.css';
import { BsPlusCircleFill } from 'react-icons/bs';
import userFunctions from './UserFunctions';

export class StudentSearch extends Component {
    static displayName = StudentSearch.name;

    constructor(props) {
        super(props);

        this.state = {
            siteList: [],
            districtList: [],
            gradeList: [],
            dateOfBirth: '',
            siteId: userFunctions.GuidEmpty(),
            districtId: userFunctions.GuidEmpty(),
            gradeId: userFunctions.GuidEmpty(),
            client: '',
            search: '',
            students: [],
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            currentPage: 1,
            itemsPerPage: 10,
            numberOfPages: 0,
            isDoctor: false,
            showDistrictOther: false
        };
        //this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        window.addEventListener('resize', this.setWindowDimensions);
        document.getElementsByTagName('html')[0].className = "background-ring";
    }

    //handleClick(event) {
    //    let id = Number(event.target.id);

    //    if (id === 0) {
    //        this.setState({
    //            currentPage: 1
    //        });
    //    } else if (id === -1) {
    //        this.setState({
    //            currentPage: Math.ceil(this.state.students.length / this.state.itemsPerPage)
    //        });
    //    } else {
    //        this.setState({
    //            currentPage: Number(event.target.id)
    //        });
    //    }
    //}

    handleChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    //handlePageSizeChange(event) {
    //    let pageSize = Number(event.target.value);
    //    this.setState({ itemsPerPage: pageSize });
    //    if (this.state.currentPage > Math.ceil(this.state.students.length / pageSize)) {
    //        this.setState({ currentPage: Math.ceil(this.state.students.length / pageSize) })
    //    }
    //}

    async componentDidMount() {
        this.setWindowDimensions();
        const isAdmin = await AdminFunctions.isAdminAsync();

        await this.getDistricts()
            .then(async () => {
                await this.getGrades()
                    .then(async () => {
                        var data = sessionStorage.getItem("studentSearch");
                        if (data != undefined && data != null) {
                            var dto = JSON.parse(data);
                            if (dto.DistrictId && dto.DistrictId != 0 && dto.DistrictId != userFunctions.GuidEmpty) {
                                await this.getSitesByDistrictId(dto.DistrictId);
                            }
                            this.setState({
                                districtId: dto.DistrictId,
                                districtOther: dto.DistrictOther,
                                siteId: dto.SiteId,
                                schoolOther: dto.SchoolOther,
                                gradeId: dto.GradeId,
                                dateOfBirth: dto.DateOfBirth,
                                search: dto.QueryString,
                            });
                            await this.searchStudents(dto);
                        }
                    });
            });
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchStudents();
        }
    }

    newStudent() {
        window.location.href = '/StudentDetails';
    }

    render() {
        // Logic for displaying sites!
        //const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        //const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
        //const currentItems = this.state.students.slice(indexOfFirstItem, indexOfLastItem);
        //const currentItems = this.state.students;

        const renderItems = this.state.students?.map((student, i) => {
            return (
                <tr key={i} style={{ cursor: 'pointer' }}>
                    <td>
                        <div onClick={() => { window.location.href = '/Screening?Id=' + student.id }} style={{ cursor: 'pointer', zIndex: '9999', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <BsPlusCircleFill color="#00cc00" size={22} />
                        </div>
                    </td>
                    <td>
                        <div onClick={() => { window.location.href = '/ExamPreTest?studentId=' + student.id }} style={{ cursor: 'pointer', zIndex: '9999', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <BsPlusCircleFill color="#00cc00" size={22} />
                        </div>
                    </td>
                    <td>
                        <div onClick={() => { window.location.href = '/ReplacementDetails?sid=' + student.id }} style={{ cursor: 'pointer', zIndex: '9999', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <BsPlusCircleFill color="#00cc00" size={22} />
                        </div>
                    </td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.lastName}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.firstName}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.dateOfBirth}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.site}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.schoolOther}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.district}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.districtOther}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.grade}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.teacher}</td>
                    <td onClick={() => window.location.href = '/StudentDetails?Id=' + student.id}>{student.mvcVisitLocation}</td>
                </tr>
            )
        });


        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%', zIndex: '9999' }} />
                    </div>
                    : null
                }
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Student Search</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={'row-label-input'}>
                                <label className="row-label-0">District</label>
                                <select className="form-control" value={this.state.districtId}
                                    onChange={(e) => {
                                        this.setState({ districtId: e.target.value });
                                        if (e.target.value == 0) {
                                            this.setState({ siteList: [] });
                                            this.setState({
                                                showDistrictOther: false,
                                                districtId: undefined,
                                                siteId: undefined,
                                                districtOther: undefined,
                                                schoolOther: undefined
                                            })
                                            //sessionStorage.removeItem("studentSearch");
                                        }
                                        else {
                                            this.getSitesByDistrictId(e.target.value, e.target.options[e.target.selectedIndex].text == 'Other' ? true : false);
                                        }
                                    }}
                                >
                                    <option key={0} value={0}>---Select---</option>
                                    {this.state.districtList.map((item, i) =>
                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className={'row-label-input'}>
                                <label className="row-label-0">School</label>
                                <select className="form-control" value={this.state.siteId}
                                    disabled={this.state.showDistrictOther}
                                    onChange={(e) => {
                                        this.setState({ siteId: e.target.value == 0 ? undefined : e.target.value });
                                    }}
                                >
                                    <option key={0} value={0}>---Select---</option>
                                    {this.state.siteList.map((item, i) =>
                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className={'row-label-input'}>
                                <label className="row-label-0">Grade</label>
                                <select className="form-control" value={this.state.gradeId}
                                    onChange={(e) => {
                                        this.setState({ gradeId: e.target.value == 0 ? undefined : e.target.value });
                                    }}
                                >
                                    <option key={0} value={0}>---Select---</option>
                                    {this.state.gradeList.map((item, i) =>
                                        <option key={i + 1} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className={'row-label-input'}>
                                <label className="row-label-0" style={{ width: 'fit-content' }}>Date Of Birth</label>
                                <Input
                                    type='date'
                                    onChange={(e) => this.setState({ dateOfBirth: e.target.value })}
                                    value={this.state.dateOfBirth}
                                    placeholder='Date Of Birth'
                                />
                            </div>
                        </Col>
                    </Row>
                    {this.state.showDistrictOther ?
                        <Row>
                            <Col>
                                <div style={{ display: 'flex', width: 'auto', marginTop: '7px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <label style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>District (Other)</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            this.setState({ districtOther: e.target.value });
                                        }}
                                        name='DistrictOther'
                                        maxLength={100}
                                        placeholder={'District (Other)'}
                                        value={this.state.districtOther ?? ''}
                                        style={this.state.showDistrictOther ? { display: 'inline' } : { display: 'none' }}
                                        autoFocus={this.state.showDistrictOther}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div style={{ display: 'flex', width: 'auto', marginTop: '7px', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <label style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>School (Other)</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            this.setState({ schoolOther: e.target.value });
                                        }}
                                        name='schoolOther'
                                        maxLength={100}
                                        placeholder={'School (Other)'}
                                        value={this.state.schoolOther ?? ''}
                                        style={this.state.showDistrictOther ? { display: 'inline' } : { display: 'none' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        : null}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                value={this.state.search}
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search Student First Name or Last Name'
                            />
                        </Col>
                        <Col xl='2' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Button
                                color='primary'
                                onClick={() => this.searchStudents()}
                                style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col xl='4' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                color='primary'
                                onClick={() => this.newStudent()}
                                style={{ whiteSpace: 'nowrap', padding: '4px 20px', width: 'fit-content' }}
                                disabled={this.state.loading ? true : false}
                            >
                                New Student
                            </Button>
                        </Col>
                    </Row>
                    {/*{this.state.students.length !== 0 && (*/}
                    {/*    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '20px' }}>*/}
                    {/*        <label style={{ marginRight: '10px' }}>Page</label>*/}
                    {/*        <select value={this.state.currentPage} onChange={this.handleChange}>*/}
                    {/*            {renderPageNumbers}*/}
                    {/*        </select>*/}
                    {/*        <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>*/}
                    {/*        <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>*/}
                    {/*            <option value="10">10</option>*/}
                    {/*            <option value="25">25</option>*/}
                    {/*            <option value="50">50</option>*/}
                    {/*            <option value="100">100</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <div style={{ maxHeight: '58vh', height: '100%', maxWidth: '80vw', width: '100%', overflow:'auto auto' }}>
                                <Table responsive className="gridTable">
                                    <thead >
                                        <tr>
                                            <th>
                                                New Screening
                                            </th>
                                            <th>
                                                New Exam
                                            </th>
                                            <th>
                                                New Replacement
                                            </th>
                                            <th>
                                                Last Name
                                            </th>
                                            <th>
                                                First Name
                                            </th>
                                            <th>
                                                Date Of Birth
                                            </th>
                                            <th>
                                                School
                                            </th>
                                            <th>
                                                School Other
                                            </th>
                                            <th>
                                                District
                                            </th>
                                            <th>
                                                District Other
                                            </th>
                                            <th>
                                                Grade
                                            </th>
                                            <th>
                                                Teacher
                                            </th>
                                            <th>
                                                MVC Visit Location
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!this.state.noResults ?
                                            <>
                                                {renderItems}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={13} style={{ textAlign: 'center', fontWeight: '500' }}>
                                                    No Results Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    // sites

    async searchStudents(dataObject) {
        this.setState({ students: [] });
        sessionStorage.removeItem("studentSearch");

        const token = await authService.getAccessToken();
        var dto = [];

        if (dataObject != undefined && dataObject != null) {
            dto = dataObject;
            //sessionStorage.removeItem("studentSearch");
        }
        else {
            dto = {
                DistrictId: this.state.districtId == userFunctions.GuidEmpty() || this.state.districtId == 0 ? null : this.state.districtId,
                SiteId: this.state.siteId == userFunctions.GuidEmpty() || this.state.siteId == 0 ? null : this.state.siteId,
                GradeId: this.state.gradeId == userFunctions.GuidEmpty() || this.state.gradeId == 0 ? null : this.state.gradeId,
                DateOfBirth: this.state.dateOfBirth,
                QueryString: this.state.search ?? "",
                SchoolOther: this.state.schoolOther ?? null,
                DistrictOther: this.state.districtOther ?? null
            }
        }

        this.setState({ loading: true });

        await fetch('api/Student/SearchStudents', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (data.length > 0) {
                    this.setState({
                        students: data,
                        noResults: data.length === 0 ? true : false,
                        showDistrictOther: data.length === 0 ? false : data[0].showDistrictOther
                    }, function () {
                        this.setState({ loading: false });
                        sessionStorage.setItem("studentSearch", JSON.stringify(dto));
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        noResults: data.length === 0 ? true : false
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                alert(error);
            });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.students;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }

    async getDistricts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetDistricts', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ districtList: data, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getDistricts - ' + error.message);
            });
    }

    setDistrictOther(_this, showDistrictOther) {
        if (showDistrictOther) {
            var id = "";
            _this.state.siteList.find(function (item, i) {
                if (item.name == 'Other') {
                    id = item.id;
                }
            });
            this.setState({ siteId: id, showDistrictOther: showDistrictOther });
        }
    }

    async getGrades() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetGrades', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({ gradeList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log('error in getGrades - ' + error.message);
            });
    }

    async getSitesByDistrictId(districtId, showDistrictOther) {
        var _this = this;
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSitesByDistrictId?id=' + districtId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                return response.json();
            })
            .then((data) => {
                this.setState({ siteList: data }, function () {
                    if (showDistrictOther) {
                        this.setDistrictOther(_this, showDistrictOther);
                    }
                    else {
                        _this.setState({ showDistrictOther: showDistrictOther });
                    }
                });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getSitesByDistrictId - ' + error.message, 'color:#ff0000')
            });
    }
}