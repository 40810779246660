import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import './layout/site.css';
import { Button, Spinner } from 'reactstrap';
import Moment from 'react-moment';
import userFunctions from './UserFunctions';
import Modal from 'react-modal';

export class DataSync extends Component {
    static displayName = DataSync.name;
    constructor(props) {
        super(props);

        this.state = {
            dataLastSynced: '1/1/1900',
            consentLastCleared: undefined,
            consentLastClearedBy: undefined,
            loading: false,
            disableSyncDataButton: false,
            statusMessage: 'Ready',
            internet: '',
            showModal: false
        }
    }

    async componentDidMount() {
        await this.getLastSynced();
        await this.getLastCleared();
    }

    render() {
        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%', zIndex: '9999' }} />
                    </div>
                    : null
                }
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    padding: '10px',
                    border: 'solid 1px #eee',
                    borderRadius: '8px'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <label style={{ marginBottom: '0' }}>Data Last Synced: <Moment format="M/D/YYYY h:mm A">{this.state.dataLastSynced}</Moment></label>
                        <Button
                            color='primary'
                            //'### release - change accordingly for MVC and AWS (data sync ONLY happens on the MVC server not the AWS server)
                            //onClick={async () => await this.syncDataManually()}
                            style={{ marginLeft: '10px', whiteSpace: 'nowrap', }}
                            disabled={true}
                            //disabled={this.state.loading}
                        >
                            Sync Data Now
                        </Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <label style={{ marginBottom: '0' }}>Status: {this.state.statusMessage}</label>
                    </div>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <label style={{ marginBottom: '0' }}>Photo Consent & Consent On File For All Students Last Cleared: {this.state.consentLastCleared === undefined ? '[Never Cleared]' : this.state.consentLastCleared + ' by ' + this.state.consentLastClearedBy}</label>
                        <Button
                            color='primary'
                            onClick={async () => this.handleClearConsentFlags()}
                            style={{ marginLeft: '10px', whiteSpace: 'nowrap', }}
                        //disabled={this.state.loading}
                        >
                            Clear For All Students
                        </Button>
                    </div>
                </div>

                <div className={this.state.loading ? "Overlay" : ""}>
                </div>

                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={() => this.setState({ showModal: false })}
                    className="et-modal"
                    contentLabel="Example Modal"
                    overlayClassName="Overlay"
                >
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <h3>{this.state.title}</h3>
                        <h3 style={{ color: '#ff0000', marginTop: '10px', marginBottom: '10px' }}>{this.state.showWarning ? 'Warning!' : ''}</h3>
                        <h5 style={{ marginBottom: '15px' }}>{this.state.msg1}</h5>
                        <h5>{this.state.msg2}</h5>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', background: '#ffffff00' }}>
                        {this.state.showButtons ?
                            <table style={{ marginTop: '10px', marginBottom: '10px', background: '#ffffff00' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <button
                                                color='primary'
                                                className="btn btn-primary"
                                                style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
                                                onClick={() => { this.setState({ showModal: false }) }}>
                                                No
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                color='primary'
                                                className="btn btn-primary"
                                                style={{ whiteSpace: 'nowrap' }}
                                                onClick={() => {
                                                    this.clearConsentFlags();
                                                }}>
                                                Yes
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            : null}
                    </div>
                </Modal>
            </div>
        );
    }

    handleClearConsentFlags() {
        this.setState({
            title: 'Clear `Photo Consent` & `Consent On File` Confirmation',
            showWarning: true,
            msg1: 'This action will clear the checkboxes for `Photo Consent` & `Consent On File` for all student profiles.',
            msg2: 'Do you wish to continue?',
            showButtons: true,
            showModal: true
        });
    }

    async clearConsentFlags() {
        this.setState({
            loading: true,
            title: 'Clearing Photo Consent & Consent On File for all student profiles.',
            showWarning: false,
            msg1: '',
            msg2: 'Please wait...',
            showButtons: false,
            showModal: true
        });

        const token = await authService.getAccessToken();
        const userId = await userFunctions.UserId();

        var dto = {
            UserId: userId
        };

        await fetch('api/DataSync/clearConsentFlags', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        })
            .then(async (response) => {
                return response.json()
            })
            .then((data) => {
                if (data == '1900-01-01T00:00:00') {
                    this.setState({
                        loading: false,
                        consentLastCleared: undefined,
                        showModal: false
                    });
                }
                else {
                    this.setState({
                        consentLastCleared: data.lastCleared === null ? undefined : data.lastCleared,
                        consentLastClearedBy: data.lastClearedBy === null ? undefined : data.lastClearedBy,
                        loading: false,
                        showModal: false
                    });
                }
            })
            .catch((error) => {
                console.log('error in clearConsentFlags - ' + error.message);
                this.setState({ loading: false });
            });
    }

    async syncDataManually() {
        var isOnLine = navigator.onLine;
        if (isOnLine) {
            this.setState({ statusMessage: 'Syncing data...', loading: true });
            const token = await authService.getAccessToken();
            await fetch('api/DataSync/SyncDataManually', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data == '1900-01-01T00:00:00') {
                        this.setState({
                            statusMessage: 'A data sync is already in progress. Please wait for it to complete.',
                            loading: false
                        });
                    }
                    else {
                        this.setState({
                            statusMessage: 'Data synced successfully',
                            dataLastSynced: data,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log('error in syncDataManually - ' + error.message);
                    this.setState({ loading: false });
                });
        } else {
            this.setState({ statusMessage: 'Internet Connectivity is currently unavailable', loading: false })
        }
    }

    async getLastSynced() {
        var isOnLine = navigator.onLine;
        if (isOnLine) {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();
            await fetch('api/DataSync/GetLastSynced', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data == '1900-01-01T00:00:00') {
                        this.setState({
                            loading: false,
                            dataLastSynced: 'No data sync performed.'
                        });
                    }
                    else {
                        this.setState({
                            dataLastSynced: data,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log('error in getLastSynced - ' + error.message);
                    this.setState({ loading: false });
                });
        } else {
            this.setState({ statusMessage: 'Internet Connectivity is currently unavailable', loading: false })
        }
    }

    async getLastCleared() {
        var isOnLine = navigator.onLine;
        if (isOnLine) {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();
            await fetch('api/DataSync/GetLastCleared', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            })
                .then(async (response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data == '1900-01-01T00:00:00') {
                        this.setState({
                            loading: false,
                            consentLastCleared: undefined
                        });
                    }
                    else {
                        this.setState({
                            consentLastCleared: data.lastCleared === null ? undefined : data.lastCleared,
                            consentLastClearedBy: data.lastClearedBy === null ? undefined : data.lastClearedBy,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log('error in getLastCleared - ' + error.message);
                    this.setState({ loading: false });
                });
        } else {
            this.setState({ statusMessage: 'Internet Connectivity is currently unavailable', loading: false })
        }
    }
}