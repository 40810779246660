import authService from "./api-authorization/AuthorizeService"

const LensFunctions = {
    postLensInventorySearchAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/SearchLenses', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postSaveLensAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/SaveLens', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postDeleteLensAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/DeleteLens', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    getLensDropdownsAsync: async function () {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/GetLensDropdowns', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        return response;
    },
    postDispensedLensesSearchAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/SearchDispensedLenses', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    getDispensedLensesAsync: async function () {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/GetDispensedLenses', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        return response;
    },
    postAddDispensedLensAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/AddDispensedLens', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postDeleteDispensedLensAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/DeleteDispensedLens', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postSaveDispensedLensAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Lens/SaveDispensedLens', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    }
}

export default LensFunctions;