import http from "../http-common";
import authService from '../components/api-authorization/AuthorizeService';

class UploadFilesService {
    async upload(file, districtId, siteId, onUploadProgress) {
        const token = await authService.getAccessToken();
        let formData = new FormData();
        formData.append("file", file);
        formData.append("districtId", districtId);
        formData.append("siteId", siteId);
        return http.post("api/upload/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress,
        });
    }

    async getFiles(districtId, siteId) {
        if (districtId != 0 && siteId != 0) {
            const token = await authService.getAccessToken();
            return http.get("api/upload/RosterImportData?districtId=" + districtId + "&siteId=" + siteId, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
        }
    }

    async importData(dto) {
        const token = await authService.getAccessToken();
        await fetch('api/RosterImport/ImportRoster', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                return true;
            })
            .catch((error) => {
                console.log('error in importData - ' + error);
            });
    }
}
export default new UploadFilesService();