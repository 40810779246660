import React, { Component } from 'react';
import userFunctions from './UserFunctions';
import AdminFunctions from './admin/AdminFunctions';
import LensFunctions from './LensFunctions';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaSort, FaRegTrashAlt } from 'react-icons/fa'
import { DIMENSIONS } from './util/utilities';
import './layout/site.css';
import Modal from 'react-bootstrap/Modal';
import Moment from 'react-moment';

export class LensInventory extends Component {
    static displayName = LensInventory.name;

    constructor(props) {
        super(props);

        this.state = {
            notRestocked: false,
            lenses: [],
            dispensedLenses: [],
            search: '',
            searchDispensed: '',
            isAdmin: false,
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
            dispensedSort: {
                column: null,
                direction: 'desc'
            },
            loading: false,
            noResults: false,
            noResultsDispensed: false,
            currentPage: 1,
            currentPageDispensed: 1,
            itemsPerPage: 10,
            itemsPerPageDispensed: 10,
            numberOfPages: 0,
            numberOfPagesDispensed: 0,
            showModal1: false,
            showInventory: false,
            showDeleteLens: false,
            showDeleteDispensedLens: false,
            selectedLens: { sign: {}, sphere: {}, cylinder: {} },
            deleteLens: { sign: {}, sphere: {}, cylinder: {} },
            dispensedLens: {},
            deleteDispensedLens: { sign: {}, sphere: {}, cylinder: {}, dispensedLocation: {} },
            updateValid: false,
            dropdowns: { signs: [], spheres: [], cylinders: [], dispensedLocations: [] }
        };

        this.handleClose = this.handleClose.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeDispensed = this.handlePageChangeDispensed.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handlePageSizeChangeDispensed = this.handlePageSizeChangeDispensed.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
        this.handleOpenEditModal = this.handleOpenEditModal.bind(this);
        this.handleOpenDispenseModal = this.handleOpenDispenseModal.bind(this);
        this.handleDeleteLens = this.handleDeleteLens.bind(this);
        this.handleDeleteDispensedLens = this.handleDeleteDispensedLens.bind(this);
        this.handleValidateLens = this.handleValidateLens.bind(this);

        window.addEventListener('resize', this.setWindowDimensions);
    }

    handleClose() {
        var selectedLens = { sign: {}, sphere: {}, cylinder: {} };
        var dispensedLens = { sign: {}, sphere: {}, cylinder: {} };
        var deleteLens = { sign: {}, sphere: {}, cylinder: {} };
        var deleteDispensedLens = { sign: {}, sphere: {}, cylinder: {}, dispensedLocation: {} };

        this.setState({
            showModal1: false,
            showModal2: false,
            showInventory: false,
            showDeleteLens: false,
            showDeleteDispensedLens: false,
            selectedLens: selectedLens,
            dispensedLens: dispensedLens,
            deleteLens: deleteLens,
            deleteDispensedLens: deleteDispensedLens,
        });
    }

    handlePageChange(event) {
        this.setState({ currentPage: Number(event.target.value) });
    }

    handlePageChangeDispensed(event) {
        this.setState({ currentPageDispensed: Number(event.target.value) });
    }

    handlePageSizeChange(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPage: pageSize });
        if (this.state.currentPage > Math.ceil(this.state.lenses.length / pageSize)) {
            this.setState({ currentPage: Math.ceil(this.state.lenses.length / pageSize) })
        }
    }

    handlePageSizeChangeDispensed(event) {
        let pageSize = Number(event.target.value);
        this.setState({ itemsPerPageDispensed: pageSize });
        if (this.state.currentPageDispensed > Math.ceil(this.state.dispensedLenses.length / pageSize)) {
            this.setState({ currentPageDispensed: Math.ceil(this.state.dispensedLenses.length / pageSize) })
        }
    }

    handleDropdownChange(lensType, dropdown, value) {
        var filtered = [];
        var propName = '';

        if (dropdown == 'signId') {
            filtered = this.state.dropdowns.signs.filter(item => {
                return item.id == value;
            });
            propName = 'sign';
        } else if (dropdown == 'sphereId') {
            filtered = this.state.dropdowns.spheres.filter(item => {
                return item.id == value;
            });
            propName = 'sphere';
        } else if (dropdown == 'cylinderId') {
            filtered = this.state.dropdowns.cylinders.filter(item => {
                return item.id == value;
            });
            propName = 'cylinder';
        } else if (dropdown == 'dispensedLocationId') {
            filtered = this.state.dropdowns.dispensedLocations.filter(item => {
                return item.id == value;
            });
            propName = 'dispensedLocation';
        }

        var lens = lensType === 'lens' ? this.state.selectedLens : this.state.dispensedLens;

        lens[dropdown] = value;

        if (filtered.length != 0)
            lens[propName] = filtered[0];

        this.handleValidateLens(lens);

        if (lensType === 'lens') {

            this.setState({ selectedLens: lens });
        } else {
            this.setState({ dispensedLens: lens });
        }
    }

    handleOpenAddModal() {
        var selectedLens = {
            id: '',
            signId: '',
            sign: {},
            sphereId: '',
            sphere: {},
            cylinderId: '',
            cylinder: {},
            barcode: ''
        };

        this.handleValidateLens(selectedLens);

        this.setState({ selectedLens: selectedLens, showModal1: true });
    }

    handleOpenEditModal(id) {
        var lens = this.state.lenses.filter(function (lens) {
            return lens.id == id;
        });

        if (lens.length != 0) {
            var selected = lens[0];
            var selectedLens = {
                id: selected.id,
                signId: selected.sign == null ? null : selected.sign.id,
                sign: selected.sign,
                sphereId: selected.sphere == null ? null : selected.sphere.id,
                sphere: selected.sphere,
                cylinderId: selected.cylinder == null ? null : selected.cylinder.id,
                cylinder: selected.cylinder,
                barcode: selected.barcode
            };

            this.handleValidateLens(selectedLens);
            this.setState({ selectedLens: selectedLens, showModal1: true });
        }
    }

    handleOpenDispenseModal() {
        var dispensedLens = {
            signId: 'new',
            sign: {},
            sphereId: 'new',
            sphere: {},
            cylinderId: 'new',
            cylinder: {},
            power: 'new',
            barcode: '',
            dispensedLocationId: '',
            dispensedLocation: {}
        }

        this.setState({ dispensedLens: dispensedLens, showModal2: true });
    }

    handleDeleteLens(id) {
        var lens = this.state.lenses.filter(lens => {
            return lens.id == id;
        });

        if (lens.length != 0) {
            var selected = lens[0];
            var selectedLens = {
                id: selected.id,
                signId: selected.sign == null ? null : selected.sign.id,
                sign: selected.sign,
                sphereId: selected.sphere == null ? null : selected.sphere.id,
                sphere: selected.sphere,
                cylinderId: selected.cylinder == null ? null : selected.cylinder.id,
                cylinder: selected.cylinder,
                barcode: selected.barcode
            };

            this.setState({ deleteLens: selected, selectedLens: selectedLens, showDeleteLens: true });
        }
    }

    handleDeleteDispensedLens(id) {
        var lens = this.state.dispensedLenses.filter(lens => {
            return lens.id == id;
        });

        if (lens.length != 0) {
            var selected = lens[0];
            var selectedLens = {
                id: selected.id,
                signId: selected.sign == null ? null : selected.sign.id,
                sign: selected.sign,
                sphereId: selected.sphere == null ? null : selected.sphere.id,
                sphere: selected.sphere,
                cylinderId: selected.cylinder == null ? null : selected.cylinder.id,
                cylinder: selected.cylinder,
                barcode: selected.barcode
            };

            var dispensedLens = {
                id: selected.id,
                signId: selected.sign == null ? null : selected.sign.id,
                sign: selected.sign,
                sphereId: selected.sphere == null ? null : selected.sphere.id,
                sphere: selected.sphere,
                cylinderId: selected.cylinder == null ? null : selected.cylinder.id,
                cylinder: selected.cylinder,
                dispensedLocationId: selected.dispensedLocation == null ? null : selected.dispensedLocation.id,
                dispensedLocation: selected.dispensedLocation,
                barcode: selected.barcode
            };

            this.setState({ deleteDispensedLens: selected, selectedLens: selectedLens, dispensedLens: dispensedLens, showDeleteDispensedLens: true });
        }
    }

    handleValidateLens(lens) {
        if (lens.signId === '' || lens.sphereId === '' || lens.cylinderId === '') {
            this.setState({ updateValid: false });
        } else {
            if (lens.dispensedLocationId != undefined && (lens.dispensedLocationId === null || lens.dispensedLocationId === '' || lens.barcode === '')) {
                this.setState({ updateValid: false });
            } else {
                this.setState({ updateValid: true });
            }
        }
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.getLenses();
        }
    }

    async getLenses() {
        //debugger
        this.setState({ loading: true });
        this.setState({ lenses: [] });

        var dto = {
            QueryString: this.state.search.length == 0 ? "" : this.state.search,
            NotRestocked: this.state.notRestocked
        }

        const response = await LensFunctions.postLensInventorySearchAsync(dto);

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            this.setState({ lenses: data, loading: false, noResults: data.length === 0 ? true : false });
        } else {
            this.setState({ loading: false });
        }
    }

    async getDispensedLenses() {
        //debugger
        this.setState({ loading: true });
        this.setState({ dispensedLenses: [] });

        var dto = {
            QueryString: this.state.searchDispensed.length == 0 ? "" : this.state.searchDispensed,
            NotRestocked: this.state.notRestocked
        }

        const response = await LensFunctions.postDispensedLensesSearchAsync(dto);

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            this.setState({ dispensedLenses: data, loading: false, noResultsDispensed: data.length === 0 ? true : false });
        } else {
            this.setState({ loading: false });
        }
    }

    async getDropdowns() {
        this.setState({ loading: true });
        const response = await LensFunctions.getLensDropdownsAsync();

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            this.setState({ dropdowns: data, loading: false });
        } else {
            this.setState({ loading: false });
        }
    }

    async saveLens() {
        if (this.state.updateValid) {
            this.setState({ loading: true });

            var dto = {
                Lens: this.state.selectedLens,
                QueryString: this.state.search,
                NotRestocked: this.state.notRestocked,
            }

            this.handleClose();

            var response = await LensFunctions.postSaveLensAsync(dto);

            if (response.status == 401) {
                await userFunctions.signout();
            } else if (response.status == 200) {

                var data = await response.json();
                this.setState({ lenses: data, loading: false, noResults: data.length === 0 ? true : false });
            } else {
                this.setState({ loading: false });
            }
        }
    }

    async saveDispensedLens() {
        if (this.state.updateValid) {
            this.setState({ loading: true });
            var dispensedLens = this.state.dispensedLens;

            this.handleClose();

            var response = await LensFunctions.postAddDispensedLensAsync(dispensedLens);

            if (response.status == 401) {
                await userFunctions.signout();
            } else if (response.status == 200) {
                var data = await response.json();
                this.setState({ dispensedLenses: data, loading: false, noResultsDispensed: data.length === 0 ? true : false });
                this.getLenses();
            } else {
                this.setState({ loading: false });
            }
        }
    }

    async deleteLens() {
        this.setState({ loading: true });

        var dto = {
            Lens: this.state.selectedLens,
            QueryString: this.state.search,
            NotRestocked: this.state.notRestocked,
        }

        this.handleClose();

        var response = await LensFunctions.postDeleteLensAsync(dto);

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {

            var data = await response.json();
            this.setState({ lenses: data, loading: false, noResults: data.length === 0 ? true : false });
        } else {
            this.setState({ loading: false });
        }
    }

    async deleteDispensedLens() {
        this.setState({ loading: true });
        var dispensedLens = this.state.dispensedLens;

        this.handleClose();

        var response = await LensFunctions.postDeleteDispensedLensAsync(dispensedLens);

        if (response.status == 401) {
            await userFunctions.signout();
        } else if (response.status == 200) {
            var data = await response.json();
            this.setState({ dispensedLenses: data, loading: false, noResultsDispensed: data.length === 0 ? true : false });
            this.getLenses();
        } else {
            this.setState({ loading: false });
        }
    }

    async updateDispensedLens(id) {
        this.setState({ loading: true });

        this.handleClose();

        var dispensed = this.state.dispensedLenses.filter((lens) => {
            return lens.id === id;
        });

        if (dispensed.length != 0) {
            var dispensedLens = dispensed[0];

            dispensedLens.restockToMVC = !dispensedLens.restockToMVC;
            var response = await LensFunctions.postSaveDispensedLensAsync(dispensedLens);

            if (response.status == 401) {
                await userFunctions.signout();
            } else if (response.status == 200) {
                var data = await response.json();
                this.setState({ dispensedLenses: data, loading: false, noResultsDispensed: data.length === 0 ? true : false });
                this.getLenses();
            } else {
                this.setState({ loading: false });
            }
        } else {
            this.setState({ loading: false });
        }
    }

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const lenses = this.state.lenses;

        lenses.sort((a, b) => {
            if (column == 'power') {
                var signA = a.sign != null ? a.sign.name : '';
                var sphereA = a.sphere != null ? a.sphere.name : '';
                var cylA = a.cylinder != null ? a.cylinder.name : '';

                var signB = b.sign != null ? b.sign.name : '';
                var sphereB = b.sphere != null ? b.sphere.name : '';
                var cylB = b.cylinder != null ? b.cylinder.name : '';

                var powerA = signA + sphereA + ' ' + cylA;
                var powerB = signB + sphereB + ' ' + cylB;

                return powerA.localeCompare(powerB);
            } else {
                if (typeof a[column] === 'object' && a[column] !== null) {
                    return a[column].name.localeCompare(b[column].name);
                } else if (typeof a[column] === 'number') {
                    return a[column] - b[column];
                } else {
                    return (a[column] instanceof Date ? (b[column] - a[column]) : a[column].localeCompare(b[column]));
                }
            }
        });

        if (direction === 'desc') {
            lenses.reverse();
        }

        this.setState({
            lenses: lenses,
            sort: {
                column,
                direction
            }
        })
    }

    handleDispensedSort(column) {
        const direction = this.state.dispensedSort.column ? (this.state.dispensedSort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const lenses = this.state.dispensedLenses;

        lenses.sort((a, b) => {
            if (column == 'power') {
                var signA = a.sign != null ? a.sign.name : '';
                var sphereA = a.sphere != null ? a.sphere.name : '';
                var cylA = a.cylinder != null ? a.cylinder.name : '';

                var signB = b.sign != null ? b.sign.name : '';
                var sphereB = b.sphere != null ? b.sphere.name : '';
                var cylB = b.cylinder != null ? b.cylinder.name : '';

                var powerA = signA + sphereA + ' ' + cylA;
                var powerB = signB + sphereB + ' ' + cylB;

                return powerA.localeCompare(powerB);
            } else {
                if (typeof a[column] === 'object' && a[column] !== null) {
                    return a[column].name.localeCompare(b[column].name);
                } else if (typeof a[column] === 'number') {
                    return a[column] - b[column];
                } else {
                    return (a[column] instanceof Date ? (b[column] - a[column]) : a[column].localeCompare(b[column]));
                }
            }
        });

        if (direction === 'desc') {
            lenses.reverse();
        }

        this.setState({
            dispensedLenses: lenses,
            dispensedSort: {
                column,
                direction
            }
        })
    }

    async componentDidMount() {
        this.setWindowDimensions();

        this.getLenses();
        this.getDropdowns();
        this.getDispensedLenses();
    }

    render() {
        // Logic for displaying sites!
        const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;

        const indexOfLastDispensed = this.state.currentPageDispensed * this.state.itemsPerPageDispensed;
        const indexOfFirstDispensed = indexOfLastDispensed - this.state.itemsPerPageDispensed;

        const currentLenses = this.state.lenses.slice(indexOfFirstItem, indexOfLastItem);
        const currentDispensed = this.state.dispensedLenses.slice(indexOfFirstDispensed, indexOfLastDispensed);

        const renderItems = currentLenses.map((lens, i) => {
            return (
                <tr key={lens.id} style={{ cursor: 'pointer' }}>
                    <td><a href="#" onClick={(e) => {
                        this.handleOpenEditModal(lens.id);
                        e.preventDefault();
                    }}>Edit</a></td>
                    <td>
                        <FaRegTrashAlt
                            style={{ margin: 'auto', width: '100%' }}
                            color={'black'}
                            onClick={() => this.handleDeleteLens(lens.id)}
                        />
                    </td>
                    <td>{lens.sign != null ? lens.sign.name : ''}</td>
                    <td>{lens.sphere != null ? lens.sphere.name : ''}</td>
                    <td>{lens.cylinder != null ? lens.cylinder.name : ''}</td>
                    <td>{lens.sign != null ? lens.sign.name : ''}{lens.sphere != null ? lens.sphere.name : ''} {lens.cylinder != null ? lens.cylinder.name : ''}</td>
                    <td>{lens.barcode}</td>
                </tr>
            )
        });

        const renderDispensedItems = currentDispensed.map((lens, i) => {
            return (
                <tr key={lens.id} style={{ cursor: 'pointer' }}>
                    <td>
                        <FaRegTrashAlt
                            style={{ margin: 'auto', width: '100%' }}
                            color={'black'}
                            onClick={() => this.handleDeleteDispensedLens(lens.id)}
                        />
                    </td>
                    <td>{lens.dateDispensed != null && <Moment format="M/D/YY">{lens.dateDispensed}</Moment>}</td>
                    <td>{lens.dispensedLocation != null ? lens.dispensedLocation.name : ''}</td>
                    <td>{lens.sign != null ? lens.sign.name : ''}</td>
                    <td>{lens.sphere != null ? lens.sphere.name : ''}</td>
                    <td>{lens.cylinder != null ? lens.cylinder.name : ''}</td>
                    <td>{lens.sign != null ? lens.sign.name : ''}{lens.sphere != null ? lens.sphere.name : ''} {lens.cylinder != null ? lens.cylinder.name : ''}</td>
                    <td>{lens.barcode}</td>
                    <td>
                        <Input type='checkbox'
                            onClick={(e) => this.updateDispensedLens(lens.id)}
                            checked={lens.restockToMVC}
                            style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} />
                    </td>
                </tr>
            )
        });


        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.lenses.length / this.state.itemsPerPage); i++) {
            pageNumbers.push(i);
        }

        const pageNumbersDispensed = [];
        for (let i = 1; i <= Math.ceil(this.state.dispensedLenses.length / this.state.itemsPerPageDispensed); i++) {
            pageNumbersDispensed.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <option key={number} value={number}>{number}</option>
            );
        });

        const renderPageNumbersDispensed = pageNumbersDispensed.map(number => {
            return (
                <option key={number} value={number}>{number}</option>
            );
        });

        const renderSigns = this.state.dropdowns.signs.map(sign => {
            return (
                <option key={sign.id} value={sign.id}>{sign.name}</option>
            );
        });

        const renderSpheres = this.state.dropdowns.spheres.map(sphere => {
            return (
                <option key={sphere.id} value={sphere.id}>{sphere.name}</option>
            );
        });

        const renderCylinders = this.state.dropdowns.cylinders.map(cylinder => {
            return (
                <option key={cylinder.id} value={cylinder.id}>{cylinder.name}</option>
            );
        });

        const renderLocations = this.state.dropdowns.dispensedLocations.map(location => {
            return (
                <option key={location.id} value={location.id}>{location.name}</option>
            );
        });

        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>Lens Inventory</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                name='search'
                                id='search'
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search'
                            />
                        </Col>
                        <Col>
                            <Button
                                color='primary'
                                onClick={() => this.getLenses()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col>
                    {this.state.lenses.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '5px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPage} onChange={this.handlePageChange}>
                                {renderPageNumbers}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive className="gridTable">
                                <thead>
                                    <tr>
                                        <th>
                                            Edit
                                        </th>
                                        <th>
                                            Delete
                                        </th>
                                        <th>
                                            Sign
                                            <FaSort
                                                color={this.state.sort.column === 'sign' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('sign')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Sphere
                                            <FaSort
                                                color={this.state.sort.column === 'sphere' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('sphere')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Cyl
                                            <FaSort
                                                color={this.state.sort.column === 'cylinder' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('cylinder')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Power
                                            <FaSort
                                                color={this.state.sort.column === 'power' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('power')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Barcode
                                            <FaSort
                                                color={this.state.sort.column === 'barcode' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('barcode')}
                                                className='sort-button'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true && this.state.loadingDocument === false ? (
                                        <tr>
                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                <Spinner color="primary" children='' />
                                            </td>
                                        </tr>

                                    ) : (
                                        <>
                                            {!this.state.noResults ? (
                                                <>
                                                    {renderItems}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td className={'absolute-center'}>
                                                        <h5>No Results Found</h5>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Button
                                color='primary'
                                onClick={() => this.handleOpenAddModal()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Add New Lens
                            </Button>
                        </Col>
                        <Col>
                            {this.state.lenses.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPage} onChange={this.handlePageChange}>
                                        {renderPageNumbers}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerPage} onChange={this.handlePageSizeChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <h3>Dispensed Lenses</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ searchDispensed: e.target.value })}
                                name='search'
                                id='search'
                                onKeyPress={this.handleKeyPressDispensed}
                                placeholder='Search'
                            />
                        </Col>
                        <Col>
                            <Button
                                color='primary'
                                onClick={() => this.getDispensedLenses()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '10px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '10px', height: '38px' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Search
                            </Button>
                            <label style={{fontSize: '0.6rem'}}><Input
                                type='checkbox'
                                onChange={(e) => { this.setState({ outOfStock: !this.state.outOfStock }) }}
                                id='chkActive'
                            /> Only Show Lenses NOT Restocked on MVC</label>
                        </Col>
                        <Col>

                    {this.state.dispensedLenses.length !== 0 && (
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center', marginTop: '5px' }}>
                            <label style={{ marginRight: '10px' }}>Page</label>
                            <select value={this.state.currentPageDispensed} onChange={this.handlePageChangeDispensed}>
                                {renderPageNumbersDispensed}
                            </select>
                            <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                            <select value={this.state.itemsPerPageDispensed} onChange={this.handlePageSizeChangeDispensed}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <Table responsive className="gridTable">
                                <thead>
                                    <tr>
                                        <th>
                                            Delete
                                        </th>
                                        <th>
                                            Date Dispensed
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'dateDispensed' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('dateDispensed')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Location
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'dispensedLocation' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('dispensedLocation')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Sign
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'sign' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('sign')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Sphere
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'sphere' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('sphere')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Cyl
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'cyl' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('cyl')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Power
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'power' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('power')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Barcode
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'barcode' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('barcode')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Restock to MVC
                                            <FaSort
                                                color={this.state.dispensedSort.column === 'restockToMVC' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleDispensedSort('restockToMVC')}
                                                className='sort-button'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading === true && this.state.loadingDocument === false ? (
                                        <tr>
                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '50vh', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                <Spinner color="primary" children='' />
                                            </td>
                                        </tr>

                                    ) : (
                                        <>
                                            {!this.state.noResultsDispensed ? (
                                                <>
                                                    {renderDispensedItems}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td className={'absolute-center'}>
                                                        <h5>No Results Found</h5>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Button
                                color='primary'
                                onClick={() => this.handleOpenDispenseModal()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={this.state.loading ? true : false}
                            >
                                Dispense Lens
                            </Button>
                        </Col>
                        <Col>
                            {this.state.dispensedLenses.length !== 0 && (
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', textAlign: 'center' }}>
                                    <label style={{ marginRight: '10px' }}>Page</label>
                                    <select value={this.state.currentPageDispensed} onChange={this.handlePageChangeDispensed}>
                                        {renderPageNumbersDispensed}
                                    </select>
                                    <label style={{ marginRight: '10px', marginLeft: '10px' }}>Page Size</label>
                                    <select value={this.state.itemsPerDispensedPage} onChange={this.handlePageSizeChangeDispensed}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
                <div>
                    <Modal show={this.state.showModal1} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header >
                            <Modal.Title>Edit Lens Inventory</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Sign</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Sphere</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control"
                                        value={this.state.selectedLens.signId}
                                        onChange={(e) => {
                                            this.handleDropdownChange('lens', 'signId', e.target.value);
                                        }}>
                                        <option value="">Select Sign...</option>
                                        {renderSigns}
                                    </select>
                                </Col>
                                <Col>
                                    <select className="form-control"
                                        value={this.state.selectedLens.sphereId}
                                        onChange={(e) => {
                                            this.handleDropdownChange('lens', 'sphereId', e.target.value);
                                        }}>
                                        <option value="">Select Sphere...</option>
                                        {renderSpheres}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Cyl</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Power</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control"
                                        value={this.state.selectedLens.cylinderId}
                                        onChange={(e) => {
                                            this.handleDropdownChange('lens', 'cylinderId', e.target.value);
                                        }}>
                                        <option value="">Select Cylinder...</option>
                                        {renderCylinders}
                                    </select>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedLens.sign.name}{this.state.selectedLens.sphere.name} {this.state.selectedLens.cylinder.name}</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Barcode</label>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var selected = this.state.selectedLens;
                                            selected.barcode = e.target.value;
                                            this.setState({ selectedLens: selected });
                                        }}
                                        placeholder={'Barcode'}
                                        value={this.state.selectedLens.barcode}
                                        style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </Col>
                                <Col></Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Close
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.saveLens()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={!this.state.updateValid}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showModal2} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header>
                            <Modal.Title>Dispense Lens</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Barcode</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var selected = this.state.dispensedLens;
                                            selected.barcode = e.target.value;
                                            this.handleValidateLens(selected);
                                            this.setState({ dispensedLens: selected });
                                        }}
                                        placeholder={'Barcode'}
                                        value={this.state.dispensedLens.barcode}
                                        style={this.state.a11 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Dispensed Location</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control" onChange={(e) => {
                                        this.handleDropdownChange('dispense', 'dispensedLocationId', e.target.value);
                                    }}>
                                        <option value="">Select Dispensed Location...</option>
                                        {renderLocations}
                                    </select>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Close
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.saveDispensedLens()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                                disabled={!this.state.updateValid}
                            >
                                Add
                            </Button>

                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showDeleteLens} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header >
                            <Modal.Title>This will delete the lens below.  Do you want to continue?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Sign</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Sphere</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.selectedLens.signId}>
                                        <option value="">Select Sign...</option>
                                        {renderSigns}
                                    </select>
                                </Col>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.selectedLens.sphereId}>
                                        <option value="">Select Sphere...</option>
                                        {renderSpheres}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Cyl</label>
                                </Col>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Power</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.selectedLens.cylinderId}>
                                        <option value="">Select Cylinder...</option>
                                        {renderCylinders}
                                    </select>
                                </Col>
                                <Col>
                                    <label style={{ marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedLens.sign != null ? this.state.selectedLens.sign.name : ''}{this.state.selectedLens.sphere != null ? this.state.selectedLens.sphere.name : ''} {this.state.selectedLens.cylinder != null ? this.state.selectedLens.cylinder.name : ''}</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Barcode</label>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <label style={{ marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.selectedLens.barcode}</label>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.deleteLens()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                            >
                                Delete Lens
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showDeleteDispensedLens} onHide={this.handleClose} dialogClassName="inventory-modal">
                        <Modal.Header >
                            <Modal.Title>This will delete the dispensed lens below.  Do you want to continue?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Barcode</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <label style={{ marginLeft: '0.5rem', marginBottom: '0.0rem' }}>{this.state.dispensedLens.barcode}</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col>
                                    <label style={{ fontSize: '0.8rem', marginLeft: '0.5rem', marginBottom: '0.0rem' }}>Dispensed Location</label>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '5px' }}>
                                <Col>
                                    <select className="form-control" disabled={true} value={this.state.dispensedLens.dispensedLocationId}>
                                        <option value="">Select Location...</option>
                                        {renderLocations}
                                    </select>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color='primary' onClick={this.handleClose}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                onClick={() => this.deleteDispensedLens()}
                                style={this.state.width < DIMENSIONS.LG ? { flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' } : { flex: '1', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                            >
                                Delete Dispensed Lens
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        )
    }
}
