import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Table, Spinner } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';
import { FaSort } from 'react-icons/fa'

export class DistrictSearch extends Component {
    static displayName = DistrictSearch.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            search: '',
            districts: [],
            noResults: false,
            loadingDocument: false,
            userId: '',
            sort: {
                column: null,
                direction: 'desc'
            },
        }
    }

    async componentDidMount() {
        var userId = await userFunctions.UserId();
        if (userId) {
            this.setState({ userId: userId });
        }
        this.searchDistrict();
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchDistrict();
        }
    }

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.sites;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if (direction === 'desc') {
            data.reverse();
        }
        this.setState({
            sites: data,
            sort: {
                column,
                direction
            }
        })
    }

    render() {
        const renderItems = this.state.districts.map((district, i) => {
            return (
                <tr key={i} style={{ cursor: 'pointer' }} onClick={() => window.location.href = "/DistrictDetails?id=" + district.id}>
                    <td>{district.name}</td>
                    <td>{district.active ? <input type="checkbox" disabled checked /> : <input type="checkbox" disabled />} </td>
                    <td>{district.contactName}</td>
                    <td>{district.phone}</td>
                </tr>
            )
        });

        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>District Search</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='6' lg='6' md='6' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                value={this.state.search}
                                onKeyPress={this.handleKeyPress}
                                placeholder='Search District Name'
                            />
                        </Col>
                        <Col xl='2' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Button
                                onClick={async () => { await this.searchDistrict(); }}
                                className="button"
                                disabled={this.state.loading ? true : false}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col xl='4' lg='2' md='2' sm='2' xs='2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button
                                onClick={() => window.location.href = "/DistrictDetails"}
                                className="button"
                                disabled={this.state.loading ? true : false}
                            >
                                New District
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='12' lg='12' md='12' sm='12' xs='12'>
                            <div style={{ maxHeight: '57vh', height: '100%' }}>
                                <Table responsive className="gridTable">
                                    <thead>
                                        <tr>
                                            <th>
                                                District Name
                                            </th>
                                            <th>
                                                Active
                                            </th>
                                            <th>
                                                Primary Contact
                                            </th>
                                            <th>
                                                Office Phone
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!this.state.noResults ?
                                            <>
                                                {renderItems}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={4} style={{ textAlign:'center', fontWeight: '500' }}>
                                                    No Results Found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    async searchDistrict() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        var q = this.state.search.length == 0 ? 'alldistricts' : this.state.search;

        await fetch('api/District/searchDistrict?q=' + q, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ districts: data });
                this.setState({ loading: false, noResults: data.length == 0 ? true : false });
            })
            .catch((error) => {
                console.log('error in searchDistrict - ' + error.message);
                this.setState({ loading: false });
            });
    }
}