import React, { Component } from "react";
import UploadService from "../services/upload-files-student.service";
import authService from './api-authorization/AuthorizeService';
import './layout/site.css';
import { IoTrashOutline } from 'react-icons/io5';
import Modal from 'react-modal';
import FileHelper from './FileHelper';

export default class UploadFilesStudent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
            uploadFileTypeId: 0,
            uploadFileTypeList: [],
            note: '',
            a1: false,
            a2: false,
            uploadId: 0,
            showModal: false,
            uploadFileTypeName: ''
        };

        this.selectFile = this.selectFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    componentDidMount() {
        this.loadFileTypes();
        UploadService.getFiles(this.props.StudentId).then((response) => {
            this.setState({
                fileInfos: response.data,
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.StudentId !== this.props.StudentId) {
            UploadService.getFiles(this.props.StudentId).then((response) => {
                this.setState({
                    fileInfos: response.data,
                });
            });
        }
    }

    async loadFileTypes() {
        await UploadService.getFileTypes().then((response) => {
            this.setState({ uploadFileTypeList: response });
        });
    }

    selectFile(event) {
        this.setState({
            progressInfos: [],
            selectedFiles: event.target.files,
        });
    }

    uploadFiles() {
        if (this.state.uploadFileTypeId == 0) {
            this.setState({ a1: true });
        }
        else if (this.state.note.length == 0 && this.state.uploadFileTypeName == 'Other') {
            this.setState({ a2: true });

        }
        else {
            const selectedFiles = this.state.selectedFiles;
            let _progressInfos = [];
            for (let i = 0; i < selectedFiles.length; i++) {
                _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
            }
            this.setState(
                {
                    progressInfos: _progressInfos,
                    message: [],
                },
                () => {
                    for (let i = 0; i < selectedFiles.length; i++) {
                        this.upload(i, selectedFiles[i], this.props.StudentId);
                    }
                }
            );
        }
    }

    upload(idx, file, studentId) {
        let _progressInfos = [...this.state.progressInfos];
        UploadService.upload(file, studentId, this.state.uploadFileTypeId, this.state.note, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                _progressInfos,
            });
        })
            .then((response) => {
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
                    return {
                        message: nextMessage
                    };
                });
                return UploadService.getFiles(studentId);

            })
            .then((files) => {
                this.setState({
                    fileInfos: files.data,
                });
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage
                    };
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({
                        selectedFiles: undefined,
                        progressInfos: [],
                        message: [],
                        uploadFileTypeId: 0,
                        note: ''
                    })
                }, 5000)
            });
    }

    deleteUpload(id) {
        this.setState({ uploadId: id, showModal: true });
    }

    async openFile(id, filename) {
        const token = await authService.getAccessToken();
        await fetch('api/Student/GetStudentFile?id=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                if (filename && filename.includes('.pdf')) {
                    window.open(URL.createObjectURL(data));
                }
                else {
                    FileHelper.DownloadFile(data, filename);
                }
            })
    }

    async deleteUploadedDocument() {
        this.setState({ showModal: false });
        const token = await authService.getAccessToken();
        var id = this.state.uploadId;

        var response = await fetch('api/Student/DeleteStudentFile?id=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then((response) => {
                UploadService.getFiles(this.props.StudentId).then((files) => {
                    this.setState({
                        fileInfos: files.data,
                    });
                });
            });
    }

    render() {
        const { selectedFiles, progressInfos, message, fileInfos } = this.state;
        return (
            <div id="main" style={{ maxWidth: '540px' }}>
                {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progressInfo.percentage + "%" }}
                                >
                                    {progressInfo.percentage}%
                                </div>
                            </div>
                        </div>
                    ))}
                {message.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <table cellPadding={5}>
                    <tbody>
                        <tr>
                            <td></td>
                            <td align="left">
                                <label className="btn p-0">
                                    <input type="file" multiple onChange={this.selectFile} />
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td align="right">
                                <label className={'row-label-upload'}>File Type</label>
                            </td>
                            <td align="left">
                                <select className="form-control"
                                    onChange={(e) => {
                                        var index = e.nativeEvent.target.selectedIndex;

                                        this.setState({
                                            uploadFileTypeId: e.target.value,
                                            uploadFileTypeName: e.nativeEvent.target[index].text, a1: false
                                        })
                                    }}
                                    value={this.state.uploadFileTypeId}
                                    style={this.state.a1 ? { width: 'fit-content', border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { width: 'fit-content' }}
                                >
                                    <option key={0} value={0}>---Select---</option>
                                    {this.state.uploadFileTypeList.map((item) =>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td align="right" valign="top">
                                <label className={'row-label-textarea-upload'}>Note</label>
                            </td>
                            <td align="left">
                                <textarea
                                    onChange={(e) => {
                                        this.setState({ note: e.target.value, a2: false });
                                    }}
                                    placeholder={this.state.a2 ? 'Required' : 'Note'}
                                    value={this.state.note}
                                    style={this.state.a2 ? { width: '420px', height: '100px', border: '1px solid #ff0000', backgroundColor: '#ff000010', borderRadius: '5px', padding: '6px 10px' } : { width: '420px', height: '100px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} align="right">
                                <button
                                    color="primary"
                                    disabled={!selectedFiles}
                                    onClick={this.uploadFiles}
                                    style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', background: '#007bff', color: '#fff', borderRadius: '4px', border: '1px solid #007bff', cursor: 'pointer' }}
                                >
                                    Upload
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="card-student">
                    <div className="card-header">Uploaded Documents</div>
                    <table className="gridTable">
                        <thead>
                            <tr>
                                <th>
                                    File Name
                                </th>
                                <th>
                                    File Type
                                </th>
                                <th>
                                    Upload Date
                                </th>
                                <th>
                                    Note
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.fileInfos.length > 0 ?
                                    fileInfos && fileInfos.map((file, index) =>
                                        <tr key={index}>
                                            <td>
                                                <div style={{ color: 'cornflowerblue', cursor: 'pointer' }} onClick={() => { this.openFile(file.id, file.url) }}>{file.displayName}</div>
                                                {/*<a href={file.url} target="_blank" rel='noopener noreferrer'>{file.url}</a>*/}
                                            </td>
                                            <td>
                                                {file.fileType}
                                            </td>
                                            <td>
                                                {file.dateUploaded}
                                            </td>
                                            <td>
                                                {file.note}
                                            </td>
                                            <td className="deleteUploadCell">
                                                <IoTrashOutline color="#000" size={21} style={{ cursor: 'pointer' }} onClick={() => this.deleteUpload(file.id)} />
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan={5} style={{ fontWeight: '500', textAlign: 'center' }}>
                                            No Results Found
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showModal: false })}
                        className="et-modal"
                        contentLabel="EDelete Upload"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Delete the uploaded document?</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
                            <button
                                color='primary'
                                style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                onClick={() => this.setState({ showModal: false })}
                            >
                                No
                            </button>
                            <button
                                color='primary'
                                style={{ marginTop: '10px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', borderRadius: '5px', border: '0' }}
                                onClick={() => this.deleteUploadedDocument()}
                            >
                                Yes
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}