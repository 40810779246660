import React, { Component } from "react";
import UploadService from "../services/upload-files-district.service";
import authService from './api-authorization/AuthorizeService';
import FileHelper from './FileHelper';
import './layout/site.css';

export default class UploadFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
        };

        this.selectFile = this.selectFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.DistrictId !== this.props.DistrictId) {
            UploadService.getFiles(this.props.DistrictId).then((response) => {
                this.setState({
                    fileInfos: response.data,
                });
            });
        }
    }

    componentDidMount() {
        UploadService.getFiles(this.props.DistrictId).then((response) => {
            this.setState({
                fileInfos: response.data,
            });
        });
    }

    selectFile(event) {
        this.setState({
            progressInfos: [],
            selectedFiles: event.target.files,
        });
    }

    uploadFiles() {
        const selectedFiles = this.state.selectedFiles;
        let _progressInfos = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }
        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i], this.props.DistrictId, this.props.UserId);
                }
            }
        );
    }

    upload(idx, file, districtId, userId) {
        let _progressInfos = [...this.state.progressInfos];
        UploadService.upload(file, districtId, userId, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                _progressInfos,
            });
        })
            .then((response) => {
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
                    return {
                        message: nextMessage
                    };
                });
                return UploadService.getFiles(districtId);

            })
            .then((files) => {
                this.setState({
                    fileInfos: files.data,
                });
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage
                    };
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({
                        selectedFiles: undefined,
                        progressInfos: [],
                        message: []
                    })
                }, 5000)
            });
    }

    async openFile(id, filename) {
        const token = await authService.getAccessToken();
        await fetch('api/District/GetDistrictFile?id=' + id, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(response => response.blob())
            .then(data => {
                if (filename && filename.includes('.pdf')) {
                    window.open(URL.createObjectURL(data));
                }
                else {
                    FileHelper.DownloadFile(data, filename);
                }
            })
    }

    render() {
        const { selectedFiles, progressInfos, message, fileInfos } = this.state;
        return (
            <div>
                {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progressInfo.percentage + "%" }}
                                >
                                    {progressInfo.percentage}%
                                </div>
                            </div>
                        </div>
                    ))}
                <div className="row">
                    <div className="col-8">
                        <label className="btn btn-default p-0">
                            <input type="file" multiple onChange={this.selectFile} />
                        </label>
                    </div>
                    <div className="col-4">
                        <button
                            className="btn btn-sm"
                            disabled={!selectedFiles}
                            onClick={this.uploadFiles}
                            style={{ marginTop: '0px', marginBottom: '20px', whiteSpace: 'nowrap', padding: '3px 20px', background: '#007bff', color: '#fff', borderRadius: '4px', border: '1px solid #007bff', cursor: 'pointer' }}
                        >
                            Upload
                        </button>
                    </div>
                </div>
                {message.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <div className="card-student">
                    <div className="card-header">Uploaded Documents</div>
                    <table className="gridTable">

                        <thead>
                            <tr>
                                <th>
                                    File Name
                                </th>
                                <th>
                                    Date Uploaded
                                </th>
                                <th>
                                    User
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.fileInfos.length > 0 ?
                                    fileInfos && fileInfos.map((file, index) =>

                                        <tr key={index}>
                                            <td>
                                                <div style={{ color: 'cornflowerblue', cursor: 'pointer' }} onClick={() => { this.openFile(file.id, file.url) }}>{file.url}</div>
                                                {/*<a href={file.url} target="_blank" rel='noopener noreferrer'>{file.url}</a>*/}
                                            </td>
                                            <td>
                                                {file.dateUploaded}
                                            </td>
                                            <td>
                                                {file.name}
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td colSpan={5} style={{ fontWeight: '500', textAlign: 'center' }}>
                                            No Results Found
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}