import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Table, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import './layout/site.css';
import userFunctions from './UserFunctions';
import Upload from "./upload-files-district.component";

export class DistrictDetails extends Component {
    static displayName = DistrictDetails.name;

    constructor(props) {
        super(props);

        this.state = {
            districtId: userFunctions.GuidEmpty(),
            districtName: '',
            districtActive: true,
            officePhone: '',
            generalNotes: '',
            loading: false,
            contactList: [],
            a1: false,
            a2: false,
            a3: false,
            a4: false,
            a5: false,
            a6: false,
            a7: false,
            a8: false,
            a9: false,
            a10: false,
            contactsDisabled: true,
            siteNotesDisabled: true,
            districtNotes: [],
            notesContactList: [],
            contactsContactId: userFunctions.GuidEmpty(),
            contactName: '',
            contactTitle: '',
            contactOfficePhone: '',
            contactMobilePhone: '',
            contactEmail: '',
            userId: '',
            contactActive: true,
            primaryContact: true,
            districtNotesContactId: userFunctions.GuidEmpty(),
            districtNotesContactName: '',
            districtNotesNote: '',
            districtNoteId: userFunctions.GuidEmpty(),
            districtNotes: [],
            noResults: false
        }
    }

    async componentDidMount() {
        this.query = new URLSearchParams(window.location.search);
        this.queryId = this.query.get('id');

        if (this.queryId) {
            this.setState({ districtId: this.queryId });
            //Get the student and load the page fields
            this.getDistrictById(this.queryId);
        }

        const userId = await userFunctions.UserId();

        this.setState({ userId: userId });
        this.loadContacts();
        this.loadDistrictNotes();
        this.loadNotesContacts();
    }

    render() {
        const currentItems = this.state.districtNotes;

        const renderItems = currentItems?.map((note, i) => {
            return (
                <tr key={i} onClick={(e) => {
                    this.setState({
                        districtNoteId: note.id,
                        districtNotesContactId: note.contactId == userFunctions.GuidEmpty ? userFunctions.GuidEmpty : note.contactId,
                        districtNotesContactName: note.contactId == null ? true : false,
                        districtNotesContactName: note.name,
                        districtNotesNote: note.notes
                    });

                }}>
                    <td>{note.shortNote}</td>
                    <td>{note.name}</td>
                    <td>{note.noteDateTime}</td>
                    <td>{note.user}</td>
                </tr >
            )
        });

        return (
            <div>
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <h3>District Details</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col xl='5' lg='6' md='6' sm='12' xs='12'>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>District Name</label>
                                <Input
                                    type='text'
                                    onChange={(e) => {
                                        this.setState({ districtName: e.target.value, a1: false });
                                    }}
                                    placeholder={this.state.a1 ? 'Required' : 'District Name'}
                                    value={this.state.districtName}
                                    style={this.state.a1 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input margin-top-10'}>
                                <label className={'row-label'}>Office Phone</label>
                                <Input
                                    type='text'
                                    maxLength={14}
                                    onChange={(e) => {
                                        var phone = userFunctions.formatPhoneNumber(e.target.value)
                                        this.setState({ officePhone: phone, a2: false });
                                    }}
                                    placeholder={this.state.a2 ? 'Required' : 'Office Phone'}
                                    value={this.state.officePhone ?? ""}
                                    style={this.state.a2 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                />
                            </div>
                            <div className={'row-label-input'}>
                                <label className={'row-label-textarea'}>District General Notes</label>
                                <textarea
                                    onChange={(e) => {
                                        this.setState({ generalNotes: e.target.value });
                                    }}
                                    value={this.state.generalNotes}
                                    style={{ width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '6px 10px' }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    color='primary'
                                    onClick={() => { this.saveDistrict(); }}
                                    style={{ whiteSpace: 'nowrap', marginTop: '10px', justifyContent: 'center' }}
                                    disabled={this.state.loading ? true : false}
                                >
                                    Save District
                                </Button>
                            </div>
                            <div className={'row-label-input'}>
                                <label style={{ width: '32%', marginTop: '10px', fontSize: '22px', fontWeight: '500', display: 'flex', justifyContent: 'flex-end' }}>Contacts</label>
                                <input type="hidden" className="form-control" />
                            </div>
                            <div style={this.state.contactsDisabled ? { pointerEvents: 'none', opacity: '0.7' } : {}}>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Contact</label>
                                    <select className="form-control" value={this.state.contactsContactId} onChange={(e) => {
                                        // load the contact info
                                        var id = e.target.value;
                                        this.loadContact(id);
                                        this.setState({ contactsContactId: id });
                                    }}>
                                        <option key={0} value={0}>---Select---</option>
                                        {this.state.contactList.map((item) =>
                                            this.state.contactsContactId == item.id ? <option key={item.id} value={item.id} selected={true}>{item.name}</option> : <option key={item.id} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Contact Name</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ contactName: e.target.value, a3: false }); }}
                                        placeholder={this.state.a3 ? 'Required' : 'Contact Name'}
                                        value={this.state.contactName}
                                        style={this.state.a3 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Title</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ contactTitle: e.target.value, a4: false }); }}
                                        placeholder={this.state.a4 ? 'Required' : 'Title'}
                                        value={this.state.contactTitle}
                                        style={this.state.a4 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Office Phone</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var phone = userFunctions.formatPhoneNumber(e.target.value)
                                            this.setState({ contactOfficePhone: phone, a5: false });
                                        }}
                                        maxLength={14}
                                        placeholder={this.state.a5 ? 'Required' : 'Office Phone'}
                                        value={this.state.contactOfficePhone}
                                        style={this.state.a5 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Mobile Phone</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => {
                                            var phone = userFunctions.formatPhoneNumber(e.target.value)
                                            this.setState({ contactMobilePhone: phone, a6: false });
                                        }}
                                        maxLength={14}
                                        placeholder={this.state.a6 ? 'Required' : 'Mobile Phone'}
                                        value={this.state.contactMobilePhone}
                                        style={this.state.a6 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div className={'row-label-input margin-top-10'}>
                                    <label className={'row-label'}>Email</label>
                                    <Input
                                        type='text'
                                        onChange={(e) => { this.setState({ contactEmail: e.target.value, a7: false }); }}
                                        placeholder={this.state.a7 ? 'Required' : 'Email'}
                                        value={this.state.contactEmail}
                                        style={this.state.a7 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        color='primary'
                                        onClick={() => { this.saveContact(); }}
                                        style={{ whiteSpace: 'nowrap', marginTop: '10px', justifyContent: 'center' }}
                                        disabled={this.state.loading ? true : false}
                                    >
                                        Save Contact
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        <Col xl='2' lg='2' md='2' sm='2' xs='2' style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <div className={'row-label-input'}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ districtActive: !this.state.districtActive }) }}
                                    checked={this.state.districtActive}
                                    style={{ marginTop: '19px' }}
                                    className="form-control-checkbox"
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '18px' }}>Active</label>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className={'row-label-input'} style={{marginTop: '-6px'}}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ primaryContact: !this.state.primaryContact }) }}
                                    id='chkPrimary'
                                    checked={this.state.primaryContact}
                                    style={{ marginTop: '16px' }}
                                    className="form-control-checkbox"
                                />
                                <label style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', marginTop: '15px' }}>Primary Contact</label>
                            </div>
                            <div className={'row-label-input'} style={{ marginTop: '8px' }}>
                                <Input
                                    type='checkbox'
                                    onChange={(e) => { this.setState({ contactActive: !this.state.contactActive }) }}
                                    id='chkActive'
                                    checked={this.state.contactActive}
                                    style={{ marginTop: '16px' }}
                                    className="form-control-checkbox"
                                />
                                <label className="checkboxLabel">Active</label>
                            </div>
                        </Col>

                        <Col xl='4' lg='2' md='2' sm='2' xs='2' style={{ paddingRight: 0, paddingLeft: 0 }}>
                            <div style={this.state.siteNotesDisabled ? { pointerEvents: 'none', opacity: '0.7' } : {}}>
                                <Container style={{ marginTop: '0px' }}>
                                    <Row style={{ marginTop: '0px', width: '47vw' }}>
                                        <Col xl='8' lg='12' md='12' sm='12' xs='12'>
                                            <label style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px', fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>District Contact Notes</label>
                                            <Table className="gridTable" responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Note
                                                        </th>
                                                        <th>
                                                            Contact
                                                        </th>
                                                        <th>
                                                            Day / Time
                                                        </th>
                                                        <th>
                                                            User
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.loading === true ? (
                                                        <tr>
                                                            <td className="spinner-cell">
                                                                <Spinner color="primary" children='' />
                                                            </td>
                                                        </tr>

                                                    ) : (
                                                        <>
                                                            {!this.state.noResults ? (
                                                                <>
                                                                    {renderItems}
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', position: 'absolute', top: '50%', left: '50%', marginTop: '-50px', marginLeft: '-50px' }}>
                                                                        <h5>No Results Found</h5>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>

                                            <div className={'row-label-input'}>
                                                <div className={'row-label-input'}>
                                                    <label className={'row-label'}>Contact</label>
                                                    <select className="form-control" value={this.state.districtNotesContactId} onChange={(e) => {
                                                        this.setState({ showNotesContactName: e.target.value == 9999 ? true : false, districtNotesContactId: e.target.value, a8: false });
                                                    }}
                                                        style={this.state.a8 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                                                    >
                                                        <option key={0} value={0}>---Select---</option>
                                                        {this.state.notesContactList?.map((item, i) =>
                                                            <option key={i + 1} value={item.id}>{item.name}</option>
                                                        )}
                                                        <option key={9999} value={9999}>Other</option>
                                                    </select>
                                                </div>
                                                <label className={'row-label'} style={{ opacity: this.state.showNotesContactName ? 1 : 0 }}>Contact Name</label>
                                                <Input
                                                    type='text'
                                                    onChange={(e) => { this.setState({ districtNotesContactName: e.target.value, a9: false }); }}
                                                    name='Contact Name'
                                                    id='name'
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={this.state.a9 ? 'Required' : 'Contact Name'}
                                                    value={this.state.districtNotesContactName}
                                                    style={this.state.a9 ? { opacity: this.state.showNotesContactName ? 1 : 0, border: '1px solid #ff0000', backgroundColor: '#ff000010' } : { opacity: this.state.showNotesContactName ? 1 : 0 }}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className={'row-label-input'}>
                                                <textarea
                                                    onChange={(e) => {
                                                        this.setState({ districtNotesNote: e.target.value, a10: false });
                                                    }}
                                                    placeholder={this.state.a10 ? 'Required' : 'Note'}
                                                    value={this.state.districtNotesNote}
                                                    style={this.state.a10 ? { width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ff0000', backgroundColor: '#ff000010', borderRadius: '5px', padding: '5px 8px' } : { width: '100%', height: '100px', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '5px 8px' }}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    color='primary'
                                                    onClick={() => { this.saveDistrictNote(); }}
                                                    style={{ whiteSpace: 'nowrap', marginTop: '10px', justifyContent: 'center' }}
                                                    disabled={this.state.loading ? true : false}
                                                >
                                                    Save District Note
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '40px' }}>
                                        <Col>
                                            <label style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '5px', fontSize: '22px', fontWeight: '600', marginBottom: '10px' }}>Uploads</label>
                                        </Col>
                                    </Row>
                                    <Upload DistrictId={this.state.districtId} UserId={this.state.userId} />
                                    <br />
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    async saveDistrict() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        var dto = {
            Id: this.state.districtId,
            Name: this.state.districtName,
            Phone: this.state.officePhone,
            Notes: this.state.generalNotes,
            Active: this.state.districtActive
        }

        await fetch('api/District/SaveDistrict', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ toastMessage: 'District saved successfully' });
                this.setState({ showToast: true, contactsDisabled: false, siteNotesDisabled: false, loading: false, districtId:data.id });

                this.hideToast();
            })
            .catch((error) => {
                console.log('error in saveDistrict - ' + error);
                this.setState({ loading: false });
            });
    }

    async getDistrictById(id) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/District/GetDistrictById?id=' + id, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({
                    districtName: data.name,
                    officePhone: data.phone,
                    generalNotes: data.notes,
                    contactsDisabled: false,
                    siteNotesDisabled: false,
                    districtActive: data.active
                });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in getDistrictById - ' + error.message);
                this.setState({ loading: false });
            });
    }

    async loadContacts() {
        this.setState({ loading: true });
        //debugger
        const token = await authService.getAccessToken();

        await fetch('api/District/GetContacts?id=' + this.state.districtId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ contactList: data });
                var id = this.state.contactList.find(f => f.primary == true).id;
                if (id != undefined) {
                    this.setState({ contactsContactId: id });
                    this.loadContact(id);
                }
                this.setState({ loading: false });

            })
            .catch((error) => {
                console.log('error in loadContacts - ' + error);
                this.setState({ loading: false });
            });
    }

    async loadContact(id) {
        if (id == 0) {
            this.clearContact();
        }
        else {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            await fetch('api/Sites/GetContactById?id=' + id, {
                method: 'GET',
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    if (response.status == 401) {
                        await this.signout();
                    }
                    else {
                        return response.json()
                    }
                })
                .then((data) => {
                    this.clearContact();

                    this.setState({
                        contactName: data.name,
                        contactTitle: data.title,
                        contactOfficePhone: data.officePhone,
                        contactMobilePhone: data.mobilePhone,
                        contactEmail: data.emailAddress,
                        contactActive: data.active,
                        primaryContact: data.primary
                    });
                    this.setState({ loading: false });

                })
                .catch((error) => {
                    console.log('error in loadContacts - ' + error, 'color:#ff0000');
                    this.setState({ loading: false });
                });
        }
    }

    clearContact() {
        this.setState({
            contactName: "",
            contactTitle: "",
            contactOfficePhone: "",
            contactMobilePhone: "",
            contactEmail: "",
            contactActive: false,
            primaryContact: false
        });
    }

    async saveContact() {
        if (this.state.contactName.length == 0) {
            this.setState({ a3: true });
        }
        //else if (this.state.contactTitle.length == 0) {
        //    this.setState({ a4: true });
        //}
        //else if (this.state.contactOfficePhone.length == 0) {
        //    this.setState({ a5: true });
        //}
        //else if (this.state.contactMobilePhone.length == 0) {
        //    this.setState({ a6: true });
        //}
        //else if (this.state.contactEmail.length == 0) {
        //    this.setState({ a7: true });
        //}
        else {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            var dto = {
                Id: this.state.contactsContactId == 0 ? userFunctions.GuidEmpty() : this.state.contactsContactId,
                Name: this.state.contactName,
                Title: this.state.contactTitle,
                OfficePhone: this.state.contactOfficePhone,
                MobilePhone: this.state.contactMobilePhone,
                EmailAddress: this.state.contactEmail,
                Active: this.state.contactActive,
                Primary: this.state.primaryContact,
                DistrictId: this.state.districtId
            }

            await fetch('api/District/SaveContact', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    if (response.status == 401) {
                        await this.signout();
                    }
                    else {
                        return response.json()
                    }
                })
                .then((data) => {
                    this.setState({ toastMessage: 'Contact saved successfully' });
                    this.setState({ showToast: true });

                    this.setState({ contactsContactId: data.id });
                    this.loadContacts();
                    this.loadNotesContacts();
                    this.hideToast();
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log('error in saveContact - ' + error);
                });
        }
    }

    hideToast() {
        setTimeout(() => {
            this.setState({ toastMessage: '' });
            this.setState({ showToast: false });
        }, 3500);
    }

    async loadNotesContacts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/District/GetNotesContacts?id=' + this.state.districtId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    userFunctions.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    this.setState({ notesContactList: data });
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in loadNotesContacts - ' + error);
                this.setState({ loading: false });
            });
    }

    async saveDistrictNote() {
        if (this.state.districtNotesContactId == 0) {
            this.setState({ a8: true });
        }
        else if (this.state.districtNotesContactId == 9999 && this.state.districtNotesContactName.length == 0) {
            this.setState({ a9: true });
        }
        else if (this.state.districtNotesNote.length == 0) {
            this.setState({ a10: true });
        }
        else {
            this.setState({ loading: true });
            const token = await authService.getAccessToken();

            var dto = {
                Id: this.state.districtNoteId == userFunctions.GuidEmpty() ? null : this.state.districtNoteId,
                ContactId: this.state.districtNotesContactId == 9999 ? null : this.state.districtNotesContactId,
                ContactName: this.state.districtNotesContactName,
                Notes: this.state.districtNotesNote,
                DistrictId: this.state.districtId,
                UserId: this.state.userId
            }

            await fetch('api/District/SaveDistrictNote', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            })
                .then(async (response) => {
                    if (response.status == 401) {
                        userFunctions.signout();
                    }
                    else {
                        return response.json()
                    }
                })
                .then((data) => {
                    this.setState({ toastMessage: 'District Note saved successfully' });
                    this.setState({ showToast: true });

                    this.setState({ districtNotes: data });
                    this.loadContacts();
                    this.loadNotesContacts();
                    this.hideToast();
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    console.log('error in saveDistrictNote - ' + error);
                    this.setState({ loading: false });
                });
        }
    }

    async loadDistrictNotes() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();

        await fetch('api/District/GetDistrictNotes?id=' + this.state.districtId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ districtNotes: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log('error in loadDistrictNotes - ' + error);
                this.setState({ loading: false });
            });
    }
}