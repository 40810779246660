import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import { Container, Row, Col, Input, Button, Table, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap';
import './layout/site.css';
import Upload from "./upload-files-roster.component";
import FileHelper from './FileHelper';
import { IoDownloadOutline } from 'react-icons/io5';

export class RosterImport extends Component {
    static displayName = RosterImport.name;

    constructor(props) {
        super(props);

        this.state = {
            districtList: [],
            siteList: [],
            districtId: 0,
            siteId: 0,
            fileName: '',
            importRows: [],
            a1: false,
            a2: false,
            readyForImport: false,
            showToast: false,
            toastMessage: '',
            loading: false
        }

        this.uploadChild = React.createRef();
        this.loadUpload = this.loadUpload.bind(this);
    }

    async loadUpload(districtId, siteId) {
        this.uploadChild.current.loadImportData(districtId, siteId);
    }

    async importData() {
        this.uploadChild.current.importData();
        this.setState({ loading: true, readyForImport: true });
    }

    async componentDidMount() {
        this.getDistricts();
    }

    async getDistricts() {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetDistricts', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ districtList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getDistricts - ' + error.message, 'color:#ff0000')
            });
    }

    async getSitesByDistrictId(districtId) {
        this.setState({ loading: true });
        const token = await authService.getAccessToken();
        await fetch('api/Sites/GetSitesByDistrictId?id=' + districtId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(async (response) => {
                if (response.status == 401) {
                    await this.signout();
                }
                else {
                    return response.json()
                }
            })
            .then((data) => {
                this.setState({ siteList: data });
                this.setState({ loading: false });
            })
            .catch((error) => {
                alert('error in getSites - ' + error.message, 'color:#ff0000')
            });
    }

    async downloadTemplate() {
        const token = await authService.getAccessToken();
        await fetch('api/RosterImport/DownloadTemplate', {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.blob())
            .then(data => {
                FileHelper.DownloadFile(data, "RosterImportTemplate.xlsx", "application/octet-stream");
            })
            .catch((error) => {
                console.log('error in downloadTemplate - ' + error.message);
            });
    }

    render() {
        return (
            <div>
                {this.state.loading === true ?
                    <div>
                        <Spinner color="primary" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                    </div>
                    : null
                }
                {this.state.showToast ?
                    <Toast className="toast">
                        <ToastBody className="toast-body">
                            {this.state.toastMessage}
                        </ToastBody>
                    </Toast>
                    : null}
                <Row>
                    <Col>
                        <h3>Roster Import</h3>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col xl='5' lg='6' md='6' sm='12' xs='12'>
                        <div className={'row-label-input'}>
                            <label className={'row-label-roster'}>District</label>
                            <select className="form-control"
                                onChange={(e) => {
                                    this.setState({ districtId: e.target.value, a1: false });
                                    this.getSitesByDistrictId(e.target.value);
                                }}
                                value={this.state.districtId}
                                style={this.state.a1 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                            >
                                <option key={0} value={0}>---Select---</option>
                                {this.state.districtList.map((item) =>
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )}
                            </select>
                        </div>
                        <div className={'row-label-input margin-top-10'}>
                            <label className={'row-label-roster'}>School</label>
                            <select className="form-control"
                                onChange={(e) => {
                                    this.setState({ siteId: e.target.value, a2: false });
                                    this.loadUpload(this.state.districtId, e.target.value);
                                }}
                                value={this.state.siteId}
                                style={this.state.a2 ? { border: '1px solid #ff0000', backgroundColor: '#ff000010' } : {}}
                            >
                                <option key={0} value={0}>---Select---</option>
                                {this.state.siteList.map((item) =>
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )}
                            </select>
                        </div>
                        <div style={{ display: 'flex', width: 'fit-content', flexDirection: 'column' }}>
                            <Upload
                                ref={this.uploadChild}
                                DistrictId={this.state.districtId}
                                SiteId={this.state.siteId}
                                onDataLoaded={(val) => this.setState({ readyForImport: val })}
                                onImportSucceed={() => {
                                    this.setState({
                                        readyForImport: false,
                                        loading: false,
                                        toastMessage: 'Roster imported successfully',
                                        showToast: true,
                                        districtId: 0,
                                        siteId: 0
                                    });
                                    setTimeout(() => {
                                        this.setState({
                                            toastMessage: '',
                                            showToast: false
                                        });
                                    }, 4000);
                                }}
                            />
                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ width: 'fit-content', color: 'cornflowerblue', cursor: 'pointer' }} onClick={() => { this.downloadTemplate() }}><IoDownloadOutline color="#000" size={21} style={{ marginTop: '-11px' }} />Download Roster Import Template</div>
                                <Button
                                    onClick={() => this.importData()}
                                    disabled={this.state.readyForImport ? false : true}
                                    className="button"
                                >
                                    Import
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}