import http from "../http-common";
import authService from '../components/api-authorization/AuthorizeService';

class UploadStudentFilesService {
    async upload(file, studentId, uploadFileTypeId, note, onUploadProgress) {
        const token = await authService.getAccessToken();
        let formData = new FormData();
        formData.append("file", file);
        formData.append("studentId", studentId);
        formData.append("uploadFileTypeId", uploadFileTypeId);
        formData.append("note", note);
        return http.post("api/upload/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress,
        });
    }
    async getFiles(studentId) {
        const token = await authService.getAccessToken();
        return http.get("api/upload/studentfiles?studentId=" + studentId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
    }

    async getFileTypes() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/student/getUploadFileTypes', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        var data = await response.json();
        return data;
    }

    signout() {
        window.location.href = "/";
    }
}
export default new UploadStudentFilesService();