import authService from "./api-authorization/AuthorizeService"

const FrameFunctions = {
    AddNew: async function(dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/AddManufacturerModel', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postFrameInventorySearchAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/SearchFrames', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postSaveFrameAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/SaveFrame', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    saveMiscFrameInventory: async function (data) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/SaveMiscFrameInventory', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postDeleteFrameAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/DeleteFrame', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    getFrameDropdownsAsync: async function () {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/GetFrameDropdowns', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        return response;
    },
    getDispensedFramesAsync: async function () {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/GetDispensedFrames', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        return response;
    },
    postAddDispensedFrameAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/AddDispensedFrame', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    },
    postDeleteDispensedFrameAsync: async function (dto) {
        const token = await authService.getAccessToken();

        const response = await fetch('api/Frames/DeleteDispensedFrame', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });

        return response;
    }
}

export default FrameFunctions;