import React, { Component } from 'react';
import {
    BrowserRouter as Router, Switch,
    Route, Redirect,
} from "react-router-dom";

export class Home extends Component {
    static displayName = Home.name;


    render() {
        return (<Redirect to="/StudentSearch" />);
    }
}
