import authService from './api-authorization/AuthorizeService';

const UserFunctions = {
    UserId: async function () {
        const userId = sessionStorage.getItem("UserId");

        return userId;
    },
    Username: async function () {
        const username = sessionStorage.getItem("Username");

        return username;
    },
    formatPhoneNumber: function (phoneNumber) {
        var result;

        result = phoneNumber.replace(/[^0-9]/g, '');
        if (result.length == 10) {
            result = result.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        else {
            result = result.replace(/\//g, "");
        }

        return result;
    },
    signout: function () {
        //window.location.href = "/Identity/Account/Login"
    },
    GuidEmpty: function () {
        return "00000000-0000-0000-0000-000000000000";
    },
    validateEmail: function (email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    },
    ValueOrNull: function (value, checkForZero) {
        if (value === this.GuidEmpty() || value === '' || value === undefined || (checkForZero && value === 0) || (checkForZero && value === '0')) {
            return null;
        }
        else {
            return value;
        }
    },
    PadWithZeros: function (value, totalLength) {
        if (value != null || value != undefined || value != '') {
            return String(value).padStart(totalLength, '0');
        }
        else {
            return value;
        }
    },
    CalculateTotal: function (value1, value2) {
        if (!isNaN(value1) && !isNaN(value2)) {
            return parseFloat(value1) + parseFloat(value2);
        }
        else {
            return 0;
        }
    },
    IsInRole: async function (role) {
        const token = await authService.getAccessToken();
        const userId = sessionStorage.getItem("UserId");

        const response = await fetch('api/role/IsInRole?role=' + role + '&userId=' + userId, {
            method: 'GET',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        return data;
    },
    FormsNoMatchCount: async function () {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch('api/StudentForms/GetFormsNoMatchCount', {
                method: 'GET',
                body: null,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            const data = await response.json();
            return data;
        }
        catch (err) {
            return 0;
        }
    }
}

export default UserFunctions;